import React, { useEffect, useState } from "react";
import {
  Paper,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SecurityIcon from "@mui/icons-material/Security";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import CadastroEventos from "./Evento";
import axios from "../../../utils/configAxios";

const SolicitacaoInformacoesBancarias = ({ alertCustom }) => {
  const [loading, setLoading] = useState(true);
  const [informacoesBancariasCadastradas, setInformacoesBancariasCadastradas] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verificarInformacoesBancarias = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${
            process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS
          }/v1/organizadores/verificar-status-cadastro-organizador/${
            JSON.parse(localStorage?.organizador).id
          }`
        );
        setInformacoesBancariasCadastradas(data.status);
      } catch (error) {
        console.error("Erro ao verificar informações bancárias:", error);
        alertCustom("Ocorreu um erro ao verificar as informações bancárias.");
      }
      setLoading(false);
    };

    verificarInformacoesBancarias();
  }, []);

  const handleCadastrarClick = () => {
    navigate("/dash/configuracoesManager");
  };

  return informacoesBancariasCadastradas ? (
    <CadastroEventos />
  ) : loading ? (
    <div
      style={{
        width: "100%",
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <Paper elevation={0} style={{ padding: "20px" }}>
      <Grid container spacing={3} justifyContent="center">
        {/* Título */}
        <Grid item xs={12}>
          <Typography variant="h5">
            Complete seu cadastro
            <Typography variant="body1" color="textSecondary">
              Antes de subir qualquer evento para a plataforma, precisamos dos
              meios pelo qual deseja receber
            </Typography>
          </Typography>
        </Grid>

        {/* Cards explicativos */}
        <Grid item xs={12} md={4}>
          <Card variant="outlined" sx={{ height: "100%" }}>
            <CardContent align="center">
              <AccountBalanceIcon sx={{ fontSize: 40, color: "gray", mb: 1 }} />
              <Typography variant="h6" marginBottom="12px">
                Por que cadastrar suas informações de depósito?
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "justify" }}>
                Suas informações bancárias são necessárias para garantir que
                você possa receber pagamentos diretamente em sua conta.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card variant="outlined" sx={{ height: "100%" }}>
            <CardContent align="center">
              <SecurityIcon sx={{ fontSize: 40, color: "gray", mb: 1 }} />
              <Typography variant="h6" marginBottom="12px">
                Segurança dos seus dados
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "justify" }}>
                Apenas você e nossa equipe financeira terão acesso a esses
                dados. Usados única e exclusivamente para realização de
                transações de depósito.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card variant="outlined" sx={{ height: "100%" }}>
            <CardContent align="center">
              <LockIcon sx={{ fontSize: 40, color: "gray", mb: 1 }} />
              <Typography variant="h6" marginBottom="12px">
                Criptografia
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "justify" }}>
                Seus dados são totalmente criptografados para impedir qualquer
                tipo de acesso não autorizado.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Botão de ação */}
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Button
              variant="contained"
              color="warning"
              disableElevation
              sx={{ borderRadius: "50px", color: "#fff" }}
              endIcon={<ArrowForwardRoundedIcon />}
              onClick={handleCadastrarClick}
            >
              Complete seus dados
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SolicitacaoInformacoesBancarias;
