import React, { useState, useEffect } from "react";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Typography,
  CardHeader,
  IconButton,
  Tooltip,
} from "@mui/material";
import axios from "../../../utils/configAxios";
import Autocomplete from "../../../components/AutoComplete/";
import { format } from "date-fns";
import ExcelButton from "../../../utils/excel";
import EnhancedTable from "../../../components/Table/";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ConfirmDialog from "./dialog";
import DataAttemp from "../../../utils/DataAttemp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Health from "./health";

const REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM;

const BuscaDadosComponente = ({ alertCustom }) => {
  const [eventos, setEventos] = useState([]);
  const [eventoSelecionado, setEventoSelecionado] = useState("");
  const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
  const [status, setStatus] = useState("");
  const [dados, setDados] = useState([]);
  const [dadosKitExcel, setDadosKitExcel] = useState([]);
  const [colunasKitExcel, setColunasKitExcel] = useState([]);
  const [numeracaoCamisaArray, setNumeracaoCamisaArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dadosExcel, setDadosExcel] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [docs, setDocs] = useState([]);
  const [transacoes, setTransacoes] = useState([]);
  const [statusToChange, setStatusToChange] = useState([]);
  const dataManager = DataAttemp();

  const [options] = useState([
    {
      id: "confirmed",
      title: "Presença confirmada",
    },
    {
      id: "approved",
      title: "Pago/Aprovado",
    },
    {
      id: "pending",
      title: "Pendente de pagamento",
    },
    {
      id: "cancelled",
      title: "Cancelado",
    },
    {
      id: "signing_up",
      title: "Em processo de inscrição",
    },
  ]);
  const [headCells, setHeadCells] = useState([
    {
      id: "TRANSACAO",
      numeric: false,
      disablePadding: true,
      label: "Transação",
      width: 150,
    },
    {
      id: "NOME",
      numeric: false,
      disablePadding: true,
      label: "Nome",
      width: 150,
    },
    {
      id: "STATUS",
      numeric: false,
      disablePadding: true,
      label: "Status",
      width: 120,
    },
    {
      id: "EMAIL",
      numeric: false,
      disablePadding: true,
      label: "Email",
      width: 200,
    },
    {
      id: "DOCUMENTO",
      numeric: false,
      disablePadding: true,
      label: "Documento",
      width: 120,
    },
    {
      id: "DATANASC",
      numeric: false,
      disablePadding: true,
      label: "Nascimento",
      width: 120,
    },
    {
      id: "VALOR",
      numeric: true,
      disablePadding: true,
      label: "Total",
      width: 120,
    },
    {
      id: "CATEGORIA",
      numeric: false,
      disablePadding: true,
      label: "Categoria",
      width: 120,
    },
    {
      id: "DATAINSCRICAO",
      numeric: false,
      disablePadding: true,
      label: "DataInscrição",
      width: 120,
    },
  ]);

  useEffect(() => {
    const fetchEventos = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/listar-eventos-parcial`
        );
        response.data.length > 0
          ? setEventos(response.data.reverse())
          : alertCustom("Nenhum resultado encontrado!");
      } catch (error) {
        setEventos("Ocorreu um erro ao buscar participantes");
        console.error("Erro ao buscar eventos:", error);
      }
    };
    fetchEventos();
  }, []);

  const buscarDados = async () => {
    if (!eventoSelecionado) {
      return alertCustom("Selecione um evento para prosseguir!");
    }
    setLoading(true);
    try {
      setDados([]);
      setDadosKitExcel([]);
      setHeadCells([
        {
          id: "INSCRICAO",
          numeric: false,
          disablePadding: true,
          label: "Inscrição",
          width: 150,
        },
        {
          id: "TRANSACAO",
          numeric: false,
          disablePadding: true,
          label: "Transação",
          width: 150,
        },
        {
          id: "NOME",
          numeric: false,
          disablePadding: true,
          label: "Nome",
          width: 150,
        },
        {
          id: "STATUS",
          numeric: false,
          disablePadding: true,
          label: "Status",
          width: 120,
        },
        {
          id: "EMAIL",
          numeric: false,
          disablePadding: true,
          label: "Email",
          width: 200,
        },
        {
          id: "TELEFONE",
          numeric: false,
          disablePadding: true,
          label: "Telefone",
          width: 120,
        },
        {
          id: "DOCUMENTO",
          numeric: false,
          disablePadding: true,
          label: "Documento",
          width: 120,
        },
        {
          id: "SEXO",
          numeric: false,
          disablePadding: true,
          label: "Sexo",
          width: 120,
        },
        {
          id: "DATANASC",
          numeric: false,
          disablePadding: true,
          label: "Nascimento",
          width: 120,
        },
        {
          id: "VALOR",
          numeric: true,
          disablePadding: true,
          label: "Total",
          width: 120,
        },
        {
          id: "CUPOM",
          numeric: true,
          disablePadding: true,
          label: "Cupom",
          width: 120,
        },
        {
          id: "CATEGORIA",
          numeric: false,
          disablePadding: true,
          label: "Categoria",
          width: 120,
        },
        {
          id: "DATAINSCRICAO",
          numeric: false,
          disablePadding: true,
          label: "Inscrição",
          width: 120,
        },
        {
          id: "BAIRRO",
          numeric: false,
          disablePadding: true,
          label: "Bairro",
          width: 120,
        },
        {
          id: "CIDADE",
          numeric: false,
          disablePadding: true,
          label: "Cidade",
          width: 120,
        },
        {
          id: "UF",
          numeric: false,
          disablePadding: true,
          label: "UF",
          width: 120,
        },
      ]);
      setColunasKitExcel(["KIT", "QUANTIDADE"]);

      const params = {
        idEvento: eventoSelecionado.id,
        idCategoria: categoriaSelecionada?.id,
        status: status?.id,
      };
      const response = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/buscar-filtro-participantes`,
        params
      );

      if (response.data.length === 0) {
        alertCustom("Nenhum resultado encontrado!");
        return;
      }
      const kitsArrayExcel = {};

      response.data.map((participante) => {
        participante.produtos.forEach((produto, index) => {
          kitsArrayExcel[produto.nome + " " + produto.variacao]
            ? (kitsArrayExcel[produto.nome + " " + produto.variacao] +=
                produto.quantidade)
            : (kitsArrayExcel[produto.nome + " " + produto.variacao] =
                produto.quantidade);
        });
      });
      const kitsSpl = [];
      Object.keys(kitsArrayExcel).forEach((key) => {
        const novoObjeto = [key, kitsArrayExcel[key]];
        kitsSpl.push(novoObjeto);
      });
      setDadosKitExcel(kitsSpl);

      // Novo código para contar respostas específicas
      const keywords = ["tamanho", "camisa", "tam", "numero"];
      const respostasCount = {};

      response.data.forEach((participante) => {
        if (participante.formulario && participante.formulario.length > 0) {
          participante.formulario.forEach((pergunta) => {
            if (
              keywords.some((keyword) =>
                pergunta.input.toLowerCase().includes(keyword)
              )
            ) {
              respostasCount[pergunta.resposta] =
                (respostasCount[pergunta.resposta] || 0) + 1;
            }
          });
        }
      });

      const respostasArray = Object.keys(respostasCount).map((resposta) => [
        resposta,
        respostasCount[resposta],
      ]);

      setNumeracaoCamisaArray(respostasArray); // Aqui você pode armazenar o array ou utilizá-lo conforme necessário

      const participantesRemapeados = response.data.map(
        (participante, index) => {
          const kitsArray = {};
          participante.produtos.forEach((produto, index) => {
            kitsArray[
              `KIT_${index + 1}`
            ] = `${produto.quantidade} x ${produto.nome} ${produto.variacao}`;
          });
          const formArray = {};
          if (participante.formulario && participante.formulario.length > 0) {
            const formularioArray = participante.formulario;

            formularioArray.forEach((pergunta) => {
              formArray[pergunta.input] = pergunta.resposta;
            });
          }
          let dataNasc = null;
          if (new Date(participante?.dataNascimento) != "Invalid Date") {
            dataNasc = participante?.dataNascimento
              ? new Date(participante?.dataNascimento)
              : new Date();
            dataNasc.setHours(dataNasc.getHours() + 3);
          }

          const enderecoArray = participante.endereco
            .split(",")
            .map((item) => item.trim());
          return {
            INSCRICAO: String(participante.numCamisa).padStart(
              response.data.length.toString().length,
              "0"
            ),
            TRANSACAO: participante.transacao,
            CUPOM: participante.cupom ?? "Não adicionado",
            NOME: participante.nome,
            DOCUMENTO: participante.documento,
            CATEGORIA: participante.categoriaName,
            VALOR: `R$ ${Number(Number(participante.valorTotal)).toFixed(2)}`,
            EMAIL: participante.email,
            DATANASC: dataNasc
              ? format(dataNasc, "dd/MM/yyyy")
              : "Não Informado",
            SEXO: participante.sexo,
            TELEFONE: participante.telefone,
            ...kitsArray,
            ...formArray,
            DATAINSCRICAO: participante.dataInscricao
              ? format(participante.dataInscricao, "dd/MM/yyyy")
              : "",
            BAIRRO: enderecoArray[0],
            CIDADE: enderecoArray[1],
            UF: enderecoArray[2],
            STATUS: options.find((option) => option.id == participante.status)
              .title,
          };
        }
      );

      const kitsColumns = response.data.reduce((columns, participante) => {
        participante.produtos.forEach((produto, index) => {
          const kitColumn = {
            id: `KIT_${index + 1}`,
            numeric: false,
            disablePadding: true,
            label: `[Kit] Item ${index + 1}`,
            width: 150,
          };
          if (!columns.find((column) => column.id === kitColumn.id)) {
            columns.push(kitColumn);
          }
        });
        return columns;
      }, []);

      const formColumns = response.data.reduce((columns, participante) => {
        if (participante.formulario && participante.formulario.length > 0) {
          const formularioArray = participante.formulario;

          formularioArray.forEach((pergunta) => {
            const perguntaColumn = {
              id: pergunta.input,
              numeric: false,
              disablePadding: true,
              label: pergunta.input,
              width: 150,
            };
            if (!columns.find((column) => column.id === perguntaColumn.id)) {
              columns.push(perguntaColumn);
            }
          });
        }
        return columns;
      }, []);

      setHeadCells((prevHeadCells) => [
        ...prevHeadCells,
        ...kitsColumns,
        ...formColumns,
      ]);
      setDados(participantesRemapeados);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitPresent = async (onlyOne) => {
    try {
      const request = {
        transacoes: transacoes,
        status: statusToChange?.id,
        evento: eventoSelecionado,
      };
      if (onlyOne) {
        request.docs = docs;
      }
      await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/marcar-presenca`,
        request
      );
      alertCustom("Participantes atualizados");
      buscarDados();
      setOpenDialog(false);
    } catch (error) {
      console.log(error);
      alertCustom(
        "Não foi possível mudar o status dos participantes selecionados!"
      );
    }
  };

  useEffect(() => {
    const colunas = Object.values(headCells).map((value) => value.id);
    const linhas = dados.map((rowData) => {
      return colunas.map((coluna) => {
        return rowData[coluna] !== undefined ? rowData[coluna] : null;
      });
    });
    setDadosExcel([
      {
        name: "INSCRIÇÕES",
        columns: colunas,
        dados: linhas,
      },
      {
        name: "NUM. CAMISETAS",
        columns: ["TAMANHO", "QUANTIDADE"],
        dados: numeracaoCamisaArray,
      },
      {
        name: "KITS",
        columns: colunasKitExcel,
        dados: dadosKitExcel,
      },
    ]);
  }, [dados]);

  useEffect(() => {
    setConfirmMessage(
      `Gostaria de mudar o status de todas as inscrições com as transações ${transacoes.join(
        ","
      )} ou apenas as inscrições selecionadas?`
    );
  }, [transacoes]);

  const handleShareInscricoes = () => {
    navigator.clipboard
      .writeText(
        `https://goianiarunners.com/inscricoes/${eventoSelecionado.id}`
      )
      .then(() => {
        alertCustom(
          "link para visualização de inscrições copiado para a área de transferência!"
        );
      })
      .catch((err) => {
        alertCustom("Erro ao copiar ");
      });
  };

  return (
    <>
      {" "}
      <ConfirmDialog
        open={openDialog}
        title={"Confirmação"}
        message={confirmMessage}
        onClose={() => setOpenDialog(false)}
        onConfirmOne={() => handleSubmitPresent(true)}
        onConfirmAll={() => handleSubmitPresent(false)}
      />
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} md={2.75} className="tour_19">
          <Autocomplete
            disabled={!eventos.length > 0}
            options={eventos}
            setValue={setEventoSelecionado}
            value={eventoSelecionado}
            label={eventos.length > 0 ? "Evento" : "Carregando..."}
            variant="standard"
          />
        </Grid>{" "}
        <Grid item xs={12} md={2.75}>
          <Autocomplete
            options={options}
            setValue={setStatus}
            value={status}
            label="Status"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={2.75}>
          {dados.length > 0 && (
            <ExcelButton
              alertCustom={alertCustom}
              data={dadosExcel}
              label="Relatório"
            />
          )}
          {/* <Autocomplete
          options={eventos.flatMap((evento) => {
            return evento.categorias.map((categoria) => ({
              id: categoria.id,
              title: categoria.titulo,
            }));
          })}
          setValue={setCategoriaSelecionada}
          value={categoriaSelecionada}
          label={"Categoria"}
          variant="standard"
        /> */}
        </Grid>
        <Grid item xs={12} md={2.75}>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            disabled={loading}
            color="primary"
            onClick={buscarDados}
          >
            {loading ? "Buscando..." : "Buscar Dados"}
          </Button>
        </Grid>
        <Grid item>
          {dados.length > 0 && (
            <Tooltip title="Copiar link para visualização pública">
              <IconButton onClick={handleShareInscricoes}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>{" "}
        <Grid item xs={12} md={12}>
          {eventoSelecionado && (
            <Health eventoSelecionado={eventoSelecionado} />
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          {dados.length > 0 ? (
            !loading && (
              <EnhancedTable
                alertCustom={alertCustom}
                buscarDados={buscarDados}
                rows={dados}
                headCells={headCells}
                label="Participantes"
                setDocs={setDocs}
                setTransacoes={setTransacoes}
                setStatusToChange={setStatusToChange}
                setOpenDialog={setOpenDialog}
                selectionAllowed={["@adm", "@manager"].includes(
                  dataManager.userData.acessType
                )}
              />
            )
          ) : (
            <CardHeader
              className="show-box"
              avatar={<ReportProblemIcon fontSize="small" />}
              title={
                <Typography color="textSecondary" variant="body2">
                  Realize uma busca informando filtros, registros atualizados a
                  cada nova busca!
                </Typography>
              }
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default BuscaDadosComponente;
