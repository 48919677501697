import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "../../../utils/configAxios";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import SearchAutocomplete from "../../../components/AutoComplete/searchAutocomplete";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CardHeader from "@mui/material/CardHeader";
import Tooltip from "@mui/material/Tooltip";
import { Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
const { REACT_APP_URL_BIBLIOTECA_RUNNERS } = process.env;

export default function UserManagement({ alertCustom }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [motivo, setMotivo] = useState("");
  const [open, setOpen] = useState(false);
  const [originalBannedUsers, setOriginalBannedUsers] = useState([]);
  const [filteredBannedUsers, setFilteredBannedUsers] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");

  const fetchBannedUsers = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/listar-usuarios?filter=&ban=y`
      );
      setOriginalBannedUsers(response.data);
      setFilteredBannedUsers(response.data); // Inicialmente, exibe todos os usuários banidos
    } catch (error) {
      console.error("Error fetching banned users:", error);
    }
  };

  useEffect(() => {
    fetchBannedUsers();
  }, []);

  const handleBan = () => {
    if (!selectedUser) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBanConfirmed = async () => {
    try {
      await axios.put(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/banir?id=${selectedUser.id}`,
        { motivo }
      );
      setOpen(false);
      alertCustom(`${selectedUser.nome} [${selectedUser.email}] foi banido.`);
      // Atualiza a lista de usuários banidos após o banimento
      fetchBannedUsers();
    } catch (error) {
      console.error("Error banning user:", error);
    }
  };

  const handleMakeOrganizer = async () => {
    if (!selectedUser) return;
    try {
      await axios.put(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/mudar-chave?id=${selectedUser.id}`
      );
      alertCustom(
        `${selectedUser.nome} [${selectedUser.email}] é agora um organizador!`
      );
    } catch (error) {
      console.error("Error making user organizer:", error);
    }
  };

  const handleUnbanUser = async (userId) => {
    try {
      await axios.put(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/banir?id=${userId}&acao=revert`
      );
      const updatedBannedUsers = originalBannedUsers.filter(
        (user) => user.id !== userId
      );
      setOriginalBannedUsers(updatedBannedUsers);
      setFilteredBannedUsers(updatedBannedUsers); // Atualiza a lista de usuários banidos após o desbanimento
    } catch (error) {
      console.error("Error unbanning user:", error);
    }
  };

  const handleSearchEmail = (event) => {
    const { value } = event.target;
    setSearchEmail(value);
    const filteredUsers = originalBannedUsers.filter((user) =>
      user.email.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredBannedUsers(filteredUsers);
  };

  const columns = [
    { field: "nome", headerName: "Nome", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "motivo", headerName: "Motivo banimento", flex: 1 },
    {
      field: "action",
      headerName: "Ação",
      flex: 1,
      maxWidth: "70",
      renderCell: (params) => (
        <Tooltip title="Desbanir">
          <IconButton
            color="secondary"
            onClick={() => handleUnbanUser(params.row.id)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography variant="h6">
          Gerenciar Perfis
          <Typography variant="body2" color="textSecondary">
            Banimento de usuário para eventos
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} sx={{ marginTop: "20px" }}>
        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <SearchAutocomplete
                url={`${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/listar-usuarios`}
                searchField="filter"
                placeholder="Comece a digitar..."
                label="Pesquisar usuário por e-mail"
                setItemSelecionado={setSelectedUser}
                itemSelecionado={selectedUser}
              />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={3}>
              <Button
                disabled={!selectedUser}
                fullWidth
                sx={{ borderRadius: "50px" }}
                variant="contained"
                color="error"
                disableElevation
                onClick={handleBan}
              >
                Banir Usuário
              </Button>
            </Grid>
            {selectedUser && (
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirmar banimento</DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <Typography variant="body1">
                        Tem certeza de que deseja banir o usuário{" "}
                        {selectedUser.nome}? Digite o motivo abaixo:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="Motivo"
                        fullWidth
                        value={motivo}
                        onChange={(e) => setMotivo(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CardHeader
                        className="show-box"
                        avatar={<ReportProblemIcon fontSize="small" />}
                        title={
                          <Typography color="textSecondary" variant="body2">
                            Ao banir o usuário mencionado, está ciente de que o
                            mesmo não visualizará nem poderá se inscrever em
                            eventos cadastrados como gratuítos!
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancelar
                  </Button>
                  <Button onClick={handleBanConfirmed} color="primary">
                    Banir
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}></Grid>
      <Grid item xs={12} md={4}>
        <TextField
          placeholder="Filtrar por e-mail"
          fullWidth
          size="small"
          variant="outlined"
          value={searchEmail}
          onChange={handleSearchEmail}
          InputProps={{
            sx: {
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              background: "rgba(0,0,0,0.05)",
              borderRadius: "50px",
            },
            endAdornment: <SearchIcon color="secondary" />,
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              background: "rgba(0,0,0,0.05)",
              borderRadius: "50px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
      </Grid>{" "}
      <Grid item xs={12} md={12}>
        {" "}
        <DataGrid
          sx={{ height: "400px" }}
          rows={filteredBannedUsers}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
        />
      </Grid>
    </Grid>
  );
}
