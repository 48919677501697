import React, { useEffect, useState } from "react";
import {
  BrowserRouter, // Alterado para HashRouter
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./views/HomePage/";
import CadastroPage from "./views/CadastroPage/";
import LoginPage from "./views/LoginPage/";
import PaymentPage from "./views/CadastroPage/Steps/Pagamentos";
import PerfilPage from "./views/PerfilPage/";
import Dashboard from "./views/Eventos/";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ToggleButtons from "./components/ToggleGroupTheme/";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import axios from "./utils/configAxios";
import PrincingPage from "./views/Pricing/";
import path from "path-browserify";
import { Grid, CircularProgress } from "@mui/material";
import LeitorQrCode from "./views/Jobs/QRReader";
import backImage from "./images/background.png";
import BackLoginImage from "./images/backLogin.png";
import TermosCondicoes from "./views/Info/Termos";
import Inscricoes from "./views/Info/Inscricoes/";
import { red } from "@mui/material/colors";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const protectPathsRegex = [
  /^\/cadastro\/\d+\/(formulario|categoria|kit|participante|pagamento)$/,
  /^\/cadastro\/\d+/,
  /^\/inscricoes\/\d+/,
  /^\/home\/[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/,
  /^\/home\/[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*(\?password=[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)?$/,
  /^\/home\/firstAcess$/,
  /dash/,
  /^\/dash\/[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/,
  /home/,
  /pricing/,
  /onboarding/,
  /perfil/,
  /^\/perfil\/(complete|info)$/,
];

function Redirect({ onRedirect }) {
  const lastPath = window.location.pathname;

  // Executa a função extra antes de redirecionar
  if (onRedirect) {
    onRedirect(lastPath);
  }

  // Armazena a última rota visitada
  localStorage.setItem("lastRoute", lastPath);

  return <Navigate to="/login" />;
}
const handleRedirect = (lastPath) => {
  if (protectPathsRegex.some((regex, index) => regex.test(lastPath))) {
    localStorage.setItem("lastRoute", lastPath);
  }
};

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function App() {
  const [currentTheme, setCurrentTheme] = useState("light");
  const [isToggleButtonsVisible, setIsToggleButtonsVisible] = useState(false);
  const [state, setState] = useState({
    open: false,
    Transition: Slide,
  });
  const [loading, setLoading] = useState(true);

  const [paths, setPaths] = useState([
    "/login",
    "/home/:evento?",
    "/recuperar",
    "/criar",
    "/redefinir/:hash?",
    "/termos",
    "/pricing",
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/verificar-cadastro`,
        {
          userData: JSON.parse(localStorage.getItem("userData")), //dataManager.userData,
          token: localStorage.getItem("token"), //dataManager.token,
        }
      );
      setPaths([...paths, ...response.data]);
    } catch (error) {
      console.error("Error", error);
    }
    setLoading(false);
  };

  const alertCustom = (message, close) => {
    if (close) {
      return setState({
        open: false,
        Transition: SlideTransition,
        message,
      });
    }
    if (!state.open) {
      setState({
        open: true,
        Transition: SlideTransition,
        message,
      });
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    if (state.open) {
      setState({
        ...state,
        open: false,
      });
    }
  };

  const toggleTheme = () => {
    setCurrentTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const toggleButtonsVisibility = () => {
    setIsToggleButtonsVisible((prevVisibility) => !prevVisibility);
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#13BCE5",
        contrastText: "#000",
      },
      secondary: {
        main: "rgba(256,256,256,0.1)",
        contrastText: "#fff",
      },
      success: {
        main: "#2E7D32",
        contrastText: "#fff",
      },
      terciary: {
        main: "#212121",
        contrastText: "#fff",
      },
      transitions: {
        duration: {
          enteringScreen: 500,
          leavingScreen: 500,
        },
      },
      error: {
        main: "#ff0000",
      },
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#0C8CE9 ",
        contrastText: "#fff",
      },
      error: {
        main: "#FC0000",
        contrastText: "#fff",
      },
      secondary: {
        main: "rgba(0,0,0,0.2)",
        contrastText: "#fff",
      },
      success: {
        main: "#17C64F",
        contrastText: "#fff",
      },
      terciary: {
        main: "#fff",
        contrastText: "#000",
      },
      warning: {
        main: "#FFC700",
        contrastText: "#000",
      },
    },
  });

  const styles = {
    container: {
      backgroundColor: currentTheme === "dark" ? "#121212" : "#fff",
      // backgroundImage: `url(${backImage})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom", // Alinha a imagem ao centro e à parte inferior
      backgroundSize: "contain", // Ajusta a imagem para caber completamente no contêiner sem cortes
      alignItems: "top",
      minHeight: "100vh",
      height: "100%",
    },
    formTitle: {
      width: "100%",
      marginTop: "20px",
      textAlign: "center",
    },
    contentForm: {
      paddingBottom: "20px",
      alignSelf: "center",
      height: "100%",
    },
    subContainerLogin: {
      //backgroundColor: currentTheme === "light" ? "#fff" : "#212121",
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    labelText: {
      width: "100%",
      height: "50px",
    },
    inputField: {
      width: "100%",
      height: "70px",
    },
    imageLabel: {
      backgroundImage: `url(${BackLoginImage})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right", // Alinha a imagem à esquerda
    },
    toggleButtons: {
      position: "fixed",
      bottom: "20px",
      right: isToggleButtonsVisible ? "20px" : "-95px",
      transition: "right 0.3s ease-in-out",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      zIndex: 120,
      borderRadius: "5px",
      backgroundColor: currentTheme === "light" ? "#fff" : "#212121",
    },
  };

  const selectedTheme = currentTheme === "light" ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={selectedTheme}>
      {loading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Grid container>
          <Grid item xs={12} md={12} style={{ ...styles.container }}>
            <BrowserRouter>
              <Routes>
                {paths.map((path, index) => (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <RouteElement
                        fetchData={fetchData}
                        path={path}
                        alertCustom={alertCustom}
                        styles={styles}
                      />
                    }
                  />
                ))}
                <Route
                  path="*"
                  element={<Redirect onRedirect={handleRedirect} />}
                />
              </Routes>
            </BrowserRouter>
          </Grid>
        </Grid>
      )}

      {/* <div style={styles.toggleButtons}>
        <ToggleButtons
          toggleTheme={toggleTheme}
          handleClose={toggleButtonsVisibility}
          visibility={isToggleButtonsVisible}
        />
      </div> */}

      <Snackbar
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        message={state.message}
      />
    </ThemeProvider>
  );
}

function RouteElement({ path, alertCustom, styles, fetchData }) {
  handleRedirect(window.location.pathname);
  switch (path) {
    case "/login":
    case "/redefinir/:hash?":
    case "/recuperar":
    case "/criar":
      return (
        <LoginPage
          fetchData={fetchData}
          path={path}
          styles={styles}
          alertCustom={alertCustom}
        />
      );
    case "/pricing":
      return <PrincingPage alertCustom={alertCustom} />;

    case "/jobs/:transacao":
      return <LeitorQrCode alertCustom={alertCustom} />;
    case "/home/:evento?":
      return <Home alertCustom={alertCustom} />;
    case "/cadastro/:id/:passo?":
      return <CadastroPage alertCustom={alertCustom} />;
    case "/perfil/:acessType?":
      return <PerfilPage alertCustom={alertCustom} />;
    case "/pagamento":
      return <PaymentPage alertCustom={alertCustom} />;
    case "/dash/:local?":
      return <Dashboard alertCustom={alertCustom} />;
    case "/termos":
      return <TermosCondicoes />;
    case "/inscricoes/:idEvento?":
      return <Inscricoes alertCustom={alertCustom} />;

    default:
      return null;
  }
}

export default App;
