import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import axios from "../../utils/configAxios";
import DataAttemp from "../../utils/DataAttemp";
import SearchIcon from "@mui/icons-material/Search";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const SearchAutocomplete = ({
  senha,
  alertCustom,
  eventosList,
  setFiltredEventos,
  setEventosList,
  setLoading,
}) => {
  const [formatedOptions, setFormatedOptions] = useState([]);
  const [sugestions, setSugestions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const textFieldRef = useRef(null); // Referência para o campo de texto

  const dataManager = DataAttemp();
  const fetchEventos = async (filter) => {
    setLoading(true);
    try {
      const query = ["type=list"];
      if (senha) {
        query.push(`senha=${senha}`);
      }
      if (filter && filter.length > 6) {
        query.push(`filter=${filter}`);
      }
      if (dataManager?.userData) {
        query.push(`idUser=${dataManager.userData?.id}`);
      }

      const response = await axios.get(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/listar-eventos?${query.join(
          "&"
        )}`
      );
      if (Array.isArray(response.data)) {
        setEventosList(response.data);
        setFiltredEventos(response.data);
        const options = response.data.map((item, index) => ({
          key: index,
          title: item.titulo,
          value: item,
        }));
        setFormatedOptions(options);
      } else {
        alertCustom(
          "Os dados recebidos da API não estão em um formato esperado."
        );
      }
    } catch (error) {
      alertCustom("Erro ao carregar feed de eventos, verifique sua conexão!");
      console.error("Erro ao buscar eventos:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEventos();
  }, []);

  useEffect(() => {
    if (searchValue.length > 6) {
      fetchEventos(searchValue);
    } else if (searchValue.length == 0) {
      fetchEventos();
    }
  }, [searchValue]);

  const handleSearch = (value) => {
    if (value && value.length > 0) {
      setSearchValue(value);
      const filteredEventos = eventosList.filter((evento) => {
        return evento.titulo.toLowerCase().includes(value.toLowerCase());
      });

      if (filteredEventos.length === 0) {
        alertCustom("Nenhum resultado encontrado!");
      } else {
        setFiltredEventos(filteredEventos);
      }
    } else if (sugestions && sugestions.length > 0) {
      const filteredEventos = eventosList.filter((evento) => {
        return sugestions.includes(evento.id);
      });

      if (filteredEventos.length === 0) {
        alertCustom("Nenhum resultado encontrado!");
      } else {
        setFiltredEventos(filteredEventos);
      }
    } else {
      setFiltredEventos(eventosList);
    }
  };

  const handleInputChange = (event, value) => {
    if (value === "") {
      setSugestions([]);
    }
    setSearchValue(value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value);
      event.target.blur(); // Desfoca o campo de texto após a pesquisa
    }
  };

  const handleOptionChange = (event, value) => {
    if (value) {
      handleSearch(value);
      textFieldRef.current.blur(); // Desfoca o campo de texto após selecionar uma opção
    }
  };

  return (
    <Grid
      container
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
      spacing={1}
    >
      <Grid xs={12} item md={6}>
        <Autocomplete
          freeSolo
          size="small"
          id="free-solo-2-demo"
          disableClearable
          options={formatedOptions.map((option) => option.title)}
          value={searchValue}
          onInputChange={handleInputChange}
          onChange={handleOptionChange}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              placeholder="Encontrar eventos"
              {...params}
              inputRef={textFieldRef} // Adiciona a referência ao campo de texto
              InputProps={{
                ...params.InputProps,
                type: "search",
                onKeyDown: handleKeyPress,
                endAdornment: <SearchIcon color="secondary" />,
                sx: {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  background: "rgba(0,0,0,0.05)",
                  borderRadius: "50px",
                },
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default SearchAutocomplete;
