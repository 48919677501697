import React, { useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import expoImage from "../../../images/logo.png";
import {
  FormControlLabel,
  Checkbox,
  CardMedia,
  Paper,
  CardHeader,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DataAttemp from "../../../utils/DataAttemp";
import NavBar from "../../../components/NavegationBar/";
import { useMediaQuery } from "@mui/material";

function GridBanner({ insideComponent, styles }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const dataManager = DataAttemp();
  return (
    <Grid container sx={styles.subContainerLogin}>
      <NavBar contrast={true} />
      <Paper
        variant={isMobile ? "" : "outlined"}
        elevation={0}
        sx={{
          marginTop: "70px",
          background: { md: "#fff", xs: "transparent" },
        }}
      >
        <Grid
          container
          sx={{
            maxWidth: { md: "400px", xs: "100vh" },
            width: { md: "90vw", xs: "95vw" },
            overflow: "hidden",
            justifyContent: "center",
            borderRadius: "5px",
          }}
        >
          <Grid
            item
            xs={12}
            md={12}
            style={{
              padding: 10,
              minHeight: "520px",
            }}
          >
            {insideComponent}
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={8}
            sx={{
              ...styles.imageLabel,
              backgroundColor: "#2398EC",
              display: { lg: "block", md: "none", xs: "none", sm: "none" },
              backgroundSize: "auto 100%", // Ajusta a propriedade backgroundSize
            }}
          >
            <Grid container sx={{ height: "100%" }}>
              <Grid
                item
                md={12}
                sm={12}
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      sx={{ width: "60px", height: "70px" }}
                      src={expoImage}
                      aria-label="recipe"
                    />
                  }
                  title={
                    <Typography sx={{ color: "#fff" }}>
                      Goiânia Runners Tickets
                    </Typography>
                  }
                  subheader={
                    <a
                      href="https://goianiarunners.com.br"
                      style={{ textDecoration: "none", color: "#fff" }}
                    >
                      www.goianiarunners.com.br
                    </a>
                  }
                />
                <Typography
                  variant="h3"
                  sx={{
                    top: "-100px",
                    color: "#fff",
                    padding: 3,
                    backgroundImage:
                      "linear-gradient(to top right, rgba(0,0,0,0.3), rgba(0,0,0,0), rgba(0,0,0,0))",
                  }}
                >
                  <div>
                    <Typography variant="h3" sx={{ fontWeight: "bolder" }}>
                      Venha fazer parte
                      <Typography variant="h3" sx={{ fontWeight: "bolder" }}>
                        {" "}
                        do nosso time!
                      </Typography>
                    </Typography>
                  </div> 

                  <div
                    style={{
                      margin: "20px 0",
                    }}
                  >
                    <Typography variant="h6" sx={{ textAlign: "left" }}>
                      <div>Adquira já seu ingresso pela plataforma</div>
                      <div>
                        {" "}
                        com os{" "}
                        <span style={{ fontWeight: "bolder" }}>
                          melhores preços do mercado!
                        </span>
                      </div>
                    </Typography>
                    <Button
                      disableElevation
                      color="success"
                      size="large"
                      variant="contained"
                      sx={{
                        width: "350px",
                        borderRadius: "500px",
                        fontWeight: "bold",
                      }}
                      onClick={() => navigate("/home")}
                    >
                      Encontre seu evento aqui!
                    </Button>
                  </div>
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </Paper>
    </Grid>
  );
}

export default GridBanner;
