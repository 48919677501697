import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box"; // Importando o Box do Material-UI
import axios from "../../../utils/configAxios";
import { cpfMask } from "../../../utils/formatFunctions";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const Login = ({ alertCustom, formData, setFormulario, fetchData }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(formData?.email);
  const [senha, setSenha] = useState(formData?.senha);
  const [cpf, setCpf] = useState(formData?.cpf);
  const [loginCpf, setLoginCpf] = useState(formData?.loginCpf || false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setEmail(formData.email || "");
    setSenha(formData.senha || "");
    setCpf(formData.cpf || "");
    setLoginCpf(formData.loginCpf || false);
  }, [formData]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const data = {
        email: email,
        senha: senha,
        cpf: cpf,
      };
      const response = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/login`,
        data
      );
      if (response.data.token) {
        alertCustom("Autenticação bem sucedida!");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userData", JSON.stringify(response.data.user));
        localStorage.setItem("acessType", response.data.authorizationKey);
        response.data.organizador &&
          localStorage.setItem(
            "organizador",
            JSON.stringify(response.data.organizador)
          );
        await fetchData();
        if (["@adm", "@manager"].includes(response.data.authorizationKey)) {
          return navigate("/dash/dashboard");
        }
        return navigate(
          ["dash"].some((item) =>
            localStorage.getItem("lastRoute")?.includes(item)
          ) || !localStorage.getItem("lastRoute")
            ? "/home"
            : localStorage.getItem("lastRoute")
        );
      } else {
        alertCustom(
          "Credenciais inválidas, por favor, verifique seus dados e tente novamente."
        );
      }
    } catch (error) {
      alertCustom(error.response?.data?.error || error.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{ height: "100%", padding: 2 }} // Adicionando margem ao Box
    >
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        style={{ height: "100%" }}
      >
        <Grid item sx={{ width: "100%" }}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", marginTop: "20px" }}
          >
            Acesse sua conta
          </Typography>
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <Grid container component="main" spacing={2}>
            <Grid item xs={12} md={12}>
              {loginCpf ? (
                <TextField
                  variant="standard"
                  fullWidth
                  name="cpf"
                  label="CPF"
                  autoComplete="current-cpf"
                  value={cpfMask(cpf)}
                  onChange={(e) => setCpf(e.target.value)}
                  inputProps={{ maxLength: 14 }}
                />
              ) : (
                <TextField
                  variant="standard"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                variant="standard"
                fullWidth
                name="password"
                label="Senha"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ opacity: "0.5" }}
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                sx={{ width: "100%" }}
                control={
                  <Checkbox
                    checked={loginCpf}
                    value="cpfLogin"
                    color="primary"
                    onChange={(e) => {
                      setLoginCpf(e.target.checked);
                      if (!e.target.checked) {
                        setCpf("");
                      }
                    }}
                  />
                }
                label={
                  <Typography variant="body1" color="textSecondary">
                    Logar usando CPF
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={12}>
              <LoadingButton
                disableElevation
                size="medium"
                disabled={loading}
                fullWidth
                loadingPosition="end"
                variant="contained"
                color="primary"
                type="submit"
              >
                {loading ? "Carregando, aguarde..." : "Entrar"}
              </LoadingButton>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                disableElevation
                variant="text"
                disabled={loading}
                onClick={() => {
                  navigate("/criar");
                }}
              >
                Não tem uma conta? Crie a sua!
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                disableElevation
                variant="text"
                disabled={loading}
                onClick={() => {
                  navigate("/recuperar");
                }}
              >
                Esqueceu a senha?
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
