import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import axios from "../../../utils/configAxios";
import { InputAdornment } from "@mui/material";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const CupomDesconto = ({
  dadosCadastro,
  setDadosCadastro,
  alertCustom,
  evento,
}) => {
  const [cupom, setCupom] = useState("");
  const [isFocused, setIsFocused] = useState(false); // Estado para controle do foco
  const refAccess = useRef(null);
  const isMobile = window.innerWidth <= 600;

  const handleVerifyCupom = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/verificar-cupom/${cupom}?eventoId=${evento.id}`
      );
      alertCustom("Cupom adicionado!");
      setDadosCadastro({
        ...dadosCadastro,
        desconto: response.data.desconto,
      });
    } catch (error) {
      console.log(error);
      alertCustom("Ocorreu um erro ao inserir Cupom ou cupom é inválido!");
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        padding: 2,
        minHeight: { xs: "52vh", md: "60vh" },
        overflowX: "scroll",
        height: "100%",
      }}
    >
      <Grid
        container
        spacing={1}
        style={{
          display: isFocused && isMobile ? "block" : "flex", // Condicional baseado no foco
          flexDirection: "row",
          height: "100%",
          justifyContent: isFocused && isMobile ? "unset" : "space-between", // Remover justificação quando focado
        }}
      >
        <Grid item xs={12} md={12}>
          <Typography variant="h6">Cupom de desconto</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid
            container
            spacing={1}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={12}>
              <TextField
                ref={refAccess}
                fullWidth
                id="outlined-basic"
                label="Cupom de desconto"
                variant="outlined"
                placeholder="Digite um cupom promocional"
                onFocus={() => setIsFocused(true)} // Controla quando o campo é focado
                onBlur={() => setIsFocused(false)} // Controla quando o campo perde o foco
                onInput={(e) => setCupom(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Button
                        variant="contained"
                        disableElevation
                        color="secondary"
                        onClick={handleVerifyCupom}
                      >
                        Adicionar
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CupomDesconto;
