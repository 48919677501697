import React from "react";
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Chip,
  CardActionArea,
} from "@mui/material";

export default function SimplePaper(props) {
  const { edit, data, isSelected, setFunction, icon } = props;
  const getStatusChipColor = () => {
    if (data.numVagasDisponiveis === 0) {
      return "error";
    } else if (data.numVagasDisponiveis <= data.numVagas / 3) {
      return "warning";
    } else {
      return "success";
    }
  };

  const customStyle = {
    card: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      position: "relative",
      cursor: "pointer",
    },
  };

  return (
    <Card style={customStyle.card} elevation={0}>
      <CardActionArea
        sx={{ height: "100%", minHeight: "150px" }}
        onClick={() => {
          setFunction(data);
        }}
      >
        <CardContent
          sx={{
            textAlign: "center",
            display: "flex",
            flexWrap: "wrap",
            height: "100%",
            alignItems: "center",
          }}
          className="show-box"
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {icon}
          </div>
          {data && data.titulo && (
            <Typography
              sx={{ fontWeight: "bold", width: "100%" }}
              variant="button"
            >
              {data.titulo}
            </Typography>
          )}

          {data && data.criterio && (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ width: "100%" }}
            >
              {data.criterio}
            </Typography>
          )}

          {data && data.valorIngresso >= 0 && (
            <Typography
              variant="body2"
              color="secondaryText"
              sx={{ width: "100%" }}
            >
              {`Valor do ingresso: R$ ${Number(data.valorIngresso).toFixed(2)}`}
            </Typography>
          )}
          {data && (
            <Chip
              label={"Categoria"}
              color={"success"}
              size="small"
              sx={{
                marginTop: "10px",
                position: "absolute",
                top: -11,
                width: "50%",
                right: "25%",
                borderRadius: " 0 0 5px 5px",
              }}
            />
          )}

          {/* {data && (
            <>
              <Typography variant="body2" color="secondaryText">
                {`${data.numVagasDisponiveis}/${data.numVagas} vagas`}
              </Typography>

            
            </>
          )} */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
