import React from "react";
import Grid from "@mui/material/Grid";
import { Typography, Divider } from "@mui/material";
import { styled } from "@mui/system";
import Link from "@mui/material/Link";
import LogoFooter from "../../images/logo_footer.jpg";
import ImagePag from "../../images/formas_de_pagamento.png";

const FooterSection = styled("div")({
  padding: "16px",
  textAlign: "center",
});

const Footer = () => {
  return (
    <footer
      style={{
        color: "#000",
        margin: "50px 5px 5px 5px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid container sx={{ maxWidth: "1200px", borderRadius: "5px" }}>
        {/* Seção da imagem */}
        <Grid item xs={12} md={2.4}>
          <FooterSection>
            <img
              style={{ maxWidth: 200, marginBottom: "10px" }}
              src={LogoFooter}
              alt="Logo"
            />
            <Typography variant="body2" color="rgba(0, 0, 0, 0.5)">
              &copy;
              {`2016 - ${new Date().getFullYear()} | Goiânia Runners`}
            </Typography>
          </FooterSection>
        </Grid>
        {/* Seção de informações de contato */}
        <Grid item xs={12} md={2.4}>
          <FooterSection sx={{ textAlign: "center" }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold" }}
              color="rgba(0, 0, 0, 0.5)"
            >
              CONTATO
            </Typography>

            <Typography
              variant="body2"
              color="rgba(0, 0, 0, 0.5)"
              align="center"
            >
              goanianiarunners@gmail.com
            </Typography>
            <Typography
              variant="body2"
              color="rgba(0, 0, 0, 0.5)"
              align="center"
            >
              Goiânia-GO
            </Typography>
          </FooterSection>
        </Grid>
        <Grid item xs={12} md={2.4}>
          <FooterSection>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold" }}
              color="rgba(0, 0, 0, 0.5)"
            >
              LINKS
            </Typography>
            <Link
              href="#"
              color="rgba(0, 0, 0, 0.5)"
              underline="none"
              align="center"
              display="block"
            >
              Home
            </Link>
            <Link
              href="https://goianiarunners.com.br/"
              color="rgba(0, 0, 0, 0.5)"
              underline="none"
              align="center"
              display="block"
            >
              Blog
            </Link>
            <Link
              href="https://goianiarunners.com.br/"
              color="rgba(0, 0, 0, 0.5)"
              underline="none"
              align="center"
              display="block"
            >
              Treinamento de Corrida
            </Link>
            <Link
              href="https://goianiarunners.com.br/"
              color="rgba(0, 0, 0, 0.5)"
              underline="none"
              align="center"
              display="block"
            >
              Grupo de Corrida
            </Link>
            <Link
              href="https://goianiarunners.com.br/"
              color="rgba(0, 0, 0, 0.5)"
              underline="none"
              align="center"
              display="block"
            >
              Sobre Nós
            </Link>{" "}
          </FooterSection>
        </Grid>

        <Grid item xs={12} md={2.4}>
          <FooterSection sx={{ textAlign: "center" }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold" }}
              color="rgba(0, 0, 0, 0.5)"
            >
              ESPECIALIDADES
            </Typography>

            <Link
              href="#"
              color="rgba(0, 0, 0, 0.5)"
              underline="none"
              align="center"
              display="block"
            >
              Consultoria Online
            </Link>
            <Link
              href="#"
              color="rgba(0, 0, 0, 0.5)"
              underline="none"
              align="center"
              display="block"
            >
              Eventos de Corrida
            </Link>
          </FooterSection>
        </Grid>

        <Grid item xs={12} md={2.4}>
          <FooterSection sx={{ textAlign: "center" }}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold" }}
              color="rgba(0, 0, 0, 0.5)"
            >
              FORMAS DE PAGAMENTO
            </Typography>
            <img
              style={{
                maxWidth: 200,
                maxHeight: 100,
              }}
              src={ImagePag}
              alt="Logo"
            />
          </FooterSection>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
