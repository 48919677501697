import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Grid,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ChatIcon from "@mui/icons-material/Chat";
import PeopleIcon from "@mui/icons-material/People";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

const OlxAdCard = ({ onClick }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        margin: "auto",
        borderRadius: "16px",
        padding: 2,
        backgroundColor: "rgba(0,0,0,0.5)", // Fundo escuro
        borderColor: "#333", // Cor da borda
        color: "white", // Texto claro
      }}
    >
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Crie sua conta no GR Eventos e Esportes e aproveite todas as vantagens
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              elevation={0}
              sx={{
                backgroundColor: "#333",
                borderRadius: "16px", // Fundo escuro
                borderColor: "#444", // Cor da borda
                color: "white", // Texto claro
              }}
            >
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "primary.main" }}>
                    <AttachMoneyIcon sx={{ color: "white" }} />
                  </Avatar>
                }
                title={
                  <Typography variant="body2" color="white">
                    Anuncie seus eventos com as melhores taxas do mercado
                  </Typography>
                }
                sx={{ alignItems: "flex-start" }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              elevation={0}
              sx={{
                backgroundColor: "#333",
                borderRadius: "16px", // Fundo escuro
                borderColor: "#444", // Cor da borda
                color: "white", // Texto claro
              }}
            >
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "primary.main" }}>
                    <ChatIcon sx={{ color: "white" }} />
                  </Avatar>
                }
                title={
                  <Typography variant="body2" color="white">
                    Proximidade e inovação! O sistema recebe atualizações o
                    tempo todo para melhorar a experiência
                  </Typography>
                }
                sx={{ alignItems: "flex-start" }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              elevation={0}
              sx={{
                backgroundColor: "#333",
                borderRadius: "16px", // Fundo escuro
                borderColor: "#444", // Cor da borda
                color: "white", // Texto claro
              }}
            >
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "primary.main" }}>
                    <PeopleIcon sx={{ color: "white" }} />
                  </Avatar>
                }
                title={
                  <Typography variant="body2" color="white">
                    Se junte a nossa comunidade, somos mais de 3 mil usuários
                  </Typography>
                }
                sx={{ alignItems: "flex-start" }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card
              elevation={0}
              sx={{
                backgroundColor: "#333",
                borderRadius: "16px", // Fundo escuro
                borderColor: "#444", // Cor da borda
                color: "white", // Texto claro
              }}
            >
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: "primary.main" }}>
                    <TrendingUpIcon sx={{ color: "white" }} />
                  </Avatar>
                }
                title={
                  <Typography variant="body2" color="white">
                    Seus usuários com o melhor suporte
                  </Typography>
                }
                sx={{ alignItems: "flex-start" }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ textAlign: "center" }}>
            <Button
              disableElevation
              variant="contained"
              size="large"
              className="button-wave"
              color="primary" // Cor primária
              onClick={onClick}
              endIcon={<ArrowForwardRoundedIcon />}
            >
              Seja um Organizador
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OlxAdCard;
