import React, { useState } from "react";
import ChangePass from "./Direction/ChangePass.js";
import Recuperacao from "./Direction/Recuperate.js";
import Login from "./Direction/Login.js";
import Registrar from "./Direction/Create.js";
import GridBanner from "./Direction/GridExterno.js";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

export default function SignInSide(props) {
  const { styles, alertCustom, path, fetchData } = props;
  const [formData, setFormData] = useState({
    email: "",
    senha: "",
    cpf: "",
  });

  const renderForm = (path) => {
    switch (path) {
      case "/criar":
        return (
          <GridBanner
            insideComponent={
              <Registrar
                fetchData={fetchData}
                alertCustom={alertCustom}
                formData={formData}
                setFormulario={setFormData}
              />
            }
            styles={styles}
          />
        );
      case "/redefinir/:hash?":
        return (
          <GridBanner
            insideComponent={
              <ChangePass
                fetchData={fetchData}
                alertCustom={alertCustom}
                formData={formData}
                setFormulario={setFormData}
              />
            }
            styles={styles}
          />
        );
      case "/recuperar":
        return (
          <GridBanner
            insideComponent={
              <Recuperacao
                alertCustom={alertCustom}
                formData={formData}
                setFormulario={setFormData}
              />
            }
            styles={styles}
          />
        );
      case "/login":
      default:
        return (
          <GridBanner
            insideComponent={
              <Login
                fetchData={fetchData}
                alertCustom={alertCustom}
                formData={formData}
                setFormulario={setFormData}
              />
            }
            styles={styles}
          />
        );
    }
  };
  return renderForm(path);
}
