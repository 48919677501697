import React, { useState, useRef } from "react";
import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import QRCodeReader from "./QRCodeReader";
import NavBar from "../../../components/NavegationBar";
import CheckUser from "./CheckUser";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Tour from "../../Eventos/Tools/Tour/";

const Jobs = ({ alertCustom }) => {
  const params = useParams();
  return (
    <Grid container spacing={2}>
      <NavBar />
      <Tour />
      {!Number.isNaN(Number(params.transacao)) ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CheckUser alertCustom={alertCustom} />
        </Grid>
      ) : (
        params.transacao.toString().toLocaleLowerCase() === "scan" && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <QRCodeReader />
          </Grid>
        )
      )}
    </Grid>
  );
};

export default Jobs;
