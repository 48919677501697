import * as React from "react";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "../../utils/configAxios";
export default function FreeSolo({
  url,
  searchField,
  placeholder,
  label,
  setItemSelecionado,
  itemSelecionado,
  variant = "outlined",
}) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!itemSelecionado) {
      setInputValue("");
    }
    if (itemSelecionado) {
      setInputValue(itemSelecionado.email);
    }
  }, [itemSelecionado]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${url}?${searchField}=${inputValue}`);
      const data = await response.data;
      setOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (inputValue.length < 1) {
      setOptions([]);
      return;
    }

    const debounceTimeout = setTimeout(fetchData, 300);
    return () => clearTimeout(debounceTimeout);
  }, [inputValue, url, searchField]);

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Autocomplete
        freeSolo
        options={options}
        loading={loading}
        getOptionLabel={(option) => option.email}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(event, newValue) => {
          setItemSelecionado(newValue); // Define o item selecionado via props
        }}
        renderInput={(params) => (
          <TextField
            size="small"
            placeholder={placeholder}
            {...params}
            variant={variant}
            label={label}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </Stack>
  );
}
