import React, { useRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Carousel } from "react-bootstrap";
import { Grid } from "@mui/material";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

export default function Carrossel(props) {
  const { ArrayItems } = props;
  const carouselRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Ajuste o valor conforme necessário
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Chama a função para definir o estado inicial

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleItemClick = (link) => {
    if (link) {
      window.location.href = link;
    }
  };

  const novoItem = (bloco, index) => {
    return (
      <div
        key={index}
        onClick={() => handleItemClick(bloco.link)}
        style={{ cursor: "pointer", width: "100%", maxHeight: "630px" }}
      >
        <img
          src={`${REACT_APP_URL_BIBLIOTECA_RUNNERS}/images?file=Carrossel/${bloco.image}`}
          alt="banner"
          style={{ width: "100%", maxHeight: "630px" }}
        />
      </div>
    );
  };

  return (
    <Grid
      container
      sx={{
        borderRadius: "10px",
        border: "1px solid #f0f0f0",
        overflow: "hidden",
        minHeight: { md: "450px", xs: "0px" },
      }}
    >
      <Carousel
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          maxHeight: "450px",
        }}
        ref={carouselRef}
        controls={!isMobile}
        indicators={!isMobile}
      >
        {ArrayItems.map((group, index) => (
          <Carousel.Item
            key={index}
            style={{
              position: "relative",
              padding: group.type === "image" ? "50px 10px" : "",
            }}
          >
            {novoItem(group, index)}
          </Carousel.Item>
        ))}
      </Carousel>
    </Grid>
  );
}
