import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";

const Timer = ({
  start,
  setOpenDialog,
  setConfirmMessage,
  setRoute,
  dataInscrição,
}) => {
  const [time, setTime] = useState({
    count: 900,
    text: "15:00",
    status: true,
    start: false,
  });

  useEffect(() => {
    if (start) {
      const calculateRemainingTime = () => {
        const currentTime = new Date();
        const startTime = new Date(dataInscrição);
        const elapsedTime = Math.floor((currentTime - startTime) / 1000); // tempo decorrido em segundos
        const newCount = Math.max(900 - elapsedTime, 0); // 900 segundos = 15 minutos
        const minutes = String(Math.floor(newCount / 60)).padStart(2, "0");
        const seconds = String(newCount % 60).padStart(2, "0");

        if (newCount === 0) {
          setConfirmMessage(
            "O tempo disponível para finalizar a inscrição se esgotou..."
          );
          setRoute("/cadastro");
          setOpenDialog(true);
        }

        return {
          status: true,
          text: `${minutes}:${seconds}`,
          count: newCount,
        };
      };

      setTime(calculateRemainingTime());

      const intervalId = setInterval(() => {
        setTime(calculateRemainingTime());
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [start, setOpenDialog, setConfirmMessage, setRoute, dataInscrição]);

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      className="show-box"
      sx={{
        width: { xs: "70vw", md: "100%" },
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      Tempo restante <b> {time.text}</b>
    </Typography>
  );
};

export default Timer;
