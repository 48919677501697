// formatarLocalizacao.js

export function formatarLocalizacao(localizacao) {
  // Dividir a string em partes usando a vírgula como delimitador
  const partes = localizacao.split(",");
  const stringFormatada = partes
    .filter((loc) => !loc.includes("Região"))
    .join(", ");
  return stringFormatada;
}
