import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import tutorialGIFAbrirComprovante from "../../images/Gifs/gif_abrir_comprovante.gif";
import tutorialGIFEditarPerfil from "../../images/Gifs/gif_editar_perfil.gif";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

const FaqDialog = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [selectedFaq, setSelectedFaq] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    navigate("/perfil/help");
  };

  const handleClose = () => {
    if (!selectedFaq) {
      setOpen(false);
      navigate("/perfil");
    } else {
      setSelectedFaq(null);
    }
  };

  const faqs = [
    {
      id: 1,
      question: "Como abrir meu comprovante?",
      type: "gif",
      text: "Acessando mais opções no evento, você pode emitir tanto um comprovante em PDF quanto um QRCode que comprova sua participação no evento.",
      content: tutorialGIFAbrirComprovante,
    },
    {
      id: 2,
      question: "Como faço para alterar meus dados pessoais?",
      type: "gif",
      text: "Você pode alterar seus dados pessoais acessando mais opções e clicando em 'Editar Perfil'",
      content: tutorialGIFEditarPerfil,
    },
    {
      id: 3,
      question: "Como recupero minha senha?",
      type: "gif",
      text: "Para recuperar sua senha, clique em 'Editar Perfil' e depois 'mudar senha' ou então em 'Esqueci minha senha' na página de login e siga as instruções. Logo após você deve receber um e-mal para trocar sua senha.",
      content: tutorialGIFEditarPerfil,
    },
    {
      id: 4,
      question: "Outros assuntos",
      type: "text",
      text: "Você pode entrar em contato conosco via e-mail goianiarunners@gmail.com informando sua dúvida. Estaremos de prontidão para auxiliar no que for preciso",
      content: tutorialGIFEditarPerfil,
    },
  ];

  const handleFaqClick = (faq) => {
    setSelectedFaq(faq);
  };

  return (
    <Grid sx={{ padding: 2, textAlign: "center" }}>
      <Button
        disableElevation
        variant="text"
        fullWidth={window.innerWidth < 600}
        onClick={handleClickOpen}
        sx={{ padding: "5px 10px" }}
      >
        Precisa de ajuda ?
      </Button>
      <Dialog
        fullScreen={selectedFaq?.type === "gif" && window.innerWidth < 600} // Tela cheia para dispositivos móveis se for o GIF
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <span>Dúvidas Frequentes</span>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!selectedFaq ? (
            <List>
              {faqs.map((faq) => (
                <ListItem
                  sx={{ margin: 1, borderRadius: "8px" }}
                  button
                  key={faq.id}
                  onClick={() => handleFaqClick(faq)}
                >
                  <ListItemText primary={faq.question} />
                </ListItem>
              ))}
            </List>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {selectedFaq.type === "gif" ? (
                <>
                  <Box mb={2}>
                    <ListItemText primary={selectedFaq.text} />
                  </Box>
                  <img
                    src={selectedFaq.content}
                    alt={selectedFaq.question}
                    style={{
                      width: window.innerWidth < 600 ? "100%" : "50%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                </>
              ) : (
                <Box p={3}>
                  <ListItemText primary={selectedFaq.text} />
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="center" width="100%">
            <Button disableElevation variant="contained" onClick={handleClose}>
              Compreendi
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default FaqDialog;
