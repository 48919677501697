import React, { useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box"; // Importando o Box do Material-UI
import axios from "../../../utils/configAxios";
import { cpfMask } from "../../../utils/formatFunctions";
import { useNavigate } from "react-router-dom";
import { IconButton, InputAdornment, Link } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const Criar = ({ alertCustom, formData, setFormulario }) => {
  const navigate = useNavigate();
  const inputCpfRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verifica se todos os campos obrigatórios estão preenchidos
    if (
      !formData.nome ||
      !formData.lastName ||
      !formData.email ||
      !formData.cpf ||
      !formData.senha ||
      !formData.passwordConfirm
    ) {
      alertCustom("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    // Verifica se a senha e a confirmação de senha são diferentes
    if (formData.senha !== formData.passwordConfirm) {
      alertCustom("A senha e a confirmação de senha devem ser iguais.");
      return;
    }

    // Verifica se a senha tem pelo menos 6 caracteres
    if (formData.senha.length < 5 || formData.passwordConfirm.length < 5) {
      alertCustom("A senha deve ter pelo menos 5 caracteres.");
      return;
    }
    if (!formData.agreedToTerms) {
      alertCustom("Concorde com os termos para prosseguir.");
      return;
    }

    setLoading(true);

    const data = {
      nome: formData.nome + " " + formData.lastName,
      telefone: "não informado",
      foto: "default",
      endereco: "não informado",
      auth: {
        email: formData.email,
        senha: formData.senha,
        cpf: formData.cpf,
      },
    };

    try {
      const response = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/cadastrar`,
        data
      );

      alertCustom(response.data.message);
      localStorage.setItem("userData", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);
      alertCustom("Conta criada com sucesso!");
      localStorage.setItem("acessType", response.data.authorizationKey);
      if (["@adm", "@manager"].includes(response.data.authorizationKey)) {
        return navigate("/dash/dashboard");
      }
      return navigate(
        ["dash"].some((item) =>
          localStorage.getItem("lastRoute")?.includes(item)
        ) || !localStorage.getItem("lastRoute")
          ? "/home"
          : localStorage.getItem("lastRoute")
      );
      window.location.reload();
    } catch (error) {
      alertCustom(
        error.response?.data?.error ||
          error.response?.data?.message ||
          "Erro ao criar conta"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        flexWrap: "wrap",
        padding: 2,
      }}
      component="form"
      onSubmit={handleSubmit}
      noValidate
    >
      <Grid container component="main">
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", marginTop: "20px" }}
          >
            Crie sua conta
          </Typography>
        </Grid>
        <Grid item xs={12} alignContent="center">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                id="firstName"
                label="Nome"
                value={formData.nome}
                onChange={(e) =>
                  setFormulario({ ...formData, nome: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                fullWidth
                id="lastName"
                label="Sobrenome"
                value={formData.lastName}
                onChange={(e) =>
                  setFormulario({ ...formData, lastName: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                id="email"
                label="Email"
                value={formData.email}
                onChange={(e) =>
                  setFormulario({ ...formData, email: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                name="cpf"
                label="CPF"
                value={cpfMask(formData.cpf)}
                onInput={(event) => {
                  event.target.value = cpfMask(event.target.value);
                  setFormulario({ ...formData, cpf: event.target.value });
                }}
                inputRef={inputCpfRef}
                inputProps={{ maxLength: 14 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                name="password"
                label="Senha"
                type={showPassword ? "text" : "password"}
                value={formData.senha}
                onChange={(e) =>
                  setFormulario({ ...formData, senha: e.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ opacity: "0.5" }}
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                name="confirmPassword"
                label="Confirme a Senha"
                type={showPassword ? "text" : "password"}
                value={formData.passwordConfirm}
                onChange={(e) =>
                  setFormulario({
                    ...formData,
                    passwordConfirm: e.target.value,
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ opacity: "0.5" }}
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.agreedToTerms}
                    onChange={(e) =>
                      setFormulario({
                        ...formData,
                        agreedToTerms: e.target.checked,
                      })
                    }
                  />
                }
                label={
                  <Typography variant="body1" color="textSecondary">
                    <span>
                      Concordo com os{" "}
                      <Link onClick={() => navigate("/termos")}>
                        termos e condições
                      </Link>
                    </span>
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} alignContent="end">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                fullWidth
                disableElevation
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={handleSubmit}
              >
                Criar Conta
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                disableElevation
                variant="text"
                disabled={loading}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Possui uma conta? Acesse.
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Criar;
