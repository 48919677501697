import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
  Paper,
  Divider,
  Typography,
  Button,
  Chip,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import DialogQRCOde from "./dialogQRcode";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import axios from "../../../../utils/configAxios";
import { format } from "date-fns";
import { Notifications } from "@mui/icons-material";
import DataAttemp from "../../../../utils/DataAttemp";
import NewNotification from "./newNotificacao";

const { REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM } = process.env;

const Notification = ({ alertCustom }) => {
  const DataManager = DataAttemp();
  const [openDialog, setOpenDialog] = useState(false);
  const [validQRcode, setValidQRcode] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/config/listar-mensagens?email=${DataManager.userData?.auth?.email}`
      );
      const sortedNotifications = response.data.reverse();
      setNotifications(sortedNotifications);
    } catch (error) {
      console.error("Erro ao buscar notificações:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleCloseNotification = async (id) => {
    setSelectedNotification(null);
    try {
      await axios.delete(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/config/remover-mensagem/${id}`
      );
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== id)
      );
    } catch (error) {
      console.error("Erro ao excluir notificação:", error);
    }
  };

  const handleNotificationClick = async (notification) => {
    setSelectedNotification(notification);
    if (notification.status) {
      try {
        await axios.put(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/config/atualizar-mensagem/${notification.id}`,
          { ...notification, status: false }
        );

        setNotifications((prevNotifications) =>
          prevNotifications.map((n) =>
            n.id === notification.id ? { ...n, status: false } : n
          )
        );
      } catch (error) {
        console.error("Erro ao atualizar status da notificação:", error);
      }
    }
  };

  const handleShowQRCode = () => {
    setOpenDialog(true);
    setValidQRcode(selectedNotification.anexo);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {localStorage.acessType == "@adm" && (
            <NewNotification alertCustom={alertCustom} />
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                Notificações
                <Typography variant="body2" color="textSecondary">
                  Solicitações de cobrança, erros e insumo extra para inscrições
                </Typography>
              </Typography>
              <Typography>
                <Tooltip title="Atualizar">
                  <IconButton onClick={fetchNotifications}>
                    {loading ? (
                      <CircularProgress color="secondary" size={25} />
                    ) : (
                      <ReplayRoundedIcon size={25} />
                    )}
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper variant="outlined">
                <List sx={{ padding: 0 }}>
                  {notifications.length > 0 ? (
                    notifications.map((notification) => (
                      <div key={notification.id}>
                        <ListItem
                          button
                          selected={
                            selectedNotification?.id === notification.id
                          }
                          onClick={() => handleNotificationClick(notification)}
                        >
                          <ListItemText
                            secondary={`${notification.message
                              .toString()
                              .substring(0, 32)}...`}
                            primary={
                              <Typography>De: {notification.from}</Typography>
                            }
                          />
                          {notification.status && (
                            <Chip label="new" size="small" color="success" />
                          )}
                        </ListItem>
                        {notifications.length > 1 && <Divider />}
                      </div>
                    ))
                  ) : (
                    <Typography
                      color="textSecondary"
                      className="show-box"
                      sx={{ textAlign: "center" }}
                    >
                      Sem Mensagens
                    </Typography>
                  )}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          {selectedNotification && notifications.length > 0 && (
            <Paper variant="outlined" sx={{ p: 2 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ marginBottom: "20px" }}
                >
                  De: {selectedNotification.from}{" "}
                  <Typography variant="body1">
                    Para: {selectedNotification.to}
                  </Typography>
                </Typography>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{ marginBottom: "20px" }}
                >
                  {format(
                    new Date(selectedNotification.data),
                    "dd/MM/yyyy HH:mm"
                  )}
                </Typography>
              </div>

              <Typography sx={{ margin: "20px 0" }}>
                {selectedNotification.message}
              </Typography>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {selectedNotification.anexo && (
                  <Button
                    sx={{ padding: "5px 15px" }}
                    onClick={handleShowQRCode}
                  >
                    Mostrar Anexo
                  </Button>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="secondary"
                  sx={{ padding: "5px 15px", borderRadius: "50px" }}
                  onClick={() =>
                    handleCloseNotification(selectedNotification.id)
                  }
                >
                  Excluir mensagem
                </Button>
              </div>
            </Paper>
          )}
        </Grid>
      </Grid>

      <DialogQRCOde
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setValidQRcode={setValidQRcode}
        validQRcode={validQRcode}
        alertCustom={alertCustom}
        fetchFunction={fetchNotifications}
      />
      {/* <button onClick={cadastrarMensagem}>Cadastrar Nova Mensagem</button> */}
    </>
  );
};

export default Notification;
