import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = userData ? userData.auth.token : null;
    const organizador = JSON.parse(localStorage.getItem("organizador"));
    const accessId = organizador?.id;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (accessToken) {
      config.headers["X-Access-Token"] = accessToken;
    }

    if (accessId) {
      config.headers["access_org"] = accessId;
      config.headers["access_org_key"] = userData.auth.key;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("organizador");
      localStorage.removeItem("lastRoute");
      const allowedPaths = ["login", "recuperar", "criar", "redefinir"];
      const currentPath = window.location.pathname.split("/")[1];
      if (!allowedPaths.includes(currentPath)) {
        window.location.reload();
      }
    }

    return Promise.reject(error);
  }
);

export default api;
