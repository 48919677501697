import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useDropzone } from "react-dropzone";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import axios from "../../../../utils/configAxios";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import ClassificacaoIndicativa from "../../Cadastrar/ClassificacaoIndicativa";
import {
  Typography,
  Card,
  CardHeader,
  Divider,
  Avatar,
  CardMedia,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import NewCategoria from "./NewCategoria";
import RenderCategoria from "./RenderCategoria";
import SimplePaper from "../../../../components/Paper/";
import ConfirmDeleteModal from "../../../../components/Modais/confirmModal";
import BannerDefault from "../../../../images/banner.png";
import PerfilDefault from "../../../../images/perfil.png";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import AlertDialog from "../../Cadastrar/AlertDialog";
import Autocomplete from "../../../../components/AutoComplete/";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UploadDialog from "./UploadDialog";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SimpleList from "../../../../components/List/";
import AddIcon from "@mui/icons-material/Add";
import ModalLote from "./ModalLote";
import ProductCards from "./ProdutosCards";
import DialogCupons from "../../Cadastrar/CupomPromocional";
import { useNavigate } from "react-router-dom";
const REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM;

export default function CriarEvento({ setEventoToEdit, eventoData }) {
  const [eventoToPut, setEventoToPut] = useState(eventoData);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const navigate = useNavigate();
  const [stateAlert, setStateAlert] = useState({
    open: false,
    message: "",
    buttonLabel: "",
    targetRoute: "/dash/dashboard",
  });
  const [uploadFilesExtras, setUploadFilesExtras] = useState(
    eventoData?.images || []
  );
  const [filesExtrasToRemove, setFilesExtrasToRemove] = useState([]);
  const [state, setState] = useState({
    open: false,
    Transition: Slide,
    message: "",
  });
  const [categoriaOptions, setCategoriaOptions] = useState([]);
  const [tabelasProdutos, setTabelasProdutos] = useState([]);
  const [formularios, setFormularios] = useState([]);
  const [organizadores, setOrganizadores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedFormulario, setSelectedFormulario] = useState(null);
  const [selectedOrganizador, setSelectedOrganizador] = useState(null);
  //const [location, setLocation] = useState(eventoData.localizacao);
  const [tabelaPrecoSelecionada, setTabelaPrecoSelecionada] = useState(null);
  const [arquivos, setArquivos] = useState([]);

  const [categoriaModalOpen, setCategoriaModalOpen] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [groupCategoria, setGroupCategoria] = useState({
    title: eventoToPut.categoriaName,
  });
  const [categoriaAtual, setCategoriaAtual] = useState(null);

  const maskHorario = (value) => {
    if (!value) return "";

    value = value.replace(/\D/g, "");

    if (value.length <= 4) {
      let temp = value.padEnd(4, "0");
      let tt = value.split("");
      if (temp > 2359) {
        tt.pop();
        value = tt.join();
      }
      value = value.replace(/(\d{2})(\d)/, "$1:$2");
    } else {
      value = value.slice(0, 4);
    }

    return value;
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  useEffect(() => {
    try {
      axios
        .get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/listar-nome-categorias`
        )
        .then((response) => {
          setCategoriaOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/produto/listar-tabelas`
        )
        .then((response) => {
          setTabelasProdutos(response.data);
          response.data.length === 0 &&
            setStateAlert((prev) => ({
              ...prev,
              open: true,
              message:
                "Você precisa cadastrar ao menos um produto / kit para começar seu evento",
              buttonLabel: "Cadastre agora mesmo",
              targetRoute: "/dash/produtos",
            }));
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(`${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/formulario/listar`)
        .then((response) => {
          setFormularios(response.data);
          response.data.length === 0 &&
            setStateAlert((prev) => ({
              ...prev,
              open: true,
              message:
                "Você precisa cadastrar ao menos um formulário para começar seu evento",
              buttonLabel: "Cadastre agora mesmo",
              targetRoute: "/dash/formularios",
            }));
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/organizadores/listar-organizadores`
        )
        .then((response) => setOrganizadores(response.data))
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      alertCustom(
        "Erro ao buscar dados, verifique sua conexão antes de prosseguir!"
      );
    }
  }, []);

  const alertCustom = (message) => {
    setState({
      open: true,
      Transition: SlideTransition,
      message,
    });
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  function gerarSenha() {
    const caracteres =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let senha = "";
    for (let i = 0; i < 10; i++) {
      const indice = Math.floor(Math.random() * caracteres.length);
      senha += caracteres.charAt(indice);
    }
    return senha;
  }

  const [perfilLoading, setPerfilLoading] = useState(PerfilDefault);
  const [bannerLoading, setBannerLoading] = useState(BannerDefault);
  const [cuponsPromocionais, setCuponsPromocionais] = useState(
    eventoData.cupons
  );
  useEffect(() => {
    if (eventoToPut) {
      setEventoToPut({
        id: eventoData.id,
        status: eventoData.status || false,
        titulo: eventoData.titulo || "",
        subTitulo: eventoData.subTitulo || "",
        bannerImage: eventoData.bannerImage || "",
        perfilImage: eventoData.perfilImage || "",
        tabelaPreco: eventoData.tabelaPreco || "",
        data: eventoData.data || "",
        dataFinal: eventoData.dataFinal || "",
        dataEvento: eventoData.dataEvento || "",
        horario: eventoData.horario || "",
        categoriaName: eventoData.categoriaName,
        organizador: eventoData.organizador,
        formulario: eventoData.formulario || "",
        regulamento: eventoData.regulamento || "",
        cupons: eventoData.cupons ?? [],
        localizacao: eventoData.localizacao || "A definir",
        senha: eventoData.senha || gerarSenha(),
        tipo: eventoData.tipo,
        numVagas: eventoData.numVagas || 0,
        horarioInscricoes: eventoData.horarioInscricoes || "",
        classificacao: eventoData.classificacao || "",
      });
      setPerfilLoading(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/images?file=Eventos/` +
          eventoData.perfilImage || PerfilDefault
      );
      setBannerLoading(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/images?file=Eventos/` +
          eventoData.bannerImage || BannerDefault
      );
      setGroupCategoria({ title: eventoData.categoriaName });
      // setLocation(eventoData.localizacao);
      setSelectedFormulario(eventoData.formulario);
      setSelectedOrganizador(eventoData.organizador);
      setTabelaPrecoSelecionada(eventoData.tabelaPreco || "");
      setArquivos([]);
    }
  }, [eventoData]);

  const handleDeleteEvento = () => {
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/deletar-evento/${eventoData.id}`,
        {
          categoriaName: eventoData.categoriaName,
        }
      );
      if (eventoData.images && eventoData.images.length > 0) {
        Promise.all(
          eventoData.images.map((fileRemove) => {
            try {
              return axios.post(
                `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/upload/${
                  fileRemove.campo
                }?anteriores=${JSON.stringify(
                  eventoData.images.map((file) => {
                    if (file.campo == fileRemove.campo) {
                      return file.src;
                    }
                  })
                )}`
              );
            } catch (error) {
              console.log(error);
            }
          })
        );
      }
      alertCustom(response.data.message);
      console.log(response.data);
    } catch (error) {
      alertCustom(
        `${error.response.data.message}, total: ${error.response.data.total}` ??
          "Erro ao excluir evento!"
      );
      console.error("Erro:", error);
    } finally {
      setEventoToEdit(null);
      setConfirmDeleteOpen(false);
      setState({
        ...state,
        open: false,
      });
    }
  };

  const handleUploadFilesExtras = async () => {
    try {
      if (uploadFilesExtras.length === 0) {
        return [];
      }
      const urls = [];

      if (filesExtrasToRemove && filesExtrasToRemove.length > 0) {
        //unlink nos que não existem mais
        Promise.all(
          filesExtrasToRemove.map((fileRemove) => {
            return axios.post(
              `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/upload/${
                fileRemove.campo
              }?anteriores=${JSON.stringify(
                filesExtrasToRemove.map((file) => {
                  if (file.campo == fileRemove.campo) {
                    return file.src;
                  }
                })
              )}`
            );
          })
        );
      }
      try {
        //fazer upload dos novos, que tem o paramtro file no objeto
        for (const file of uploadFilesExtras) {
          if ("file" in file) {
            const formData = new FormData();
            const newName = `${file.titulo}.${file.type}`;
            const novoArquivo = new File([file.file], newName, {
              type: file.file.type,
            });
            formData.append("fotos", novoArquivo);

            const response = await axios.post(
              `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/upload/${file.campo}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            urls.push({
              titulo: file.titulo,
              campo: file.campo,
              src: response.data.fileNames[0],
              descricao: file.descricao,
              link: file.link,
            });
          } else {
            //não possui o parametro file, é arquivo que já existe e não foi deletado
            !filesExtrasToRemove.some(
              (fileExists) => file.src == fileExists.src
            ) && urls.push(file);
          }
        }
      } catch (err) {
        throw new Error(err.response.data.error);
      }

      alertCustom("Arquivos adicionais enviados com sucesso!");
      return urls;
    } catch (error) {
      alertCustom("Erro no envio dos arquivos adicionais!");
      throw new Error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (arquivos.length === 0 && (!bannerLoading || !perfilLoading)) {
      return;
    }

    // Validação dos campos obrigatórios
    const requiredFields = [
      { field: eventoToPut.titulo, name: "Título" },
      { field: eventoToPut.subTitulo, name: "Subtítulo" },
      // { field: eventoToPut.perfilImage, name: "Imagem de perfil" },
      { field: eventoToPut.tabelaPreco, name: "Configurar valores" },
      { field: eventoToPut.data, name: "Data de início" },
      { field: eventoToPut.dataFinal, name: "Data final" },
      { field: eventoToPut.dataEvento, name: "Data do evento" },
      { field: eventoToPut.horario, name: "Horário" },
      {
        field: eventoToPut.horarioInscricoes,
        name: "Horário de Início das inscrições",
      },
      { field: eventoToPut.categoriaName, name: "Nome da categoria" },
      { field: selectedOrganizador, name: "Organizador" },
      { field: selectedFormulario, name: "Formulário" },
      { field: eventoToPut.regulamento, name: "Regulamento" },
      { field: eventoToPut.localizacao, name: "Localização" },
    ];

    for (const { field, name } of requiredFields) {
      if (!field) {
        setLoading(false);
        alertCustom(`Por favor, preencha o campo obrigatório: ${name}!`);
        return;
      }
    }

    try {
      let fotoBanner = eventoToPut.bannerImage;
      let fotoPerfil = eventoToPut.perfilImage;

      const urls = await handleUploadFilesExtras();

      if (arquivos.length > 0) {
        const ant = [eventoData.bannerImage, eventoData.perfilImage];
        const formData = new FormData();
        const filesAnteriores = [];
        arquivos.forEach((file) => {
          const novoNome = `${file.inputName}-${file.file.name}`;
          const novoArquivo = new File([file.file], novoNome, {
            type: file.file.type,
          });
          formData.append("fotos", novoArquivo);

          const fileA = ant.find((str) => str.includes(file.inputName));
          fileA && filesAnteriores.push(fileA);
        });

        try {
          const response = await axios.post(
            `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/upload/Eventos?anteriores=${JSON.stringify(
              filesAnteriores
            )}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          alertCustom(response.data.message);

          const files = response.data.fileNames;

          for (const file of files) {
            if (file.includes("Banner")) {
              fotoBanner = file;
            } else if (file.includes("Perfil")) {
              fotoPerfil = file;
            }
          }
        } catch (error) {
          console.error("Erro:", error);
          throw new Error(error.response.data.error);
        }
      }
      const imgs = eventoData?.images || [];
      const data = {
        evento: {
          ...eventoToPut,
          bannerImage: fotoBanner,
          perfilImage: fotoPerfil,
          formularioId: selectedFormulario.id,
          categoriaName: groupCategoria.title,
          images: [...urls],
          cupons: eventoToPut.cupons,
          senha: eventoToPut.senha || gerarSenha(),
          tipo: eventoToPut.tipo,
          cupons: cuponsPromocionais,
        },
        categorias: categorias,
        organizador: selectedOrganizador,
      };

      // Adicionando arquivos anteriores apenas se houver arquivos novos
      if (arquivos.length > 0) {
        data.arquivosAnteriores = [
          eventoData.bannerImage,
          eventoData.perfilImage,
        ];
      }
      const response2 = await axios.put(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/atualizar-evento/${eventoData.id}`,
        data
      );

      alertCustom(response2.data.message);
      navigate("/dash/dashboard");
    } catch (error) {
      alertCustom(`Erro ao atualizar evento`);
      console.error("Erro:", error);
    }

    setLoading(false);
  };

  const onDrop = (acceptedFiles, inputName) => {
    console.log(acceptedFiles);
    const filteredFiles = arquivos.filter(
      (file) => file.inputName !== inputName
    );

    if (inputName === "Perfil") {
      setPerfilLoading(URL.createObjectURL(acceptedFiles[0]));
    } else if (inputName === "Banner") {
      setBannerLoading(URL.createObjectURL(acceptedFiles[0]));
    }

    setArquivos([
      ...(filteredFiles ? filteredFiles : {}),
      ...Object.values(acceptedFiles).map((file) => ({ inputName, file })),
    ]);
  };

  // const dropzonePerfil = useDropzone({
  //   onDrop: (acceptedFiles) => onDrop(acceptedFiles, "Perfil"),
  // });

  // const dropzoneBanner = useDropzone({
  //   onDrop: (acceptedFiles) => onDrop(acceptedFiles, "Banner"),
  // });

  // const {
  //   getRootProps: getPerfilRootProps,
  //   getInputProps: getPerfilInputProps,
  // } = dropzonePerfil;

  // const {
  //   getRootProps: getBannerRootProps,
  //   getInputProps: getBannerInputProps,
  // } = dropzoneBanner;

  const handleDelete = (file) => {
    const filteredFiles = arquivos.filter(
      (f) => f.inputName !== file.inputName
    );
    setArquivos(filteredFiles);

    if (file.inputName === "Perfil") {
      setPerfilLoading(PerfilDefault);
    } else if (file.inputName === "Banner") {
      setBannerLoading(BannerDefault);
    }
  };

  function encontrarValorIngressoPorId(categoriasDoEvento, id) {
    for (var i = 0; i < categoriasDoEvento.length; i++) {
      if (categoriasDoEvento[i].categoria.id === id) {
        return {
          valorIngresso: categoriasDoEvento[i].valorIngresso,
          vagasDisponiveis: categoriasDoEvento[i].numVagas,
        };
      }
    }
    return { valorIngresso: 0, vagasDisponiveis: 0 };
  }

  useEffect(() => {
    const fetchData = async () => {
      if (groupCategoria && !groupCategoria.created) {
        await axios
          .get(
            `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/listar-categorias/${groupCategoria.title}`
          )
          .then((response) => {
            const categoriasData = response.data;
            if (Array.isArray(categoriasData)) {
              // Filtrar as categorias que existem em eventoData.categorias
              const categoriasExistentes = categoriasData.filter((categoria) =>
                eventoData.categorias.some(
                  (categoriaExistente) =>
                    categoriaExistente.categoria.titulo === categoria.titulo &&
                    categoriaExistente.categoria.criterio === categoria.criterio
                )
              );
              const categoriaRemaped = [];
              for (const categoria of categoriasExistentes) {
                const dadosExternos = encontrarValorIngressoPorId(
                  eventoData.categorias,
                  categoria.id
                );

                categoriaRemaped.push({
                  numVagasDisponiveis: dadosExternos.vagasDisponiveis,
                  numVagas: categoria.numVagas,
                  titulo: categoria.titulo,
                  criterio: categoria.criterio,
                  grupo: categoria.grupo,
                  valorIngresso: dadosExternos.valorIngresso,
                });
              }
              setCategorias(categoriaRemaped);
            } else {
              console.error(
                "A resposta do servidor não é um array:",
                categoriasData
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setCategorias([]);
      }
    };
    fetchData();
  }, [groupCategoria]);

  const handleSalvarCategoria = (novaCategoria, update, categoriaToUpdate) => {
    if (update) {
      const index = categorias.indexOf(categoriaToUpdate);
      categorias[index] = novaCategoria;
      setCategorias(categorias);
    } else {
      eventoToPut.categoriaName = groupCategoria;
      setCategorias([...categorias, novaCategoria]);
    }

    setCategoriaAtual(null);
    setCategoriaModalOpen(false);
  };

  const handleEditarCategoria = (categoria) => {
    setCategoriaAtual(categoria);
    setCategoriaModalOpen(true);
  };

  const handleDeleteCategoria = (categoriaToDelete) => {
    const updatedCategorias = categorias.filter(
      (categoria) => categoria !== categoriaToDelete
    );
    setCategorias(updatedCategorias);
    setCategoriaModalOpen(false);
  };

  const deleteArquivo = (index) => {
    const newFiles = [...uploadFilesExtras];
    const fileAnterior = newFiles.splice(index, 1);
    setFilesExtrasToRemove([...filesExtrasToRemove, ...fileAnterior]);
    setUploadFilesExtras(newFiles);
  };

  const perfilRef = useRef(null);
  const bannerRef = useRef(null);
  return (
    <Grid container style={{ position: "relative" }}>
      <input
        ref={perfilRef}
        id="perfil-upload"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          const files = e.target.files;
          if (files.length > 0) {
            onDrop(files, "Perfil");
          }
        }}
      />

      <input
        ref={bannerRef}
        id="banner-upload"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          const files = e.target.files;
          if (files.length > 0) {
            onDrop(files, "Banner");
          }
        }}
      />
      {localStorage.acessType === "@manager" && stateAlert.open && (
        <AlertDialog
          open={stateAlert.open}
          message={stateAlert.message}
          buttonLabel={stateAlert.buttonLabel}
          targetRoute={stateAlert.targetRoute}
        />
      )}
      <Snackbar
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        message={state.message}
      />
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "start",
          position: "relative",
        }}
      >
        <CardMedia
          component="img"
          alt="Imagem não disponível"
          sx={{
            textAlign: "center",
            borderRadius: "8px",
            height: "auto",
            minHeight: "150px",
            width: "100%",
            objectFit: "cover",
            backgroundColor: "#f9f9f9",
          }}
          image={bannerLoading}
          elevation={0}
        />{" "}
        <div
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          <ClassificacaoIndicativa
            classificacao={eventoToPut.classificacao}
            setClassificacao={(classificacao) =>
              setEventoToPut({
                ...eventoToPut,
                classificacao: classificacao,
              })
            }
          />
        </div>
        <Grid
          container
          style={{ position: "absolute", justifyContent: "end" }}
          spacing={1}
        >
          <Grid item>
            <Button
              size="small"
              disableElevation
              variant="contained"
              color="secondary"
              className="no-round"
              style={{ borderRadius: "0 0 5px 5px" }}
              onClick={() => {
                setEventoToEdit(null);
              }}
            >
              Cancelar edição
            </Button>
          </Grid>

          <Grid item>
            <Button
              size="small"
              disableElevation
              variant="contained"
              className="no-round"
              onClick={() =>
                setEventoToPut({ ...eventoToPut, status: !eventoToPut.status })
              }
              color={eventoToPut.status ? "success" : "secondary"}
              sx={{
                marginTop: "1px",
                borderRadius: "0 0 5px 5px",
              }}
            >
              {eventoToPut.status ? "Ativo" : "Inativo"}
            </Button>
          </Grid>
          <Grid item>
            <Tooltip title="Trocar Banner">
              <IconButton
                onClick={() => bannerRef.current.click()}
                name="banner"
              >
                <AddPhotoAlternateIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            height: "150px",
            width: "150px",
            display: "flex",
            justifyContent: "end",
            alignItems: "start",
            position: "absolute",
            left: "20px",
            bottom: "-20px",
          }}
        >
          <img
            src={perfilLoading}
            elevation={1}
            style={{
              textAlign: "center",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <Tooltip title="Trocar imagem de perfil">
            <IconButton
              style={{ position: "absolute" }}
              onClick={() => perfilRef.current.click()}
              name="perfil"
            >
              <AddPhotoAlternateIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <div
        className="arquives"
        style={{
          display: "flex",
          justifyContent: "end",
          overflow: "hidden",
          width: "100%",
          minHeight: "60px",
        }}
      >
        {arquivos.map((file, index) => (
          <Chip
            style={{
              backgroundColor: "transparent",
              maxWidth: "calc(25% - 5px)",
              margin: "0px 0px 5px 5px",
              borderRadius: " 0 0  10px 10px",
            }}
            key={index}
            label={file.file.name}
            onDelete={() => handleDelete(file)}
          />
        ))}
      </div>

      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Nome do evento"
              variant="standard"
              value={eventoToPut.titulo}
              onChange={(e) =>
                setEventoToPut({ ...eventoToPut, titulo: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Subtitulo do evento"
              variant="standard"
              value={eventoToPut.subTitulo}
              onChange={(e) =>
                setEventoToPut({ ...eventoToPut, subTitulo: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="date"
              label="Inscrições (Início)"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={eventoToPut.data}
              onChange={(e) => {
                const data = new Date(e.target.value);
                const dataFinal = eventoToPut.dataFinal
                  ? new Date(eventoToPut.dataFinal)
                  : null;
                const dataEvento = eventoToPut.dataEvento
                  ? new Date(eventoToPut.dataEvento)
                  : null;

                if (
                  (!dataFinal || data.getTime() < dataFinal.getTime()) &&
                  (!dataEvento || data.getTime() < dataEvento.getTime())
                ) {
                  setEventoToPut({ ...eventoToPut, data: e.target.value });
                } else {
                  alertCustom(
                    "A data de início das inscrições deve ser inferior à data final das inscrições e à data de realização do evento!"
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="date"
              label="Inscrições (Final)"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={eventoToPut.dataFinal}
              onChange={(e) => {
                const dataFinal = new Date(e.target.value);
                const dataInicio = eventoToPut.data
                  ? new Date(eventoToPut.data)
                  : null;
                const dataEvento = eventoToPut.dataEvento
                  ? new Date(eventoToPut.dataEvento)
                  : null;

                if (
                  (!dataInicio || dataFinal.getTime() > dataInicio.getTime()) &&
                  (!dataEvento || dataFinal.getTime() < dataEvento.getTime())
                ) {
                  setEventoToPut({ ...eventoToPut, dataFinal: e.target.value });
                } else {
                  alertCustom(
                    "A data final das inscrições deve ser posterior à data de início e inferior à data de realização do evento!"
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="date"
              label="Data realização evento"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={eventoToPut.dataEvento}
              onChange={(e) => {
                const dataEvento = new Date(e.target.value);
                const dataInicio = eventoToPut.data
                  ? new Date(eventoToPut.data)
                  : null;
                const dataFinal = eventoToPut.dataFinal
                  ? new Date(eventoToPut.dataFinal)
                  : null;

                if (
                  (!dataInicio ||
                    dataEvento.getTime() > dataInicio.getTime()) &&
                  (!dataFinal || dataEvento.getTime() > dataFinal.getTime())
                ) {
                  // Verificação da distância de 7 dias entre a data final e a data do evento
                  if (dataFinal) {
                    const diffTime = dataEvento.getTime() - dataFinal.getTime();
                    const diffDays = diffTime / (1000 * 60 * 60 * 24); // Converter para dias

                    if (diffDays < 7) {
                      alertCustom(
                        "A data do evento deve ser pelo menos 7 dias após a data final das inscrições!"
                      );
                      return;
                    }
                  }

                  setEventoToPut({
                    ...eventoToPut,
                    dataEvento: e.target.value,
                  });
                } else {
                  alertCustom(
                    "A data de realização do evento deve ser superior ao período de inscrições!"
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Largada"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={maskHorario(eventoToPut.horario)}
              onChange={(e) =>
                setEventoToPut({
                  ...eventoToPut,
                  horario: maskHorario(e.target.value),
                })
              }
              type="text"
              inputProps={{
                placeholder: "HH:mm",
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Horário das inscrições"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={maskHorario(eventoToPut.horarioInscricoes)}
              onChange={(e) =>
                setEventoToPut({
                  ...eventoToPut,
                  horarioInscricoes: maskHorario(e.target.value),
                })
              }
              type="text"
              inputProps={{
                placeholder: "HH:mm",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel className="input-type" id="formulario-label">
                Formulário
              </InputLabel>
              <Select
                labelId="formulario-label"
                id="formulario"
                value={selectedFormulario ? selectedFormulario.id : ""}
                label="Formulário"
                onChange={(e) => {
                  const selectedFormulario = formularios.find(
                    (item) => item.id === e.target.value
                  );
                  setSelectedFormulario(selectedFormulario);
                }}
              >
                {formularios.map((item, index) => (
                  <MenuItem key={index} value={item.id} title={item.titulo}>
                    {item.nome}
                  </MenuItem>
                )) || <MenuItem>Não há dados cadastrados</MenuItem>}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel className="input-type" id="organizador-label">
                Organizador
              </InputLabel>
              <Select
                labelId="organizador-label"
                id="organizador"
                value={selectedOrganizador ? selectedOrganizador.id : ""}
                label="Organizador"
                onChange={(e) => {
                  const selectedOrganizador = organizadores.find(
                    (item) => item.id === e.target.value
                  );
                  setSelectedOrganizador(selectedOrganizador);
                }}
              >
                {organizadores.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.nome}
                  </MenuItem>
                )) || <MenuItem>Não há dados cadastrados</MenuItem>}
              </Select>
            </FormControl>
          </Grid>{" "}
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              id="outlined-mult
              outlined-static"
              value={eventoToPut.localizacao}
              label="Localização"
              placeholder="Informe a localização do evento"
              onInput={(e) =>
                setEventoToPut({ ...eventoToPut, localizacao: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              type="number"
              id="outlined-mult
              outlined-static"
              disabled
              value={eventoToPut.numVagas}
              label="Número de vagas"
              placeholder="Informe número de vagas"
              onInput={(e) =>
                setEventoToPut({ ...eventoToPut, numVagas: e.target.value })
              }
            />
          </Grid>{" "}
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel className="input-type" id="type-label">
                Tipo de evento
              </InputLabel>
              <Select
                labelId="type-label"
                id="type"
                value={eventoToPut.tipo}
                label="Tipo de evento"
                onChange={(e) =>
                  setEventoToPut({ ...eventoToPut, tipo: e.target.value })
                }
              >
                <MenuItem key={1} value={"pago"}>
                  Pago
                </MenuItem>
                <MenuItem key={2} value={"gratuito"}>
                  Gratuito
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel className="input-type" id="configurar-valores-label">
                Configurar Kits
              </InputLabel>
              <Select
                labelId="configurar-valores-label"
                id="configurar-valores"
                value={eventoToPut.tabelaPreco}
                label="Configurar valores"
                onChange={(e) =>
                  setEventoToPut({
                    ...eventoToPut,
                    tabelaPreco: e.target.value,
                  })
                }
              >
                {tabelasProdutos.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                )) || <MenuItem>Não há dados cadastrados</MenuItem>}
              </Select>
            </FormControl>
          </Grid>{" "}
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              id="outlined-mult
              outlined-static"
              value={eventoToPut.senha}
              label="Senha do Evento"
              placeholder="Informe uma senha para o evento"
              onInput={(e) =>
                setEventoToPut({ ...eventoToPut, senha: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ModalLote
              idEvento={eventoData.id}
              alertCustom={alertCustom}
              setEventoToPut={setEventoToPut}
              eventoToPut={eventoToPut}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <DialogCupons
              edit={4}
              alertCustom={alertCustom}
              setCuponsPromocionais={setCuponsPromocionais}
              cuponsPromocionais={cuponsPromocionais}
            />
          </Grid>
          {eventoToPut && eventoToPut.tabelaPreco && (
            <Grid item xs={12} md={12}>
              <ProductCards tabela={eventoToPut.tabelaPreco} />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <TextField
              fullWidth
              id="outlined-mult
              outlined-static"
              value={eventoToPut.regulamento}
              label="Regulamento"
              multiline
              rows={12}
              onInput={(e) =>
                setEventoToPut({ ...eventoToPut, regulamento: e.target.value })
              }
            />
          </Grid>{" "}
          <Grid item xs={12} md={12}>
            <Paper elevation={0} variant="outlined" sx={{ padding: 2 }}>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <Typography variant="h6">
                    Upload de arquivos adicionais
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  {uploadFilesExtras && (
                    <SimpleList
                      clickFunction={handleOpenDialog}
                      arquivos={uploadFilesExtras}
                      deleteArquivo={deleteArquivo}
                    />
                  )}
                </Grid>{" "}
                <Grid item xs={12} md={12}>
                  <CardHeader
                    className="show-box"
                    avatar={<ReportProblemIcon fontSize="small" />}
                    title={
                      <Typography color="textSecondary" variant="body2">
                        Certifique-se quanto ao formato dos arquivos, procure
                        usar pdf's para regulamento e png, jpeg, jpg's para
                        imagens de circuito e kits!
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper elevation={0} variant="outlined" sx={{ padding: 2 }}>
              <Grid
                container
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={12} md={9}>
                  <Typography variant="h6">Configurar Categorias</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={groupCategoria ? 3 : 12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    padding: groupCategoria ? 0 : "30px",
                  }}
                >
                  <div style={{ minWidth: "280px" }}>
                    <Autocomplete
                      options={categoriaOptions.map((categoria) => ({
                        title: categoria,
                      }))}
                      setValue={setGroupCategoria}
                      value={groupCategoria}
                      label={"Grupo de categorias"}
                      variant="outlined"
                      placeholder="Pesquise ou digite uma nova"
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid container spacing={1}>
                  {categorias &&
                    categorias.map((item, index) => (
                      <Grid item xs={12} md={3} key={index}>
                        <SimplePaper
                          edit={true}
                          setFunction={handleEditarCategoria}
                          data={item}
                        />
                      </Grid>
                    ))}
                  {groupCategoria && (
                    <Grid item xs={12} md={3}>
                      <SimplePaper
                        edit={true}
                        setFunction={() => {
                          setCategoriaAtual(null);
                          setCategoriaModalOpen(true);
                        }}
                        icon={
                          <Avatar>
                            <AddIcon />
                          </Avatar>
                        }
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={12}>
                    <CardHeader
                      className="show-box"
                      avatar={<ReportProblemIcon fontSize="small" />}
                      title={
                        <Typography color="textSecondary" variant="body2">
                          Você pode selecionar e editar um grupo de categorias
                          existente ou criar um novo grupo informando um novo
                          nome, desde que não esteja previamente cadastrado!
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/*modais*/}
              {categoriaAtual && groupCategoria && groupCategoria.title ? (
                <RenderCategoria
                  nameGroup={groupCategoria.title}
                  alertCustom={alertCustom}
                  open={categoriaModalOpen}
                  onClose={() => setCategoriaModalOpen(false)}
                  categoriaEditar={categoriaAtual}
                  onSave={handleSalvarCategoria}
                  onDelete={handleDeleteCategoria}
                />
              ) : groupCategoria && groupCategoria.title ? (
                <NewCategoria
                  nameGroup={groupCategoria.title}
                  alertCustom={alertCustom}
                  open={categoriaModalOpen}
                  onClose={() => setCategoriaModalOpen(false)}
                  onSave={handleSalvarCategoria}
                />
              ) : null}
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid
              container
              style={{
                marginBottom: "100px",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={12} md={3}>
                {/* <LoadingButton
                  fullWidth
                  disableElevation
                  type="submit"
                  onClick={handleDeleteEvento}
                  loadingPosition="end"
                  variant="text"
                >
                  Excluir evento
                </LoadingButton> */}
              </Grid>
              <Grid item xs={12} md={3}>
                <LoadingButton
                  disableElevation
                  fullWidth
                  color="primary"
                  onClick={handleSubmit}
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                >
                  Atualizar
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <UploadDialog
        uploadFilesExtras={uploadFilesExtras}
        setUploadFilesExtras={setUploadFilesExtras}
        alertCustom={alertCustom}
        handleCloseDialog={handleCloseDialog}
        open={open}
        setEventoToPut={setEventoToPut}
        eventoData={eventoToPut}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDeleteModal
        open={confirmDeleteOpen}
        title="Tem certeza que deseja excluir este evento?"
        onCancel={() => setConfirmDeleteOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </Grid>
  );
}
