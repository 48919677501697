import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  List,
  Divider,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Grid,
  Paper,
  TextField,
  Avatar,
  Box,
  Typography,
  CardHeader,
  CircularProgress,
  Badge,
} from "@mui/material";
import ListItemWithMenu from "./RenderEventosAnteriores";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DataAttemp from "../../utils/DataAttemp";
import axios from "../../utils/configAxios";
import Navbar from "../../components/NavegationBar/";
import DialogoQRCode from "./dialogQRcode";
import HelperDialog from "./helper";
import EditProfileDialog from "./dialogEditPerfil";
import { LensTwoTone } from "@mui/icons-material";
import Dialog from "./dialog";
import DialogReembolso from "./dialogReembolso";
import MarcaAg from "../../images/marcaAg.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogDetalhesTransacao from "./dialogInscricao";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const ListWithItemsAndMenu = ({ alertCustom }) => {
  const params = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const dataManager = DataAttemp();
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [historico, setHistorico] = useState([]);
  const [filteredHistorico, setFilteredHistorico] = useState([]);
  const [conteudo, setConteudo] = useState(null);
  const [transacaoReembolso, setTransacaoReembolso] = useState(null);
  const [validQRcode, setValidQRcode] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogReembolso, setOpenDialogReembolso] = useState(false);
  const [openDialogQRcode, setOpenDialogQRcode] = useState(false);
  const [showMore, setShowMore] = useState(5); // Define o número inicial de registros a serem exibidos
  const listRef = useRef(null); // Referência para a lista
  const [loading, setLoading] = useState(false);
  const [editingProfile, setEditingProfile] = useState(false);
  const [numeroTransacao, setNumeroTransacao] = useState("");
  const [openConfirmacao, setOpenConfirmacao] = useState(false);

  useEffect(() => {
    if (params.acessType === "complete") {
      handleEditProfile("/perfil/complete");
    }
    if (!dataManager.userData) {
      navigate("/login");
    }
  }, []);

  const gerarQRcode = (dados) => {
    setOpenDialogQRcode(true);
    setValidQRcode(
      JSON.stringify({
        transacao: dados.codTransacao,
        eventoId: dados.eventoId,
      })
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenDialog = (respostas) => {
    setConteudo(respostas);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const solicitarReembolso = (item) => {
    setTransacaoReembolso(item);
    setOpenDialogReembolso(true);
  };

  const retornarFlags = (itemHistorico) => {
    const { dataExpiracao, formaPagamento, content, status } = itemHistorico;
    const currentDate = new Date();
    const expirationDate = new Date(dataExpiracao);
    if (status === "cancelled") {
      return [
        {
          value: "cancelled",
        },
      ];
    } else if (status === "confirmed") {
      return [
        {
          value: "confirmed",
        },
      ];
    } else if (status === "gratuito") {
      return [
        {
          value: "Gerar QR Code",
          function: () => gerarQRcode(itemHistorico),
        },
        {
          value: "Imprimir comprovante",
          function: () => {
            setNumeroTransacao(itemHistorico.codTransacao);
            setOpenConfirmacao(!openConfirmacao);
          },
        },
      ];
    } else if (status === "approved") {
      return [
        {
          value: "Gerar QR Code",
          function: () => gerarQRcode(itemHistorico),
        },
        {
          value: "Imprimir comprovante",
          function: () => {
            setNumeroTransacao(itemHistorico.codTransacao);
            setOpenConfirmacao(!openConfirmacao);
          },
        },
      ];
    } else if (content === "Não disponível") {
      return [
        {
          value: "QR Code ou segunda via indisponível",
          function: () => {},
        },
      ];
    }

    switch (formaPagamento) {
      case "pix":
        return [
          {
            value: "Exibir código pix",
            function: () => {
              if (expirationDate > currentDate) {
                handleOpenDialog(content);
              } else {
                alertCustom("Pagamento expirado!");
              }
            },
          },
        ];
      case "bolbradesco":
      case "pec":
        return [
          {
            value: "Emitir segunda via de boleto",
            function: () => {
              if (expirationDate > currentDate) {
                window.open(content, "_blank");
              } else {
                alertCustom("Pagamento expirado!");
              }
            },
          },
        ];
      case "master":
        return [
          {
            value: "Cancelar reserva",
            function: () => {
              if (expirationDate > currentDate) {
                window.open(content, "_blank");
              } else {
                alertCustom("Pagamento expirado!");
              }
            },
          },
        ];
      default:
        return [];
    }
  };
  const fetchEventos = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/pagamentos/buscar-historico-pagamentos?userId=${dataManager.userData.id}&token=${dataManager.userData.auth.token}`
      );
      setHistorico(response.data);
    } catch (error) {
      console.log(error);
      alertCustom(
        "Ocorreu um erro ao buscar seus dados, favor, volte novamente mais tarde!"
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchEventos();
  }, [openDialog, conteudo]);

  useEffect(() => {
    if (historico && historico.pagamentosEncontrados) {
      const filteredData = historico.pagamentosEncontrados.filter((item) =>
        `${item.nomeEvento} ${item.subtitleEvento}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredHistorico(filteredData);
    }
  }, [searchTerm, historico, validQRcode, transacaoReembolso]);

  const handleShowMore = () => {
    setShowMore((prev) => prev + 5);
    setTimeout(() => {
      listRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleShowLess = () => {
    setShowMore(5);
  };

  useEffect(() => {
    if (!params.acessType) {
      setEditingProfile(false);
    }
  }, [window.location.href]);

  const handleEditProfile = (local) => {
    navigate(local);
    setEditingProfile(true);
  };

  return (
    <Grid
      sx={{
        background: `url(${MarcaAg})`,
        paddingTop: { md: "70px", xs: "50px" },
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        height: "calc(100vh - 70px)",
      }}
    >
      <Navbar />
      {editingProfile && (
        <EditProfileDialog
          acessType={params.acessType}
          open={editingProfile}
          onClose={() => setEditingProfile(false)}
          alertCustom={alertCustom}
        />
      )}
      {numeroTransacao > 0 && filteredHistorico.length > 0 && (
        <DialogDetalhesTransacao
          historico={filteredHistorico}
          numeroTransacao={numeroTransacao}
          open={openConfirmacao}
          handleClose={() => setOpenConfirmacao(!openConfirmacao)}
        />
      )}

      {conteudo ? (
        <Dialog
          openDialog={openDialog}
          setConteudo={setConteudo}
          setOpenDialog={setOpenDialog}
          conteudo={conteudo}
          alertCustom={alertCustom}
        />
      ) : transacaoReembolso ? (
        <DialogReembolso
          openDialog={openDialogReembolso}
          setTransacaoReembolso={setTransacaoReembolso}
          setOpenDialog={setOpenDialogReembolso}
          transacaoReembolso={transacaoReembolso}
          alertCustom={alertCustom}
        />
      ) : (
        <DialogoQRCode
          openDialog={openDialogQRcode}
          setValidQRcode={setValidQRcode}
          setOpenDialog={setOpenDialogQRcode}
          validQRcode={validQRcode}
          alertCustom={alertCustom}
          fetchEventos={fetchEventos}
        />
      )}
      <Grid
        container
        spacing={2}
        sx={{ maxWidth: { md: "950px", xs: "100vh" } }}
      >
        <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
          <Paper
            elevation={0}
            sx={{ background: "rgba(0,0,0,0)", margin: "0 10px" }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography
                  variant="h6"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  Histórico de Eventos
                  <Typography variant="body2" color="textSecondary">
                    Acompanhe status de pagamento, emissão de segunda via de
                    boleto e mais
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  placeholder="Pesquisar no histórico"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    type: "search",
                    endAdornment: <SearchIcon color="secondary" />,
                    sx: {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },

                      background: "rgba(0,0,0,0.05)",
                      borderRadius: "50px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  sx={{
                    display: { xs: "block", md: "none" },
                    textAlign: "center",
                  }}
                  variant="body2"
                  color="textSecondary"
                  className="show-box"
                >
                  Acompanhe status de pagamento, emissão de segunda via de
                  boleto e mais
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <List
                  sx={{
                    maxHeight: "55vh",
                    overflowY: "scroll",
                    background: "rgba(0,0,0,0)",
                  }}
                >
                  {filteredHistorico && filteredHistorico.length > 0 ? (
                    filteredHistorico.slice(0, showMore).map((item, index) => (
                      <React.Fragment key={index}>
                        <ListItemWithMenu
                          title={item.nomeEvento}
                          subtitle={`R$ ${item.valorTotal} (${item.formaPagamento})`}
                          content={[
                            {
                              status: item.status,
                              dataExp: item.dataExpiracao,
                            },
                          ]}
                          flags={retornarFlags(item)}
                          alertCustom={alertCustom}
                        />
                      </React.Fragment>
                    ))
                  ) : loading ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <Typography
                      color="textSecondary"
                      className="show-box"
                      variant="body2"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Nenhum resultado encontrado
                    </Typography>
                  )}
                  <div ref={listRef}></div>{" "}
                  {/* Referência para o final da lista */}
                </List>
              </Grid>
              <Grid item xs={12} md={12}>
                {filteredHistorico.length > showMore ? (
                  <Button fullWidth onClick={handleShowMore}>
                    Ver Mais
                  </Button>
                ) : (
                  filteredHistorico.length > 5 && (
                    <Button fullWidth onClick={handleShowLess}>
                      Ver Menos
                    </Button>
                  )
                )}
              </Grid>{" "}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
          <Paper variant={!isMobile && "outlined"} elevation={isMobile && 0}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: "#0c8ce9" }} aria-label="recipe">
                  {dataManager.userData &&
                    dataManager.userData.nome[0].toUpperCase()}
                </Avatar>
              }
              action={
                <>
                  <IconButton
                    aria-label="settings"
                    onClick={() => navigate("/home")}
                  >
                    <HomeRoundedIcon />
                  </IconButton>
                  <IconButton aria-label="settings" onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                </>
              }
              title={dataManager.userData && dataManager.userData.nome}
              subheader={
                dataManager.userData && dataManager.userData.auth.email
              }
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleEditProfile("/perfil/info")}>
                Editar Perfil
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  localStorage.clear();
                  navigate("/login");
                  window.location.reload();
                }}
              >
                Trocar de conta
              </MenuItem>
            </Menu>
          </Paper>
          <HelperDialog />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListWithItemsAndMenu;
