import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  CardHeader,
  Avatar,
  Grid,
  CardMedia,
  IconButton,
  Button,
  Link,
  Checkbox,
  FormControlLabel,
  Paper,
  Tooltip,
  ButtonBase,
  Box,
  CircularProgress,
} from "@mui/material";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import XIcon from "@mui/icons-material/X";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { formatarLocalizacao } from "../../utils/localFormater";
import CardImage from "../../components/Cards/CardImage";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CardView from "../../components/Cards/CardView";
import ClassificacaoIndicativa from "../Eventos/Cadastrar/ClassificacaoIndicativa";
const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

function abrirMapa(localizacao) {
  const localizacaoFormatada = localizacao.replace(/\s/g, "+");
  const url = `https://www.google.com/maps/search/?api=1&query=${localizacaoFormatada}`;
  window.open(url, "_blank");
}

const RenderHTML = ({ text }) => {
  // Função para processar o texto
  const isMobile = window.innerWidth <= 600;
  const processText = (text) => {
    // Primeiro passo: processar frases entre ###
    const divRegex = /###(.*?)###/g;
    const divParts = text.split(divRegex);

    // Função auxiliar para processar negrito
    const processBoldText = (part) => {
      const boldRegex = /\*(.*?)\*/g;
      const boldParts = part.split(boldRegex);

      return boldParts.map((boldPart, index) =>
        index % 2 === 1 ? (
          <strong key={index}>{boldPart}</strong>
        ) : (
          <span key={index}>{boldPart}</span>
        )
      );
    };

    // Mapeia as partes do texto, transformando aquelas que correspondem à regex em divs
    return divParts.map((divPart, index) =>
      index % 2 === 1 ? (
        <div
          key={index}
          style={
            isMobile
              ? {
                  background: "#0C8CE9",
                  borderRadius: "8px 8px 0 0 ",
                  padding: "5px 10px",
                  color: "#fff",
                  width: "100%",
                  fontSize: "17px",
                  fontWeight: "600",
                }
              : {
                  textAlign: "center",
                  width: "auto",
                  borderBottom: "2px solid rgba(0,0,0,0.3)",
                  fontSize: isMobile ? "17px" : "15px",
                  fontWeight: isMobile ? "600" : "500",
                }
          }
        >
          {processBoldText(divPart)}
        </div>
      ) : (
        processBoldText(divPart)
      )
    );
  };

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      className={isMobile ? "font-size-16" : ""}
      sx={{
        textAlign: "justify",
        whiteSpace: "pre-wrap",
      }}
    >
      {" "}
      {processText(text)}
    </Typography>
  );
};
const SimpleComponent = ({ alertCustom, EventoData, handleClose, loading }) => {
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const isMobile = window.innerWidth <= 600;
  const [isChecked, setIsChecked] = useState(false);
  const [imagemErroPerfil, setImagemErroPerfil] = useState(false);
  const [imagemErroBanner, setImagemErroBanner] = useState(false);
  const [regulamentos, setRegulamentos] = useState(
    EventoData.images
      ? EventoData.images.filter((item) => item.campo == "Regulamento")
      : null
  );
  const dataEvento = new Date(EventoData.dataEvento);
  dataEvento.setHours(
    parseInt(EventoData.horario.split(":")[0]) + 24,
    parseInt(EventoData.horario.split(":")[1]),
    0,
    0
  );

  const dataInicial = new Date(EventoData.data);
  dataInicial.setHours(
    parseInt(EventoData.horarioInscricoes.split(":")[0]) + 24,
    parseInt(EventoData.horarioInscricoes.split(":")[1]),
    0,
    0
  );

  const dataFinal = new Date(EventoData.dataFinal);
  dataFinal.setHours(47, 59, 0, 0);
  let text = "";

  if (
    new Date().getTime() < dataFinal.getTime() &&
    new Date().getTime() > dataInicial.getTime()
  ) {
    if (EventoData.numVagas > 0) {
      text = "Inscreva-se";
    } else {
      text = "Vagas esgotadas";
    }
  } else if (new Date().getTime() > dataFinal.getTime()) {
    text = "Inscrições encerradas";
  } else {
    text = "Não iniciado";
  }

  const [eventoFormated, setEventoFormated] = useState({
    data: dataEvento,
    dataInscricaoInicial: dataInicial,
    dataInscricaoFinal: dataFinal,
    horario: EventoData.horario,
    buttonText: text,
  });
  const [textReg, setTextReg] = useState(EventoData.regulamento);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const closeModal = () => {
    handleClose();
  };

  const shareOnWhatsApp = () => {
    const url = encodeURIComponent(
      `${window.location.href}-${EventoData.titulo
        .replace(/[^a-zA-Z\u00C0-\u017F\s]/g, "")
        .toLowerCase()
        .split(" ")
        .filter((item) => !!item)
        .join("-")}`
    );

    const text = encodeURIComponent(
      `*GR - Eventos e Esportes*\n\n` +
        `Participe já do(a) *${EventoData.titulo}*!\n\n` +
        `Garanta seu ingresso diretamente pela plataforma usando o link abaixo:\n`
    );
    window.open(`https://wa.me/?text=${text}%20${url}`);
  };

  const shareOnFacebook = () => {
    const url = encodeURIComponent(
      `${window.location.href}-${EventoData.titulo
        .replace(/[^a-zA-Z\u00C0-\u017F\s]/g, "")
        .toLowerCase()
        .split(" ")
        .join("-")}`
    );
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      "_blank"
    );
  };

  const shareOnLinkedIn = () => {
    const url = encodeURIComponent(
      `${window.location.href}-${EventoData.titulo
        .replace(/[^a-zA-Z\u00C0-\u017F\s]/g, "")
        .toLowerCase()
        .split(" ")
        .join("-")}`
    );
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
      "_blank"
    );
  };

  const shareOnTwitter = () => {
    const text = encodeURIComponent(
      `Olá atleta! Temos um evento perfeito para você: Participe agora do evento ${EventoData.titulo} - ${EventoData.subTitulo} garantindo seu ingresso diretamente da nossa plataforma Goiânia Runners Tickets!`
    );
    window.open(`https://twitter.com/intent/tweet?text=${text}`, "_blank");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape" || event.key === "Backspace") {
      closeModal();
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        onClose={closeModal}
        fullScreen={window.innerWidth < 600}
        PaperProps={{
          sx: {
            maxWidth: "100vw",
            overflowY: "scroll",
            maxHeight: { md: "90vh", xs: "100vh" },
            width: { md: "1200px", xs: "99vh" },
            position: "relative",
          },
        }}
      >
        {loading ? (
          <Grid
            sx={{
              width: "100%",
              height: { md: "90vh", xs: "100vh" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <DialogContent
            sx={{
              padding: 0,
            }}
            className={isMobile ? "" : "allow-scroll"}
          >
            {isMobile && (
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  display: "flex",
                  zIndex: 100,
                  justifyContent: "center",
                }}
              >
                <IconButton
                  fullWidth
                  disableElevation
                  sx={{
                    margin: "20px",
                    background: "#0C8CE9",
                    color: "#fff",
                  }}
                  onClick={() => {
                    targetRef.current.scrollIntoView({ behavior: "smooth" });
                  }}
                  variant="contained"
                >
                  <ArrowDownwardIcon fontSize="large" />
                </IconButton>
              </div>
            )}
            <IconButton
              onClick={closeModal}
              className="button-contrast"
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
                zIndex: 20,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Grid container>
              <Grid item xs={12} md={12} sx={{ position: "relative" }}>
                {imagemErroBanner ? ( // Verifica se ocorreu um erro ao carregar a imagem
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                    bgcolor="#f0f0f0"
                    sx={{ minHeight: "300px" }}
                    color="text.secondary"
                    fontSize={14}
                  >
                    Imagem não disponível
                  </Box>
                ) : (
                  <CardMedia
                    component="img"
                    sx={{
                      height: "auto",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    image={`${REACT_APP_URL_BIBLIOTECA_RUNNERS}/images?file=Eventos/${
                      isMobile ? EventoData.perfilImage : EventoData.bannerImage
                    }`}
                    elevation={0}
                    onError={() => setImagemErroBanner(true)} // Adiciona o evento onError para lidar com o erro
                  />
                )}
                <div style={{ position: "absolute", right: 10, bottom: 10 }}>
                  <ClassificacaoIndicativa
                    classificacao={EventoData.classificacao}
                    setClassificacao={() => {}}
                    readOnly
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12} sx={{ position: "relative" }}>
                <Grid container padding={2} spacing={3}>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Paper
                          elevation={0}
                          sx={{
                            padding: 1,
                            borderRadius: "10px",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                                alignItems: "start",
                                textAlign: "center",
                              }}
                            >
                              <Grid container spacing={1}>
                                {/*largada*/}
                                <Grid item xs={12} md={12}>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    className="font-size-16"
                                    sx={{
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {format(
                                      new Date(eventoFormated.data),
                                      "'Largada no dia 'dd/MM/yyyy' às 'HH:mm' horas"
                                    )}
                                  </Typography>
                                </Grid>
                                {/*actions*/}
                                <Grid item xs={12} md={6}>
                                  <CardHeader
                                    sx={{ height: "100%" }}
                                    className="show-box-select"
                                    onClick={() =>
                                      abrirMapa(EventoData.localizacao)
                                    }
                                    avatar={<OpenInNewIcon />}
                                    title={
                                      <b
                                        className={
                                          isMobile ? "font-size-16 " : ""
                                        }
                                      >
                                        {formatarLocalizacao(
                                          EventoData.localizacao
                                        )}
                                      </b>
                                    }
                                  />
                                </Grid>
                                {regulamentos.map((regulamento, index) => (
                                  <Grid item xs={12} md={6}>
                                    <CardHeader
                                      sx={{ height: "100%" }}
                                      className="show-box-select"
                                      onClick={() =>
                                        window.open(
                                          `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/images?file=Regulamento/${regulamento.src}`
                                        )
                                      }
                                      avatar={<OpenInNewIcon />}
                                      title={
                                        <b
                                          className={
                                            isMobile ? "font-size-16 " : ""
                                          }
                                        >
                                          {`Leia o regulamento completo - Edital ${
                                            index + 1
                                          }`}
                                        </b>
                                      }
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <RenderHTML
                                setTextReg={setTextReg}
                                text={textReg}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4} ref={targetRef}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Paper
                          variant="outlined"
                          sx={{
                            borderRadius: "8px",
                            padding: 1,
                            minHeight: "500px",
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                          }}
                        >
                          <CardHeader
                            sx={{
                              height: 70,
                            }}
                            avatar={
                              <Avatar
                                sx={{ bgcolor: "#0c8ce9", color: "#fff" }}
                                aria-label="recipe"
                              >
                                {EventoData.organizador.nome[0]}
                              </Avatar>
                            }
                            title={EventoData.organizador.nome}
                            subheader={
                              <Link
                                className="simple-link"
                                href={
                                  EventoData.organizador.site.includes("http")
                                    ? EventoData.organizador.site
                                    : `https://${EventoData.organizador.site}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {`${EventoData.organizador.site
                                  .replace(/^https?:\/\//, "")
                                  .substring(0, 35)}${
                                  EventoData.organizador.site.length > 35
                                    ? "..."
                                    : ""
                                }`}
                              </Link>
                            }
                          />

                          <Grid
                            container
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <FormControlLabel
                              color="secondaryText"
                              control={
                                <Checkbox
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                />
                              }
                              label={
                                <Typography
                                  variant="body2"
                                  color="secondaryText"
                                >
                                  Li e concordo com o regulamento
                                </Typography>
                              }
                            />
                            <Button
                              fullWidth
                              sx={{ margin: "5px 10px" }}
                              disableElevation
                              disabled={
                                !(
                                  isChecked &&
                                  new Date().getTime() <
                                    eventoFormated.dataInscricaoFinal.getTime() &&
                                  new Date().getTime() >
                                    eventoFormated.dataInscricaoInicial.getTime()
                                ) || !EventoData.numVagas > 0
                              }
                              variant="contained"
                              onClick={() => {
                                navigate(`/cadastro/${EventoData.id}`);
                              }}
                            >
                              {eventoFormated.buttonText}
                            </Button>

                            <Typography
                              variant="body2"
                              color="textSecondary"
                              className="show-box"
                              sx={{
                                margin: "10px 0",
                                textAlign: "center",
                              }}
                            >
                              {format(
                                eventoFormated.dataInscricaoInicial,
                                "'Inscrições de 'dd/MM/yyyy' às 'HH:mm' a '"
                              )}
                              {format(
                                eventoFormated.dataInscricaoFinal,
                                "dd/MM/yyyy' até às 'HH:mm' ou até o limite de vagas'"
                              )}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                width: "100%",
                                marginTop: "20px",
                                textAlign: "center",
                              }}
                            >
                              Compartilhe esse evento
                            </Typography>
                            <IconButton onClick={shareOnLinkedIn}>
                              <LinkedInIcon className="blue-hover" />
                            </IconButton>
                            <IconButton onClick={shareOnTwitter}>
                              <XIcon className="blue-hover" />
                            </IconButton>
                            <IconButton onClick={shareOnWhatsApp}>
                              <WhatsAppIcon className="green-hover" />
                            </IconButton>
                            <IconButton onClick={shareOnFacebook}>
                              <FacebookOutlinedIcon className="blue-hover" />
                            </IconButton>
                          </Grid>
                        </Paper>
                      </Grid>
                      {EventoData.images &&
                        EventoData.images.map((image, index) => {
                          if (image.campo != "Regulamento") {
                            return (
                              <Grid item xs={12} md={12}>
                                <CardView
                                  image={`${REACT_APP_URL_BIBLIOTECA_RUNNERS}/images?file=${image.campo}/${image.src}`}
                                  alt={image.campo}
                                  variant="outlined"
                                  title={image.titulo}
                                  subtitle={image.descricao}
                                  handleSelectItem={() =>
                                    image.campo === "Circuito" &&
                                    window.open(image.link, "_blank")
                                  }
                                  disabled={image.campo != "Circuito"}
                                  maxWidth={"100%"}
                                  height={"auto"}
                                />
                              </Grid>
                            );
                          }
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default SimpleComponent;
