export const tourSteps = [
  {
    position: "top",
    targetClass: ".tour_1",
    description:
      "Aqui você poderá visualizar, editar e obter um parecer geral das finanças de todos os eventos cadastrados.",
  },
  {
    position: "bottom",
    targetClass: ".tour_2",
    event: "click",
    description:
      "No canto superior direito, você pode acessar algumas opções, incluindo um guia para a página inicial dos eventos, página de preços e taxas, além de poder sair da aplicação.",
  },
  {
    position: "bottom",
    targetClass: ".tour_3",
    description:
      "No canto superior esquerdo, você pode acessar suas ferramentas.",
    mobileEvent: "click",
  },
  // formulários
  {
    position: "bottom",
    targetClass: ".tour_null",
    description:
      "Bem-vindo ao cadastro de Formulários. Aqui você pode cadastrar perguntas para os seus usuários no ato da inscrição. Você precisa cadastrar um formulário para criar um evento.",
    navigateTo: "/dash/formularios",
    event: "click",
  },
  {
    position: "top",
    targetClass: ".tour_4",
    event: "click",
    description:
      "Informando um nome e um subtítulo, você pode cadastrar campos clicando em 'Novo Campo'.",
  },
  {
    position: "bottom",
    targetClass: ".tour_5",
    event: "click",
    description:
      "'Label' é a pergunta, e 'Placeholder' é uma dica para a resposta.",
  },
  {
    position: "top",
    targetClass: ".tour_6",
    description:
      "Se você optar por uma seleção, pode digitar as opções e salvá-las teclando 'Enter'.",
  },
  // Produtos
  {
    position: "bottom",
    targetClass: ".tour_7",
    description: "Bem-vindo ao cadastro de produtos, ou como preferir: kits.",
    event: "click",
    navigateTo: "/dash/produtos",
  },
  {
    position: "bottom",
    targetClass: ".tour_7",
    event: "click",
    description:
      "O preço será adicionado ao valor final pago pelo participante.",
  },
  {
    position: "bottom",
    targetClass: ".tour_9",
    description:
      "'Tabela de preço' é uma ferramenta para controlar preços de forma mais fácil. Você pode agrupar produtos e dar um nome a esse grupo. Assim, toda vez que criar um novo evento, poderá reutilizar os mesmos produtos do evento anterior.",
  },
  {
    position: "bottom",
    targetClass: ".tour_10",
    description:
      "As variações são variantes de um mesmo produto. Para um kit, por exemplo, podem ser 'completo' ou 'básico'. Basta digitar e teclar 'Enter' para confirmar.",
  },
  {
    position: "top",
    targetClass: ".tour_11",
    description:
      "Você pode controlar a visibilidade deste produto e de suas variantes quando quiser.",
  },
  {
    position: "bottom",
    targetClass: ".tour_12",
    event: "click",
    description:
      "Você pode visualizar o produto conforme a tabela de preço, ou seja, de acordo com o nome do grupo ao qual ele pertence.",
    navigateTo: "/dash/produtos",
  },
  {
    position: "bottom",
    targetClass: ".tour_13",
    description:
      "Para editar um produto, selecione-o na tabela e clique em 'Editar'.",
  },
  // cadastro de eventos
  {
    position: "bottom",
    targetClass: ".tour_14",
    description:
      "Bem-vindo ao cadastro de eventos. Adicionando banners e escolhendo a classificação indicativa, você pode determinar, por meio de intervalos de datas, o período de inscrições.",
    navigateTo: "/dash/novoEvento",
  },
  {
    position: "bottom",
    targetClass: ".tour_15",
    description:
      "O botão 'Ativo' e 'Inativo' controla a visibilidade do evento. Estando inativo, o evento pode ser acessado apenas via link; caso contrário, ele é exibido no feed geral.",
  },
  {
    position: "bottom",
    targetClass: ".tour_16",
    event: "focus",
    description:
      "Como informado nos passos anteriores, aqui você pode selecionar formulários e produtos pré-cadastrados.",
  },
  {
    position: "bottom",
    targetClass: ".tour_17",
    event: "focus",
    description:
      "O regulamento é o trecho de texto visível aos usuários. Você pode digitá-lo como desejar, envolvendo trechos do texto com '***' ou '###' para destacá-los.",
  },
  {
    position: "top",
    targetClass: ".tour_18",
    event: "focus",
    description:
      "Para evitar ter que informar todas as categorias sempre que cadastrar um evento, você pode configurar um grupo de categorias e salvá-las usando um nome, reutilizando-o sempre que quiser em outros eventos futuros. Caso queira formar um novo grupo, basta digitar um nome ainda não cadastrado.",
  },
  {
    position: "bottom",
    event: "focus",
    targetClass: ".tour_19",
    description:
      "Não esqueça de editar o preço das categorias para os que desejar cobrar.",
  },
  // inscrições
  {
    position: "bottom",
    targetClass: ".tour_19",
    event: "focus",
    description:
      "Aqui você pode ter uma visão geral das inscrições, extrair relatórios e obter o link para a divulgação das mesmas.",
    navigateTo: "/dash/gerenciarInscricoes",
  },
  {
    position: "bottom",
    targetClass: ".tour_19",
    description:
      "Ao clicar em uma inscrição, você pode mudar o status dela e o sistema cuidará do restante.",
  },
  // ferramentas gerais
  {
    position: "bottom",
    targetClass: ".tour_20",
    description:
      "O scanner pode ser usado para verificar a inscrição via QR Code, com todos os dados na palma da sua mão.",
    navigateTo: "/jobs/scan",
  },
  {
    position: "bottom",
    targetClass: ".tour_21",
    description:
      "Você concluiu o tour! Consulte as instruções sempre que necessário em 'Configurações'.",
    navigateTo: "/dash/dashboard",
  },
];
