import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import DataAttemp from "../../../utils/DataAttemp";
import { useNavigate } from "react-router-dom";
import axios from "../../../utils/configAxios";
import LoadingButton from "@mui/lab/LoadingButton"; // Importe o LoadingButton do Material-UI
import { format } from "date-fns";

const REACT_APP_URL_BIBLIOTECA_RUNNERS_HTTPS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS_HTTPS;

const FreeEventComponent = ({ dadosCadastro, eventoData, alertCustom }) => {
  const [loading, setLoading] = useState(false);
  const dataMaganer = DataAttemp();
  const navigate = useNavigate();
  const handleClick = () => {
    const dataEvento = new Date(eventoData.dataEvento);
    dataEvento.setHours(
      parseInt(eventoData.horario.split(":")[0]) + 24,
      parseInt(eventoData.horario.split(":")[1]),
      0,
      0
    );

    setLoading(true);
    axios
      .post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_HTTPS}/v1/pagamentos/cadastrar-gratuito`,
        {
          transacao: dadosCadastro.transacao,
          user: {
            id: dataMaganer.userData.id,
            nome: dataMaganer.userData.nome,
            email: dataMaganer.userData.auth.email,
          },
          docs: dadosCadastro.dependentes.map(({ doc }) => doc),
          evento: {
            id: eventoData.id,
            nome: eventoData.titulo,
            subtitle: eventoData.subTitulo,
            dataFinal: eventoData.dataFinal,
            dataEvento: dataEvento,
            horario: eventoData.horario,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        alertCustom(response.data.message || "Sucesso!");
        navigate("/perfil");
      })
      .catch((error) => {
        alertCustom(
          "Ocorreu um erro ao realizar inscrição, favor, tente novamente mais tarde!"
        );
        console.error("Erro ao enviar requisição POST:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: { background: "transparent", xs: "52vh", md: "60vh" },
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Este evento é gratuito!
        <Typography variant="body1" gutterBottom>
          Podemos contar com a sua participação?
        </Typography>
      </Typography>
      <LoadingButton
        disableElevation
        margin={1}
        variant="contained"
        color="primary"
        onClick={handleClick}
        loading={loading} // Define o estado de carregamento diretamente no LoadingButton
      >
        Confirmar participação
      </LoadingButton>
    </Box>
  );
};

export default FreeEventComponent;
