import React, { useState } from "react";
import {
  Paper,
  Typography,
  CardHeader,
  Avatar,
  CardMedia,
  Grid,
  IconButton,
  Button,
  Link,
  Box,
  CircularProgress,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../utils/configAxios";
import ConfirmDeleteModal from "../../../components/Modais/confirmModal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CardImage from "../../../components/Cards/CardImage";
import ShareIcon from "@mui/icons-material/Share";
import CardView from "../../../components/Cards/CardView";
import ClassificacaoIndicativa from "../Cadastrar/ClassificacaoIndicativa";
import { formatarLocalizacao } from "../../../utils/localFormater";
const {
  REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM,
  REACT_APP_URL_BIBLIOTECA_RUNNERS,
} = process.env;
const RenderHTML = ({ text }) => {
  // Função para processar o texto
  const processText = (text) => {
    const isMobile = window.innerWidth <= 600;
    // Primeiro passo: processar frases entre ###
    const divRegex = /###(.*?)###/g;
    const divParts = text.split(divRegex);

    // Função auxiliar para processar negrito
    const processBoldText = (part) => {
      const boldRegex = /\*(.*?)\*/g;
      const boldParts = part.split(boldRegex);

      return boldParts.map((boldPart, index) =>
        index % 2 === 1 ? (
          <strong key={index}>{boldPart}</strong>
        ) : (
          <span key={index}>{boldPart}</span>
        )
      );
    };

    // Mapeia as partes do texto, transformando aquelas que correspondem à regex em divs
    return divParts.map((divPart, index) =>
      index % 2 === 1 ? (
        <div
          key={index}
          style={
            isMobile
              ? {
                  background: "#0C8CE9",
                  borderRadius: "8px 8px 0 0 ",
                  padding: "5px 10px",
                  color: "#fff",
                  width: "100%",
                  fontSize: "17px",
                  fontWeight: "600",
                }
              : {
                  textAlign: "center",
                  width: "auto",
                  borderBottom: "2px solid rgba(0,0,0,0.3)",
                  fontSize: isMobile ? "17px" : "15px",
                  fontWeight: isMobile ? "600" : "500",
                }
          }
        >
          {processBoldText(divPart)}
        </div>
      ) : (
        processBoldText(divPart)
      )
    );
  };

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      className="font-size-16"
      sx={{
        textAlign: "justify",
        whiteSpace: "pre-wrap",
      }}
    >
      {processText(text)}
    </Typography>
  );
};

function abrirMapa(localizacao) {
  const localizacaoFormatada = localizacao.replace(/\s/g, "+");
  const url = `https://www.google.com/maps/search/?api=1&query=${localizacaoFormatada}`;
  window.open(url, "_blank");
}

const SimpleComponent = ({
  alertCustom,
  EventoData,
  handleClose,
  setEventoToEdit,
  loading,
  fetchData,
}) => {
  const isMobile = window.innerWidth <= 600;
  const [isChecked, setIsChecked] = useState(false);
  const [textReg, setTextReg] = useState(EventoData.regulamento);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [errorBanner, setErrorBanner] = useState(false);
  const [regulamentos, setRegulamentos] = useState(
    EventoData.images
      ? EventoData.images.filter((item) => item.campo == "Regulamento")
      : null
  );
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const closeModal = () => {
    handleClose();
  };

  const handleDeleteEvento = () => {
    setConfirmDeleteOpen(true);
  };

  const handleShareEvento = () => {
    const eventoString = `${EventoData.titulo
      .replace(/[^a-zA-Z\u00C0-\u017F\s]/g, "")
      .toLowerCase()
      .split(" ")
      .join("-")}`;

    const link = `https://goianiarunners.com/home/${eventoString}${
      EventoData.status == false ? "?password=" + EventoData.senha : ""
    }`;

    if (!navigator.clipboard) {
      console.error("O navegador não suporta a API de Clipboard.");
      return;
    }
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alertCustom("Link do evento copiado para a área de transferência");
      })
      .catch((err) => {
        console.log(err);
        alertCustom("Erro ao copiar o link");
      });
  };

  const handleCancelDelete = () => {
    setConfirmDeleteOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/deletar-evento/${EventoData.id}`,
        {
          categoriaName: EventoData.categoriaName,
        }
      );
      if (EventoData.images && EventoData.images.length > 0) {
        Promise.all(
          EventoData.images.map((fileRemove) => {
            try {
              return axios.post(
                `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/upload/${
                  fileRemove.campo
                }?anteriores=${JSON.stringify(
                  EventoData.images.map((file) => {
                    if (file.campo == fileRemove.campo) {
                      return file.src;
                    }
                  })
                )}`
              );
            } catch (error) {
              console.log(error);
            }
          })
        );
      }
      await fetchData();
      handleClose();
      alertCustom(response.data.message);
    } catch (error) {
      alertCustom(
        "Erro ao deletar evento! Verifique se confirmou todos os inscritos no evento."
      );
      console.error("Erro:", error);
    } finally {
      setConfirmDeleteOpen(false);
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={closeModal}
        fullScreen={window.innerWidth < 600}
        PaperProps={{
          sx: {
            maxWidth: "100vw",
            overflowY: "scroll",
            maxHeight: { md: "90vh", xs: "100vh" },
            width: { md: "1200px", xs: "100vh" },
            position: "relative",
          },
        }}
      >
        {loading ? (
          <Grid
            sx={{
              width: "100%",
              height: { md: "90vh", xs: "100vh" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <DialogContent
            sx={{
              padding: 0,
            }}
            className="allow-scroll"
          >
            <IconButton
              onClick={closeModal}
              className="button-contrast"
              sx={{
                position: "absolute",
                right: 10,
                top: 10,
                zIndex: 20,
              }}
            >
              <CloseIcon />
            </IconButton>
            <Grid container>
              <Grid item xs={12} md={12} sx={{ position: "relative" }}>
                {errorBanner ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="#f0f0f0"
                    sx={{
                      width: "100%",
                      height: "300px",
                      textAlign: "center",
                      marginRight: "10px",
                    }}
                    color="text.secondary"
                    fontSize={14}
                  >
                    Imagem não disponível
                  </Box>
                ) : (
                  <CardMedia
                    component="img"
                    sx={{
                      height: "auto",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    onError={() => setErrorBanner(true)}
                    image={`${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/images?file=Eventos/${EventoData.bannerImage}`}
                    elevation={0}
                  />
                )}
                <div style={{ position: "absolute", right: 10, bottom: 10 }}>
                  <ClassificacaoIndicativa
                    classificacao={EventoData.classificacao}
                    setClassificacao={() => {}}
                    readOnly
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: 1,
                  }}
                >
                  <Grid item xs={12} md={6} order={0}>
                    <CardHeader
                      sx={{
                        height: 70,
                      }}
                      avatar={
                        <Avatar
                          sx={{ bgcolor: "#0c8ce9", color: "#fff" }}
                          aria-label="recipe"
                        >
                          {EventoData.organizador.nome[0]}
                        </Avatar>
                      }
                      title={EventoData.organizador.nome}
                      subheader={
                        <Link
                          className="simple-link"
                          href={EventoData.organizador.site}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {`${EventoData.organizador.site
                            .replace(/^https?:\/\//, "")
                            .substring(0, 35)}${
                            EventoData.organizador.site.length > 35 ? "..." : ""
                          }`}
                        </Link>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={2} sx={{ order: { md: 0, xs: 1 } }}>
                    <Button
                      fullWidth
                      disableElevation
                      variant="contained"
                      color="error"
                      sx={{ borderRadius: "50px" }}
                      onClick={handleDeleteEvento}
                    >
                      Excluir
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        setEventoToEdit(EventoData);
                        closeModal();
                      }}
                      sx={{ order: { md: 1, xs: 0 } }}
                    >
                      Editar
                    </Button>
                  </Grid>{" "}
                  <Grid item xs={12} md={2}>
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={handleShareEvento}
                      endIcon={<ShareIcon />}
                    >
                      Compartilhar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{ padding: 2 }} spacing={2}>
              <Grid item xs={12} md={8}>
                <Paper
                  elevation={0}
                  sx={{
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      {" "}
                      <Typography variant="h6">
                        {EventoData.titulo}
                        <Typography variant="body1" color="textSecondary">
                          {EventoData.subTitulo}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CardHeader
                        sx={{ height: "100%" }}
                        className="show-box-select"
                        onClick={() => abrirMapa(EventoData.localizacao)}
                        avatar={<OpenInNewIcon />}
                        title={
                          <b className={isMobile ? "font-size-16 " : ""}>
                            {formatarLocalizacao(EventoData.localizacao)}
                          </b>
                        }
                      />
                    </Grid>
                    {regulamentos.map((regulamento, index) => (
                      <Grid item xs={12} md={6}>
                        <CardHeader
                          sx={{ height: "100%" }}
                          className="show-box-select"
                          onClick={() =>
                            window.open(
                              `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/images?file=Regulamento/${regulamento.src}`
                            )
                          }
                          avatar={<OpenInNewIcon />}
                          title={
                            <b className={isMobile ? "font-size-16 " : ""}>
                              {`Leia o regulamento completo - Edital ${
                                index + 1
                              }`}
                            </b>
                          }
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12} md={12}>
                      <RenderHTML setTextReg={setTextReg} text={textReg} />
                    </Grid>{" "}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  {EventoData.images &&
                    EventoData.images.length > 0 &&
                    EventoData.images.map((image, index) => {
                      if (image.campo != "Regulamento") {
                        return (
                          <Grid item xs={12} md={12}>
                            <CardView
                              image={`${REACT_APP_URL_BIBLIOTECA_RUNNERS}/images?file=${image.campo}/${image.src}`}
                              alt={image.campo}
                              variant="outlined"
                              title={image.titulo}
                              subtitle={image.descricao}
                              handleSelectItem={() =>
                                image.campo === "Circuito" &&
                                window.open(image.link, "_blank")
                              }
                              disabled={image.campo != "Circuito"}
                              maxWidth={"100%"}
                              height={"auto"}
                            />
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
      <ConfirmDeleteModal
        title={"Tem certeza que deseja excluir este evento?"}
        open={confirmDeleteOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default SimpleComponent;
