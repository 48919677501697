import React, { useState, useEffect, Fragment } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { Grid, Paper, Typography, Chip, CardHeader } from "@mui/material";
import DataAttemp from "../../../utils/DataAttemp";
import { Add } from "@mui/icons-material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import axios from "../../../utils/configAxios";
const { REACT_APP_URL_BIBLIOTECA_RUNNERS } = process.env;
const AddDependentButton = ({
  setActiveStep,
  alertCustom,
  dadosCadastro,
  setDadosCadastro,
  setVisibilityButtonStep,
  setPivo,
  pivo,
  classificacao,
}) => {
  const dataManager = DataAttemp();
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [doc, setDoc] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [sexo, setSexo] = useState("");
  const [email, setEmail] = useState("");

  const maskDoc = (value) => {
    if (!value) return "";
    if (value.length <= 14) {
      value = value.replace(/\D/g, "");
      if (value.length > 7) {
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d)/, "$1.$2");
        value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      }
      return value;
    } else {
      return value.slice(0, 14);
    }
  };

  const handleSetPivo = (newDependent) => {
    if (newDependent) {
      setDadosCadastro((prevState) => ({
        ...prevState,
        dependentes: [...dadosCadastro.dependentes, newDependent],
      }));
      setPivo(dadosCadastro.dependentes.length);
      setActiveStep(0);
    }
  };

  const handleAddDependent = async () => {
    if (doc.replace(/[^\d]/g, "").length < 7) {
      alertCustom("Por favor, insira um documento válido para o dependente.");
      return;
    }
    if (
      dadosCadastro.dependentes.filter(
        (participante) => participante.doc == doc.replace(/[^\d]/g, "")
      ).length > 0
    ) {
      alertCustom("O número do documento já foi cadastrado, verificar!");
      return;
    }
    if (!name.trim()) {
      alertCustom("Por favor, insira um nome para o dependente.");
      return;
    }
    if (!dataNascimento) {
      alertCustom("Informe uma data de nascimento");
      return;
    }
    if (
      classificacao != "livre" &&
      +classificacao <
        (new Date() - new Date(dataNascimento)) / (1000 * 60 * 60 * 24 * 365.25)
    ) {
      return alertCustom(`Idade mínima é de ${classificacao} anos!`);
    }
    if (!sexo) {
      alertCustom("Preencha todos os campos!");
      return;
    }
    if (dadosCadastro.dependentes.length >= 4) {
      alertCustom("Você já atingiu o limite máximo de dependentes (4).");
      return;
    }
    let usuarioExistsCadastrado = null;
    try {
      const { data } = await axios.get(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/verificar?cpf=${doc.replace(
          /[^\d]/g,
          ""
        )}`
      );
      if (data.status) {
        alertCustom(
          "Usuário já possui cadastro no sistema, o mesmo deve se inscrever por conta própria!"
        );
        return;
      }
    } catch (error) {
      alertCustom(
        "Ocorreu um erro ao verificar situação do dependente informado, verifique os dados!"
      );
      return;
    }

    const newDependent = {
      nome: name,
      doc: doc.replace(/[^\d]/g, ""),
      prop: false,
      email: dataManager.userData.auth.email,
      dataNascimento: new Date(dataNascimento),
      sexo: sexo,
      kits: [],
      formulario: [],
      dataInscricao: new Date(),
    };
    setVisibilityButtonStep(true);
    handleSetPivo(newDependent);

    setName("");
    setDoc("");
    setDataNascimento("");
    setSexo("");
    setShowForm(false);
    setEmail("");
  };

  const handleDeleteDependent = (dependent) => {
    setDadosCadastro((prevState) => ({
      ...prevState,
      dependentes: prevState.dependentes.filter(
        (item) => item.doc !== dependent.doc
      ),
    }));
    setActiveStep(3);
    setPivo(pivo - 1);
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          background: "transparent",
          padding: 2,
          height: {
            xs: "52vh",
            md: "60vh",
          },
          overflowX: "scroll",
        }}
      >
        {!showForm && (
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <Typography variant="h6">
                Dependente
                <Typography variant="body1" color="textSecondary">
                  Deseja adicionar um dependente?
                </Typography>
              </Typography>
            </Grid>
            {dadosCadastro.dependentes.map((item, index) => (
              <Grid item key={index} md={12} xs={12}>
                <Chip
                  label={item.nome}
                  onDelete={
                    index !== 0 ? () => handleDeleteDependent(item) : undefined
                  }
                />
              </Grid>
            ))}

            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <Button
                disableElevation
                onClick={() => {
                  setShowForm(true);
                  setVisibilityButtonStep(false);
                }}
                color="success"
                variant="contained"
              >
                Adicionar mais um participante
              </Button>
            </Grid>
          </Grid>
        )}
        {showForm && (
          <Fragment>
            <Typography variant="h6">
              Adicionar Dependente
              <Typography variant="body2" color="textSecondary">
                Insira as informações do dependente.
              </Typography>
            </Typography>
            <Paper elevation={0} sx={{ marginTop: "16px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="name"
                    label="Nome completo do dependente"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="name"
                    placeholder="CPF ou RG"
                    label="Documento (CPF ou RG)"
                    type="text"
                    fullWidth
                    value={doc}
                    onChange={(e) => setDoc(maskDoc(e.target.value))}
                  />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <TextField
                    variant="standard"
                    id="email"
                    placeholder="Email"
                    label="E-mail do novo participante (Opcional)"
                    type="text"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    id="dataNascimento"
                    label="Data de Nascimento"
                    type="date"
                    fullWidth
                    value={dataNascimento}
                    onChange={(e) => setDataNascimento(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="sexo-label">Sexo</InputLabel>
                    <Select
                      labelId="sexo-label"
                      id="sexo"
                      variant="outlined"
                      value={sexo}
                      label="Sexo"
                      onChange={(e) => setSexo(e.target.value)}
                    >
                      <MenuItem value="Masculino">Masculino</MenuItem>
                      <MenuItem value="Feminino">Feminino</MenuItem>
                      <MenuItem value="Não informado">Não informar</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} md={12}>
                  <CardHeader
                    className="show-box"
                    avatar={<ReportProblemIcon fontSize="small" />}
                    title={
                      <Typography color="textSecondary" variant="body2">
                        São considerados dependentes candidatos com idade de
                        máxima de 16 anos ou familiares! Ao não seguir esses
                        critérios, recomenda-se que o candidado realize a
                        inscrição com sua própria conta, evitando
                        inconformidades no cadastro e penalidades.
                      </Typography>
                    }
                  />
                </Grid> */}
              </Grid>
            </Paper>
          </Fragment>
        )}
      </Paper>

      {showForm && (
        <Grid
          container
          sx={{
            p: "8px 8px",
            alignSelf: "flex-end",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap-reverse",
          }}
          spacing={1}
        >
          <Grid item xs={12} md={4}>
            <Button
              fullWidth
              disableElevation
              onClick={() => {
                setShowForm(false);
                setVisibilityButtonStep(true);
              }}
              variant="text"
              color="primary"
              sx={{ height: "32px" }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              fullWidth
              disableElevation
              onClick={handleAddDependent}
              variant="contained"
              color="success"
              sx={{ height: "37px" }}
            >
              Adicionar
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AddDependentButton;
