import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountList from "./ListaContas";
import TabelaPagamentos from "./TabelaPagamentos";
import TaxConfigDialog from "./TaxConfigDialog"; // Importe o novo componente
import CloseIcon from "@mui/icons-material/Close";

const Volumetria = ({ alertCustom }) => {
  const isMobile = window.innerWidth <= 600;
  const [dataBoard, setDataBoard] = useState(null);
  const [openConfigDialog, setOpenConfigDialog] = useState(false);

  const handleOpenConfigDialog = () => {
    setOpenConfigDialog(true);
  };

  const handleCloseConfigDialog = () => {
    setOpenConfigDialog(false);
  };

  return (
    <Grid container spacing={2} sx={{ height: "205px" }}>
      {/* Primeiro Card */}
      <Grid item xs={12} md={4}>
        <Card variant="outlined">
          <CardContent sx={{ width: "100%", height: "100%", height: "205px" }}>
            {dataBoard ? (
              <>
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{ whiteSpace: "pre-wrap", margin: "10px 0" }}
                >
                  <Typography>{dataBoard.ciclo}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Total movimentado:</Typography>
                  <Typography sx={{ width: "30%" }}>
                    {dataBoard.totalMovimentado}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Total pago:</Typography>
                  <Typography sx={{ width: "30%" }}>
                    {dataBoard.totalPago}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Pagamentos pendentes:</Typography>
                  <Typography sx={{ width: "30%" }}>
                    {dataBoard.pendentes}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Pagamentos realizados:</Typography>
                  <Typography sx={{ width: "30%" }}>
                    {dataBoard.realizados}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>Lucro:</Typography>
                  <Typography sx={{ width: "30%" }}>
                    {dataBoard.lucro}
                  </Typography>
                </Box>
              </>
            ) : (
              <div className="align-center">
                <CircularProgress />
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>

      {/* Segundo Card */}
      <Grid
        item
        xs={12}
        md={8}
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Grid container sx={{ flex: 1, height: "100%" }}>
          <Grid
            item
            xs={12}
            sx={{
              height: "20%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Solicitações</Typography>

            <Tooltip title="Configurações">
              <IconButton onClick={handleOpenConfigDialog}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              height: "100%",
              maxHeight: "90%",
              display: "flex",
              width: "100%",
            }}
          >
            <Card
              variant="outlined"
              sx={{ flex: 1, width: "100%", maxHeight: "100%" }}
              className="allow-scroll"
            >
              <AccountList alertCustom={alertCustom} />
            </Card>
          </Grid>
        </Grid>
      </Grid>

      {/* Tabelas de Pagamentos */}
      <Grid item xs={12} md={12}>
        <TabelaPagamentos
          setDataBoard={setDataBoard}
          alertCustom={alertCustom}
        />
      </Grid>

      {/* Dialog de Configurações */}
      <Dialog
        fullScreen={isMobile}
        open={openConfigDialog}
        onClose={handleCloseConfigDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Configurações
          <IconButton onClick={handleCloseConfigDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TaxConfigDialog alertCustom={alertCustom} />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default Volumetria;
