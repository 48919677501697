import React, { useState } from "react";
import { Button } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import * as XLSX from "xlsx";
import axios from "./configAxios";

const ExportToExcelButton = ({ data, alertCustom, label }) => {
  const [isGenerating, setIsGenerating] = useState(false);

  const downloadExcel = async (data) => {
    try {
      setIsGenerating(true);

      // Create workbook
      const workbook = XLSX.utils.book_new();

      // Iterate over data to create sheets
      data.forEach((tableData, index) => {
        const { columns, dados, name } = tableData;

        // Create worksheet
        const worksheet = XLSX.utils.aoa_to_sheet([columns, ...dados]);

        // Set column widths
        worksheet["!cols"] = columns.map((width) => ({ wpx: 100 }));

        // Apply style to title (first line)
        const titleRange = XLSX.utils.decode_range(
          `A1:${XLSX.utils.encode_col(columns.length - 1)}1`
        );
        for (let col = titleRange.s.c; col <= titleRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({
            r: titleRange.s.r,
            c: col,
          });
          // worksheet[cellAddress].s = {
          //   font: { bold: true },
          //   fill: { patternType: "solid", fgColor: { rgb: "ff0000" } }, // Red background
          // };
        }

        // Add colored cell example (optional)
        // worksheet[XLSX.utils.encode_cell({ r: 2, c: 1 })].s = {
        //   fill: { patternType: "solid", fgColor: { rgb: "00FF00" } }, // Green background
        // };

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, name);
      });

      // Save workbook as XLSX file
      XLSX.writeFile(workbook, "go-runners-relatorio.xlsx");

      setIsGenerating(false);
    } catch (error) {
      setIsGenerating(false);
      alertCustom("Erro ao gerar relatório!");
      console.log(error);
    }
  };

  return (
    <Button
      fullWidth
      disableElevation
      variant="contained"
      color="success"
      disabled={isGenerating}
      onClick={() => downloadExcel(data)}
    >
      <GetAppIcon />
      {label}
    </Button>
  );
};

export default ExportToExcelButton;
