import React, { useEffect, useState } from "react";
import PaymentBrick from "./PaymentBrick";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles"; // Importando useTheme
import TextField from "@mui/material/TextField";
import axios from "../../../../utils/configAxios";
import { CircularProgress, InputAdornment } from "@mui/material";
const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const Payment = ({
  eventoData,
  financeiro,
  dadosCadastro,
  setDadosCadastro,
  setFormData,
  alertCustom,
  pivo,
  activeStep,
}) => {
  const [paymentBrickController, setPaymentBrickController] = useState(null);
  const theme = useTheme();
  useEffect(() => {
    const setData = async () => {
      if (paymentBrickController) {
        const result = await paymentBrickController.getFormData();
        setFormData(result);
      }
    };
    setData();
  }, [paymentBrickController]);

  const handlePaymentBrickReady = (controller) => {
    setPaymentBrickController(controller);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        padding: 2,
        minHeight: { xs: "52vh", md: "60vh" },
        overflowX: "scroll",
      }}
    >
      <Grid
        container
        spacing={1}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} md={12}>
          <Typography variant="h6">
            Pagamento
            <Typography variant="body2" color="textSecondary">
              Acompanhe o resumo do pedido antes de prosseguir
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          {financeiro && financeiro.valorTotal && activeStep === 5 ? (
            <PaymentBrick
              eventoData={eventoData}
              dadosCadastro={dadosCadastro}
              themeType={theme.palette.mode}
              onPaymentBrickReady={handlePaymentBrickReady}
              financeiro={financeiro}
              alertCustom={alertCustom}
              pivo={pivo}
            />
          ) : (
            <div
              style={{ display: "flex", justifyContent: "center", padding: 10 }}
            >
              <CircularProgress />
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Payment;
