import React, { useState } from "react";
import {
  MenuItem,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import c10 from "../../../images/Classificacao/c10.png";
import c12 from "../../../images/Classificacao/c12.png";
import c14 from "../../../images/Classificacao/c14.png";
import c16 from "../../../images/Classificacao/c16.png";
import c18 from "../../../images/Classificacao/c18.png";
import livre from "../../../images/Classificacao/livre.png";

// Exemplo de URLs de imagens para cada classificação indicativa
const classificationOptions = [
  { label: "Livre", value: "livre", imgSrc: livre },
  { label: "10 anos", value: "10", imgSrc: c10 },
  { label: "12 anos", value: "12", imgSrc: c12 },
  { label: "14 anos", value: "14", imgSrc: c14 },
  { label: "16 anos", value: "16", imgSrc: c16 },
  { label: "18 anos", value: "18", imgSrc: c18 },
];

const ClassificacaoIndicativa = ({
  classificacao,
  setClassificacao,
  readOnly = false,
}) => {
  const [open, setOpen] = useState(false); // Estado para abrir e fechar o modal

  const handleClickOpen = () => {
    setOpen(true); // Abre o modal
  };

  const handleClose = () => {
    setOpen(false); // Fecha o modal
  };

  const handleSelect = (value) => {
    setClassificacao(value); // Seleciona a classificação indicativa
    handleClose(); // Fecha o modal após a seleção
  };

  // Encontra a imagem correspondente à classificação atual
  const selectedImage = classificationOptions.find(
    (option) => option.value === classificacao
  ).imgSrc;

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        {/* Imagem que serve como botão para abrir o modal */}
        <img
          src={selectedImage}
          alt="Classificação Indicativa"
          style={{
            width: 50,
            height: 50,
            cursor: "pointer",
            borderRadius: "8px",
          }}
          onClick={() => !readOnly && handleClickOpen()} // Ao clicar, abre o modal
        />
      </Grid>

      {/* Modal com as opções de classificação indicativa */}
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          Selecione a Classificação Indicativa
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {classificationOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => handleSelect(option.value)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 2,
                cursor: "pointer",
              }}
            >
              <img
                src={option.imgSrc}
                alt={option.label}
                style={{ width: 40, marginRight: 10 }}
              />
              <Typography>{option.label}</Typography>
            </MenuItem>
          ))}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ClassificacaoIndicativa;
