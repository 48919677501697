import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { IconButton, ListItemButton } from "@mui/material";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import AddIcon from "@mui/icons-material/Add";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import DeleteIcon from "@mui/icons-material/Delete";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function InteractiveList({
  arquivos,
  clickFunction,
  deleteArquivo,
}) {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Demo>
          <List>
            {clickFunction && ( // Verifica se clickFunction está definida
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ListItemButton
                    onClick={() => clickFunction()}
                    sx={{ borderRadius: "8px" }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <AddIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Adicionar Arquivos"
                      secondary="Adicione ou configure novos arquivos extras"
                    />
                  </ListItemButton>
                </Grid>
              </Grid>
            )}
            {arquivos &&
              arquivos.map((arquivo, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      sx={{
                        background:
                          hoveredIndex === index ? "#FF5733" : "#4AA3E4",
                      }}
                      size="small"
                    >
                      {arquivo.type === "pdf" ? (
                        hoveredIndex === index ? (
                          <IconButton onClick={() => deleteArquivo(index)}>
                            <DeleteIcon />
                          </IconButton>
                        ) : (
                          <InsertDriveFileRoundedIcon />
                        )
                      ) : hoveredIndex === index ? (
                        <IconButton onClick={() => deleteArquivo(index)}>
                          <DeleteIcon />
                        </IconButton>
                      ) : (
                        <InsertPhotoIcon />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    titulo
                    primary={arquivo.campo}
                    secondary={
                      arquivo.src ?? `${arquivo.titulo}.${arquivo.type}`
                    }
                  />
                </ListItem>
              ))}
          </List>
        </Demo>
      </Grid>
    </Grid>
  );
}
