import React, { useEffect, useState } from "react";
import axios from "../../../utils/configAxios";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  Icon,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";

const { REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM } = process.env;

const ErrorIndicator = ({ eventoSelecionado }) => {
  const [errors, setErrors] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchErrors = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/indicativos?eventoId=${eventoSelecionado.id}`
        );
        setErrors(response.data);
      } catch (error) {
        console.error("Erro ao buscar os indicativos:", error);
      }
    };

    fetchErrors();
  }, [eventoSelecionado]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <label
        onClick={handleOpen}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <IconButton
          color={errors.length ? "error" : "success"}
          style={{ marginRight: 8 }}
        >
          <WarningIcon />
        </IconButton>
        <Typography
          variant="caption"
          color={errors.length ? "error" : "success"}
        >
          {errors.length
            ? `${errors.length} Erro${errors.length > 1 ? "s" : ""} encontrado${
                errors.length > 1 ? "s" : ""
              }`
            : "Inscrições estáveis"}
        </Typography>
      </label>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Erros Encontrados: {errors.length}
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List>
            {errors.map((error) => (
              <ListItem key={error.codTransacao}>
                <ListItemText
                  primary={`Transação: ${error.codTransacao}`}
                  secondary={`Status: ${error.status}`}
                />
              </ListItem>
            ))}
          </List>
          <Typography className="show-box" color="textSecondary">
            Caso seja identificado algum erro, entre em contato com o suporte.
            Verificamos a saúde dos registros para garantir que imprevistos não
            o prejudiquem no decorrer do evento!
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ErrorIndicator;
