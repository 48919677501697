import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Tooltip,
  CircularProgress,
  Typography,
  Box, // Importação do Box do MUI
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ActionClick from "./assets/redirect";
import axios from "../../../utils/configAxios";

const AccountList = ({ alertCustom }) => {
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const handleAprovacaoOrganizador = async (decisao, body, id) => {
    try {
      if (decisao === "APROVADO") {
        await axios
          .post(
            `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/cadastrar-organizador`,
            body
          )
          .then(async () => {
            await axios.post(
              `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/alterar-solicitacao-inscricao?id=${id}&status=${decisao}`
            );
            alertCustom("Solicitação aprovada com sucesso!");
          });
      } else if (decisao === "REPROVADO") {
        await axios.post(
          `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/alterar-solicitacao-inscricao?id=${id}&status=${decisao}`
        );
        alertCustom("Solicitação reprovada com sucesso!");
      }
      fetchSolicitacoes();
    } catch (error) {
      console.log(error);
      alertCustom(`Ocorreu um erro ao ${decisao.toLowerCase()} solicitação!`);
    }
  };

  const fetchSolicitacoes = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/listar-solicitacao-inscricao?status=PENDING`
      );
      const dataFormated = data.map((item) => ({
        ...item,
        body: JSON.parse(item.body),
      }));
      setAccounts(dataFormated);
    } catch (error) {
      alertCustom("Ocorreu um erro ao listar solicitações");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSolicitacoes();
  }, []);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <List sx={{ height: "100%", width: "100%" }}>
        {!loading ? (
          accounts?.length > 0 ? (
            accounts.map((account, index) => (
              <ListItem key={index}>
                <ListItemAvatar>
                  <Avatar>{account.body.nome[0].toUpperCase()}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      {`${account.body.nome} - ${account.body.contato} ${account.body.telefone}`}
                    </Typography>
                  }
                  secondary={
                    <ActionClick
                      alertCustom={alertCustom}
                      value={account.email}
                    />
                  }
                />
                <Tooltip title="Aprovar">
                  <IconButton
                    onClick={() =>
                      handleAprovacaoOrganizador(
                        "APROVADO",
                        account.body,
                        account.id
                      )
                    }
                  >
                    <DoneIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reprovar">
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      handleAprovacaoOrganizador(
                        "REPROVADO",
                        account.body,
                        account.id
                      )
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ background: "#f0f0f0", color: "#212121" }}>
                  <DoneIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Sem solicitações pendentes</ListItemText>
            </ListItem>
          )
        ) : (
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

export default AccountList;
