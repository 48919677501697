import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import { tourSteps } from "./Steps";
import Cookies from "js-cookie";

export default function TourComponent({ restartTour }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [highlightedElement, setHighlightedElement] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const cardRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const accessType = localStorage.acessType;
    if (accessType !== "@manager") {
      return;
    }
    if (restartTour) {
      Cookies.remove("tourCompleted");
      Cookies.remove("currentStep");
      navigate("/dash/dashboard");
      window.location.reload();
      setCurrentStep(0);
      return;
    }

    const tourCompleted = Cookies.get("tourCompleted");
    if (tourCompleted) {
      setCurrentStep(-1);
      return;
    }

    const savedStep = Cookies.get("currentStep");
    if (savedStep) {
      setCurrentStep(parseInt(savedStep, 10));
    }

    const targetElement = document.querySelector(
      tourSteps[currentStep]?.targetClass
    );

    if (targetElement) {
      setHighlightedElement(targetElement);
      setAnchorEl(targetElement);
    }

    return () => {
      if (targetElement) {
        targetElement.style.border = "none";
      }
    };
  }, [currentStep, highlightedElement, restartTour]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        event.stopPropagation();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNext = () => {
    const nextStep = currentStep + 1;
    if (nextStep < tourSteps.length) {
      setCurrentStep(nextStep);
      Cookies.set("currentStep", nextStep, { expires: 7 });
      if (tourSteps[nextStep].navigateTo) {
        navigate(tourSteps[nextStep].navigateTo);
      }
      if (tourSteps[nextStep].event && tourSteps[nextStep].event === "click") {
        document.querySelector(".MuiBackdrop-invisible")?.click();
        document.querySelector(tourSteps[nextStep].targetClass)?.click();
      }

      if (tourSteps[nextStep].event && tourSteps[nextStep].event === "focus") {
        document
          .querySelector(tourSteps[nextStep].targetClass)
          ?.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
      }

      if (tourSteps[nextStep].mobileEvent && isMobile) {
        document.querySelector(tourSteps[nextStep].targetClass)?.click();
      }
    }
  };

  const handleClose = () => {
    setCurrentStep(-1);
    Cookies.set("tourCompleted", "true", { expires: 365 });
    Cookies.remove("currentStep");
  };

  const handleFinish = () => {
    Cookies.set("tourCompleted", "true", { expires: 365 });
    handleClose();
  };

  const open = Boolean(anchorEl);

  const positionStyles = {
    position: "fixed",
    background: "#fff",
    borderRadius: isMobile ? "0" : "16px",
    right: "20px",
    maxWidth: 500,
    boxShadow: 3,
    width: isMobile ? "100%" : "auto",
    transform: isMobile ? "translateX(-50%)" : undefined,
    left: isMobile ? "50%" : undefined,
    zIndex: 10001,
    ...(tourSteps[currentStep]?.position === "bottom" || !isMobile
      ? {
          bottom: isMobile ? "0" : "20px",
          top: undefined,
        }
      : {
          top: isMobile ? "70px" : "90px",
          bottom: undefined,
        }),
  };

  return (
    <>
      {currentStep !== -1 && anchorEl && (
        <Card ref={cardRef} sx={positionStyles}>
          <CardContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <b>TOUR {`${currentStep + 1}/${tourSteps.length}`}</b>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {tourSteps[currentStep]?.description}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button variant="text" onClick={handleClose}>
                  Entendi tudo
                </Button>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={
                    currentStep === tourSteps.length - 1
                      ? handleFinish
                      : handleNext
                  }
                  endIcon={<ArrowForwardIcon />}
                >
                  {currentStep === tourSteps.length - 1
                    ? "Finalizar"
                    : "Próximo"}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
