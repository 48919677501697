import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Categorias from "./Categorias";
import RenderForm from "./RenderForm";
import RenderKits from "./RenderKits";
import DataAttemp from "../../../utils/DataAttemp";
import Pagamentos from "./Pagamentos/";
import axios from "../../../utils/configAxios";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Confirmacao from "./Confirmacao";
import NewParticpante from "./NewParticipante";
import InserirCupomDesconto from "./InserirCupomDesconto";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Timer from "../timer";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const VerticalLinearStepper = ({
  setPivo,
  pivo,
  dadosCadastro,
  setDadosCadastro,
  alertCustom,
  evento,
  financeiro,
  setOpenDialog,
  setRoute,
  setConfirmMessage,
  start,
  setStart,
}) => {
  const dataManager = DataAttemp();
  const parametros = useParams();
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [formData, setFormData] = useState(null);
  const [respondidos, setRespondidos] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibilityButtonStep, setVisibilityButtonStep] = useState(true);
  const containerRef = useRef(null);
  const isDesktop = useMediaQuery("(min-width:960px)");
  const navigate = useNavigate();
  const passos = [
    "categoria",
    "formulario",
    "kit",
    "participante",
    "cupom",
    "pagamento",
  ];

  const { passo } = parametros;
  const passoIndex = passos.indexOf(passo);
  const [activeStep, setActiveStep] = useState(
    passoIndex >= 0 ? passoIndex + 1 : 0
  );
  const [activeAntStep, setActiveAntStep] = useState(-1);

  useEffect(() => {
    const { pathname } = location;
    const parts = pathname.split("/");

    const currentStep = parts[parts.length - 1];

    const currentStepIndex = passos.indexOf(currentStep);
    if (currentStepIndex !== -1 && activeAntStep != -1) {
      if (currentStepIndex !== 0 && currentStepIndex != activeStep) {
        navigate(`/cadastro/${evento.id}/${passos[currentStepIndex]}`);
      } else {
        setActiveStep(currentStepIndex);
      }
    } else {
      setActiveStep(0);
      navigate(`/cadastro/${evento.id}/${passos[0]}`);
    }
    setActiveAntStep(activeStep);
  }, [activeStep]);

  useEffect(() => {
    const { pathname } = location;
    const parts = pathname.split("/");
    const currentStep = parts[parts.length - 1];
    const currentStepIndex = passos.indexOf(currentStep);
    if (currentStepIndex < activeStep) {
      setActiveStep(currentStepIndex);
    }
  }, [window.location.href]);

  const handleReserveVaga = async (passo) => {
    try {
      if (pivo === 0 && passo === 0) {
        dadosCadastro.dependentes[0].dataInscricao = new Date();
        setDadosCadastro(dadosCadastro);
      }
      const data = {
        pivo: pivo,
        transacao: dadosCadastro.transacao,
        idUser: dataManager.userData.id,
        idEvento: evento.id,
        dependentes: dadosCadastro.dependentes,
        dataInscricao: dadosCadastro.dataExpiracao,
      };
      const response = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/reservar-vaga`,
        data
      );
      setStart(true);
      return true;
    } catch (error) {
      setLoading(false);
      alertCustom(
        error.response.data.message ||
          "Não foi possível reservar a vaga, tente novamente mais tarde!"
      );
      return false;
    }
  };

  const steps = [
    {
      label: "Categoria",
      description: evento.categorias && (
        <Categorias
          setActiveStep={setActiveStep}
          setPivo={setPivo}
          pivo={pivo}
          dadosCadastro={dadosCadastro}
          setDadosCadastro={setDadosCadastro}
          categorias={evento.categorias}
          evento={evento}
        />
      ),
      status:
        dadosCadastro.dependentes[pivo] &&
        dadosCadastro.dependentes[pivo].categoriaId,
      function: true,
    },
    {
      label: "Questionário",
      description: evento.formulario && (
        <RenderForm
          pivo={pivo}
          setPivo={setPivo}
          alertCustom={alertCustom}
          formData={evento.formulario}
          dadosCadastro={dadosCadastro}
          setRespondidos={setRespondidos}
          setDadosCadastro={setDadosCadastro}
        />
      ),
      function: false,
      status: respondidos,
    },
    {
      label: "Kit Corrida",
      description: evento.tabelaPreco && (
        <RenderKits
          pivo={pivo}
          setPivo={setPivo}
          alertCustom={alertCustom}
          dadosCadastro={dadosCadastro}
          setDadosCadastro={setDadosCadastro}
          nomeTabela={evento.tabelaPreco}
        />
      ),
      function: true,
      status:
        dadosCadastro.dependentes[pivo] &&
        dadosCadastro.dependentes[pivo].kits.length > 0,
    },
    {
      label: "Participante",
      description: evento.tabelaPreco && (
        <NewParticpante
          classificacao={evento.classificacao}
          setVisibilityButtonStep={setVisibilityButtonStep}
          setActiveStep={setActiveStep}
          setPivo={setPivo}
          pivo={pivo}
          alertCustom={alertCustom}
          dadosCadastro={dadosCadastro}
          setDadosCadastro={setDadosCadastro}
        />
      ),
      function: true,
      status: true,
    },
    {
      label: "Cupom de desconto",
      description: evento.tabelaPreco && (
        <InserirCupomDesconto
          evento={evento}
          alertCustom={alertCustom}
          dadosCadastro={dadosCadastro}
          setDadosCadastro={setDadosCadastro}
        />
      ),
      function: true,
      status: true,
    },
    {
      label: "Pagamento",
      description:
        dadosCadastro &&
        (financeiro.valorTotal > 0 ? (
          <Pagamentos
            eventoData={evento}
            alertCustom={alertCustom}
            financeiro={financeiro}
            dadosCadastro={dadosCadastro}
            setDadosCadastro={setDadosCadastro}
            setFormData={setFormData}
            pivo={pivo}
            activeStep={activeStep}
          />
        ) : (
          <Confirmacao
            eventoData={evento}
            alertCustom={alertCustom}
            dadosCadastro={dadosCadastro}
          />
        )),
      status: true,
    },
  ];

  const handleNext = async () => {
    setLoading(true);
    if (activeStep === 3 && financeiro.valorTotal == 0) {
      setActiveStep((prevStep) => prevStep + 2);
      navigate(`/cadastro/${evento.id}/${passos[activeStep + 2]}`);
      return;
    }
    if (activeStep < steps.length - 1) {
      if (steps[activeStep]?.function) {
        const status = await handleReserveVaga(activeStep);
        if (!status) {
          return setLoading(false);
        }
      }
      setActiveStep((prevStep) => prevStep + 1);
      navigate(`/cadastro/${evento.id}/${passos[activeStep + 1]}`);
    } else {
      navigate(`/cadastro/${evento.id}/${passos[activeStep]}`);
    }
    setLoading(false);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      if (activeStep === 5 && financeiro.valorTotal == 0) {
        setActiveStep((prevStep) => prevStep - 2);
        navigate(`/cadastro/${evento.id}/${passos[activeStep - 2]}`);
        return setLoading(false);
      }
      setActiveStep((prevStep) => prevStep - 1);
      navigate(`/cadastro/${evento.id}/${passos[activeStep - 1]}`);
    } else {
      navigate("/home");
    }
  };

  const handleDeleteDependent = (dependent) => {
    setDadosCadastro({
      ...dadosCadastro,
      dependentes: dadosCadastro.dependentes.filter(
        (item) => item.doc !== dependent.doc
      ),
    });
    setPivo((prevPivo) => prevPivo - 1);
    setActiveStep(3);
  };

  return (
    <Grid container>
      <Grid
        item
        sx={{
          padding: 1,
          //height: "70px",
          overflowX: "hidden",
        }}
      >
        {/* <Stepper
          activeStep={activeStep}
          sx={{
            maxWidth: { xs: "100vw", md: "100%" },
            "& .MuiStepConnector-line": { display: "none" },
          }}
          ref={containerRef}
        >
          {steps.map((step, index) => (
            <Step
              sx={{
                transition: "transform 0.3s ease",
                transform: `translateX(-${scrollPosition}px)`,
              }}
              key={index}
            >
              <StepLabel>
                <Typography
                  variant="body1"
                  sx={{
                    width: { xs: "37vw", md: "150px" },
                    cursor: "pointer",
                    whiteSpace: "inherit",
                  }}
                  onClick={() =>
                    activeStep - 1 === index ? setActiveStep(index) : null
                  }
                >
                  {step.label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper> */}
      </Grid>
      <Grid item md={12} xs={12}>
        <Grid container>
          {!isDesktop && (
            <Grid item md={12} xs={12}>
              <Typography
                variant="button"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                {evento.titulo}{" "}
                <Timer
                  dataInscrição={dadosCadastro?.dependentes[0].dataInscricao}
                  start={start}
                  setRoute={setRoute}
                  setOpenDialog={setOpenDialog}
                  setConfirmMessage={setConfirmMessage}
                />
              </Typography>
            </Grid>
          )}
          <Grid item md={12} xs={12}>
            <Grid container>
              {steps.map((step, index) => (
                <Grid
                  item
                  xs={12}
                  md={12}
                  key={index}
                  style={{
                    display: activeStep === index ? "block" : "none",
                  }}
                >
                  {step.description}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item md={12} xs={12}>
            {visibilityButtonStep && (
              <Grid
                container
                spacing={1}
                sx={{
                  padding: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    order: { xs: 1, md: 3 },
                    display: activeStep === steps.length - 1 ? "none" : "flex",
                  }}
                >
                  <Button
                    fullWidth
                    disableElevation
                    disabled={!steps[activeStep]?.status || loading}
                    variant="contained"
                    type="submit"
                    onClick={handleNext}
                  >
                    {loading ? "Carregando..." : "Próximo"}
                  </Button>
                </Grid>
                {pivo > 0 && activeStep < 3 && (
                  <Grid item md={4} xs={12} sx={{ order: 2 }}>
                    <Button
                      variant="text"
                      sx={{ borderRadius: "50px" }}
                      color="error"
                      fullWidth
                      disableElevation
                      onClick={() =>
                        handleDeleteDependent(dadosCadastro.dependentes[pivo])
                      }
                    >
                      Cancelar
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} md={4} sx={{ order: { xs: 3, md: 1 } }}>
                  <Button
                    fullWidth
                    disableElevation
                    onClick={
                      activeStep === 0 ? () => navigate("/home") : handleBack
                    }
                  >
                    {activeStep === 0 ? "Voltar pra Home" : "Voltar"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VerticalLinearStepper;
