import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Paper,
  CircularProgress,
  Divider,
  IconButton,
} from "@mui/material";
import axios from "../../utils/configAxios";
import html2pdf from "html2pdf.js";
import DataAttemp from "../../utils/DataAttemp";
import Logo from "../../images/logo_footer.jpg";
import MarcaAg from "../../images/marcaAg.png";
import { format } from "date-fns";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const { REACT_APP_URL_BIBLIOTECA_RUNNERS } = process.env;

const DetalhesTransacao = ({
  historico,
  numeroTransacao,
  open,
  handleClose,
}) => {
  const [transacaoData, setTransacaoData] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [evento, setEvento] = useState(null);
  const contentRef = useRef(null);
  const dataManager = DataAttemp();

  useEffect(() => {
    if (historico.length > 0) {
      const { nomeEvento, subtitleEvento, formaPagamento, dataFinalEvento } =
        historico.find((item) => item.codTransacao === numeroTransacao);

      setEvento({
        nomeEvento: nomeEvento,
        subtitleEvento: subtitleEvento,
        formaPagamento: formaPagamento,
        dataFinalEvento: dataFinalEvento,
      });
    }
  }, [historico, numeroTransacao]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600); // Adjust this threshold as needed
    };

    handleResize(); // Call once to initialize state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchTransacaoData = async () => {
      try {
        const response = await axios.post(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/buscar-filtro-participantes`,
          {
            user: {
              token: dataManager.userData.auth.token,
              id: dataManager.userData.id,
            },
            status: "approved",
            transacao: numeroTransacao,
          }
        );
        setTransacaoData(response.data);
      } catch (error) {
        console.error("Erro ao buscar os dados da transação:", error);
      }
    };

    if (open) {
      fetchTransacaoData();
    }
  }, [numeroTransacao, open, dataManager.userData]);

  const handleImprimirPDF = () => {
    const element = contentRef.current;
    const opt = {
      margin: 1,
      filename: `comprovante_inscricao_${numeroTransacao}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().set(opt).from(element).save();
  };

  const statusText = {
    approved: "Inscrição aprovada",
    confirmed: "Inscrição validada",
    cancelled: "Inscrição cancelada",
    gratuito: "Inscrição aprovada",
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={isMobile}
      maxWidth="70vw"
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        INSCRIÇÃO #{numeroTransacao}
        <IconButton onClick={handleClose}>
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: { md: "60vw", xs: "100vw" },
          height: { md: "80vh", xs: "100vh" },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Paper
          ref={contentRef}
          elevation={0}
          sx={{
            position: "relative",
            width: "160mm",
            padding: 0,
            margin: 0,
            // boxSizing: "border-box",
            background: `url(${MarcaAg})`,
          }}
        >
          {transacaoData && transacaoData.length > 0 ? (
            <div>
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    borderRadius: "5px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ width: "100%", marginBottom: "20mm" }}
                    >
                      <b>{evento.nomeEvento}</b>
                      <Typography variant="body2">
                        {evento.subtitleEvento}
                      </Typography>
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      textAlign={"center"}
                    >
                      <Grid item xs={12} md={12}>
                        <img src={Logo} style={{ width: "160px" }} alt="Logo" />
                      </Grid>
                    </Typography>
                  </div>
                </Grid>
                {transacaoData.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{ display: "flex", flexWrap: "wrap" }}
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Transação</b>
                        <Typography variant="body2">
                          {item.transacao}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Nome</b>
                        <Typography variant="body2">{item.nome}</Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Documento</b>
                        <Typography variant="body2">
                          {item.documento}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Categoria</b>
                        <Typography variant="body2">
                          {item.categoriaName}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Valor do Ingresso R$</b>
                        <Typography variant="body2">
                          {item.valorIngresso}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Email</b>
                        <Typography variant="body2">{item.email}</Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Telefone</b>
                        <Typography variant="body2">{item.telefone}</Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Data de Nascimento</b>
                        <Typography variant="body2">
                          {format(
                            new Date(item.dataNascimento).setHours(
                              new Date(item.dataNascimento).getHours() + 24
                            ),
                            "dd/MM/yyyy"
                          )}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Sexo</b>
                        <Typography variant="body2">{item.sexo}</Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Data de Inscrição</b>
                        <Typography variant="body2">
                          {new Date(
                            new Date(item.dataInscricao).getTime() +
                              3 * 60 * 60 * 1000
                          ).toLocaleString()}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Status</b>
                        <Typography variant="body2">
                          {statusText[item.status]}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Valor Total</b>
                        <Typography variant="body2">
                          {`R$ ${Number(item.valorTotal).toFixed(2)} | ${
                            evento.formaPagamento
                          }`}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ width: "48mm", margin: "2mm" }}
                      >
                        <b>Data do evento</b>
                        <Typography variant="body2">
                          {format(
                            new Date(evento.dataFinalEvento),
                            "dd/MM/yyyy"
                          )}
                        </Typography>
                      </Typography>

                      {item.formulario &&
                        item.formulario.map((formItem, formIndex) => (
                          <Typography
                            key={formIndex}
                            variant="subtitle1"
                            gutterBottom
                            sx={{ width: "48mm", margin: "2mm" }}
                          >
                            <b>{formItem.input}</b>
                            <Typography variant="body2">
                              {formItem.resposta}
                            </Typography>
                          </Typography>
                        ))}

                      {item.produtos &&
                        item.produtos.map((produto, prodIndex) => (
                          <Grid
                            key={prodIndex}
                            item
                            xs={12}
                            md={12}
                            sx={{
                              borderRadius: "5px",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{ width: "48mm", margin: "2mm" }}
                            >
                              <b>Kit</b>
                              <Typography variant="body2">
                                {produto.nome}
                              </Typography>
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{ width: "48mm", margin: "2mm" }}
                            >
                              <b>Preço</b>
                              <Typography variant="body2">
                                {`R$ ${Number(produto.preco).toFixed(2)}`}
                              </Typography>
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{ width: "48mm", margin: "2mm" }}
                            >
                              <b>Quantidade</b>
                              <Typography variant="body2">
                                {produto.quantidade}
                              </Typography>
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{ width: "48mm", margin: "2mm" }}
                            >
                              <b>Variação</b>
                              <Typography variant="body2">
                                {produto.variacao}
                              </Typography>
                            </Typography>
                          </Grid>
                        ))}
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Divider textAlign="right">
                        <Typography variant="body2">
                          ptc. {index + 1}
                        </Typography>
                      </Divider>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </div>
          ) : Array.isArray(transacaoData) ? (
            <Typography
              className="show-box"
              sx={{ width: "100%", margin: "50px 0", textAlign: "center" }}
              variant="body1"
            >
              Ocorreu um problema ao exibir seu comprovante
              <Typography variant="body2">
                Tente novamente mais tarde ou comunique o suporte
              </Typography>
            </Typography>
          ) : (
            <Typography
              sx={{ width: "100%", margin: "50px 0", textAlign: "center" }}
              variant="body1"
            >
              <CircularProgress />
            </Typography>
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent={"end"}
          alignItems="flex-start"
          sx={{ minHeight: { xs: "100px", md: "0" } }}
        >
          <Grid item md={3} xs={12} sx={{ order: { md: 2, xs: 1 } }}>
            <Button
              fullWidth
              disabled={
                !transacaoData ||
                (Array.isArray(transacaoData) && transacaoData.length == 0)
              }
              onClick={handleImprimirPDF}
              variant="contained"
              disableElevation
            >
              Download PDF
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default DetalhesTransacao;
