import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
const REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM;

export default function UploadDialog({
  uploadFilesExtras, //já existentes
  alertCustom,
  open,
  handleCloseDialog,
  setUploadFilesExtras,
  setEventoData,
  eventoData,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [local, setLocal] = useState("");
  const [descricao, setDescricao] = useState("");

  const [fileToUpload, setFileToUpload] = useState(null);

  // const handleDeleteFile = (index) => {
  //   const newFiles = [...uploadFiles];
  //   newFiles.splice(index, 1);
  //   setUploadFiles(newFiles);
  // };

  // const closeAndReset = () => {
  //   setUploadFiles([]);
  //   setSelectedName("");
  //   setFileToUpload(null);
  //   handleCloseDialog();
  //   setLocal("");
  // };

  // useEffect(() => {
  //   if (uploadFiles && uploadFiles.length > 0) {
  //     setUploadFilesExtras(uploadFiles);
  //   }
  // }, [uploadFiles]);

  const onDrop = (acceptedFiles) => {
    setModalOpen(true);
    setFileToUpload(acceptedFiles[0]);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedName("");
    setFileToUpload(null);
    setLocal("");
  };

  const handleFileUpload = () => {
    if (!selectedName) {
      alertCustom("Por favor, selecione um destino válido para o arquivo.");
      return;
    }
    if (!local && selectedName === "Circuito") {
      alertCustom("Informe o link para o circuito selecionado.");
      return;
    }
    const term = fileToUpload.path.split(".");
    setUploadFilesExtras([
      ...uploadFilesExtras,
      {
        id: local.replace(/\D/g, ""),
        link: local,
        titulo: `${selectedName}${local.replace(/\D/g, "")}`,
        campo: selectedName,
        file: fileToUpload,
        descricao: descricao ?? `${selectedName}${local.replace(/\D/g, "")}`,
        type: term[term.length - 1],
      },
    ]);
    handleModalClose();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*, application/pdf",
  });

  return (
    <div>
      <Grid container style={{ position: "relative" }}>
        <Dialog open={modalOpen} onClose={handleModalClose}>
          <DialogTitle>
            <Typography variant="body1">
              Escolha o destino do arquivo
              <Typography variant="body2" color="textSecondary">
                Será exibido conforme sua escolha
              </Typography>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={1}
              sx={{ padding: "10px 0", maxWidth: "300px" }}
            >
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Destino do arquivo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedName}
                    label="Destino do arquivo"
                    onChange={(event) => setSelectedName(event.target.value)}
                  >
                    {["Regulamento", "Kit", "Circuito"].map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  onInput={(e) => setDescricao(e.target.value)}
                  label="Descrição da imagem"
                  placeholder="Descrição"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {selectedName === "Circuito" && (
                  <TextField
                    fullWidth
                    onInput={(e) => setLocal(e.target.value)}
                    label="Link para o circuito"
                    placeholder="https://www.plotaroute.com/route/xxxxx"
                    variant="outlined"
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose}>Cancelar</Button>
            <Button onClick={handleFileUpload} color="primary">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            Upload de Arquivos
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div {...getRootProps()} style={{ cursor: "pointer" }}>
                  <input {...getInputProps()} />
                  <Typography variant="body1" className="show-box-dash">
                    Arraste e solte os arquivos aqui ou clique para selecionar
                    <Typography variant="body2" color="textSecondary">
                      Formatos aceitos: .pdf, .png, .jpeg, .jpg
                    </Typography>
                  </Typography>
                </div>
              </Grid>
              {/* <Grid item xs={12}>
                <Grid container spacing={1}>
                  {uploadFiles.map((file, index) => (
                    <Grid item key={index}>
                      <Chip
                        label={`${file.titulo}.${file.type}`}
                        onDelete={() => handleDeleteFile(index)}
                      />
                    </Grid>
                  ))}
                </Grid> 
              </Grid>*/}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "right" }}>
            <Button
              onClick={handleCloseDialog}
              color="primary"
              // disabled={uploadFilesExtras.length === 0}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
}
