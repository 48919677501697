import React, { useState, useEffect } from "react";
import {
  Container,
  CircularProgress,
  Typography,
  Box,
  TextField,
  Grid,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../../../utils/configAxios";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import NavBar from "../../../components/NavegationBar/";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const { REACT_APP_URL_BIBLIOTECA_RUNNERS } = process.env;

const ListaParticipantes = ({ alertCustom }) => {
  const { idEvento } = useParams();
  const [participantes, setParticipantes] = useState([]);
  const [filteredParticipantes, setFilteredParticipantes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [eventDetails, setEventDetails] = useState({
    titulo: "",
    subTitulo: "",
  });
  const [page, setPage] = useState(0);
  const rowsPerPage = 100;

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/listar-eventos-parcial`
        );
        const event = response.data.find(
          (evento) => evento.id.toString() === idEvento
        );
        if (event) {
          setEventDetails({ titulo: event.titulo, subTitulo: event.subTitulo });
          fetchParticipantes();
        } else {
          setError("Evento não encontrado.");
          alertCustom("Evento não encontrado.");
          setLoading(false);
        }
      } catch (error) {
        setError("Erro ao buscar detalhes do evento.");
        alertCustom("Erro ao buscar detalhes do evento.");
        console.error("Erro ao buscar detalhes do evento:", error);
        setLoading(false);
      }
    };

    const fetchParticipantes = async () => {
      try {
        const response = await axios.post(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/buscar-filtro-participantes`,
          {
            partialQuery: true,
            idEvento,
          }
        );
        setParticipantes(response.data);
        setFilteredParticipantes(response.data);
      } catch (error) {
        setError("Erro ao buscar os participantes.");
        alertCustom("Erro ao buscar os participantes.");
        console.error("Erro ao buscar os participantes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [idEvento]);

  useEffect(() => {
    const filtered = participantes.filter((participante) =>
      participante.nome.toLowerCase().includes(filterText.toLowerCase())
    );
    setFilteredParticipantes(filtered);
  }, [filterText, participantes]);

  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prevPage) => {
      const maxPage = Math.floor(filteredParticipantes.length / rowsPerPage);
      return Math.min(prevPage + 1, maxPage);
    });
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
          <CircularProgress />
          <Typography variant="h6" mt={2}>
            Carregando participantes...
          </Typography>
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={4}
          className="show-box"
        >
          {error}
        </Box>
      </Container>
    );
  }

  const statusLiteral = (status) => {
    const st = {
      approved: "Aprovado",
      cancelled: "Cancelado",
      pending: "Aguardando",
      singin_up: "Em processo de inscrição",
    };
    return st[status];
  };

  const rows = filteredParticipantes
    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
    .map((participante, index) => ({
      id: index + page * rowsPerPage,
      nome: participante.nome,
      categoriaName: participante.categoriaName,
      sexo: participante.sexo,
      dataInscricao: format(new Date(participante.dataInscricao), "dd/MM/yyyy"),
      numCamisa: participante.numCamisa,
      endereco: participante.endereco,
      status: statusLiteral(participante.status),
    }))
    .sort((a, b) => a.numCamisa - b.numCamisa);

  const columns = [
    //{ field: "numCamisa", headerName: "Número Inscrição", width: 150 },
    { field: "nome", headerName: "Nome", width: 200 },
    { field: "categoriaName", headerName: "Categoria", width: 150 },
    { field: "sexo", headerName: "Sexo", width: 100 },
    {
      field: "dataInscricao",
      headerName: "Data de Inscrição",
      width: 200,
      type: "string",
    },
    { field: "endereco", headerName: "Endereço", width: 250 },
    { field: "status", headerName: "Status", width: 150 },
  ];

  return (
    <>
      <NavBar />
      <Container sx={{ marginTop: "80px" }}>
        <Grid container>
          <Grid xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {eventDetails.titulo}{" "}
              <Typography variant="body1" gutterBottom>
                {eventDetails.subTitulo}
              </Typography>
            </Typography>
          </Grid>
          <Grid xs={12} md={4}>
            <TextField
              placeholder="Filtrar por Nome"
              variant="outlined"
              fullWidth
              size="small"
              margin="normal"
              value={filterText}
              onChange={handleFilterTextChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: <SearchIcon color="secondary" />,
                sx: {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  background: "rgba(0,0,0,0.05)",
                  borderRadius: "50px",
                },
              }}
            />
          </Grid>
          <Grid
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <IconButton
              onClick={handlePrevPage}
              disabled={page === 0}
              sx={{ marginRight: 2 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              onClick={handleNextPage}
              disabled={
                (page + 1) * rowsPerPage >= filteredParticipantes.length
              }
            >
              <ArrowForwardIcon />
            </IconButton>
          </Grid>
          <Grid xs={12} md={12}>
            <div
              style={{ height: "60vh", width: "100%" }}
              className="allow-scroll"
            >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={rowsPerPage}
                disableSelectionOnClick
                hideFooter
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ListaParticipantes;
