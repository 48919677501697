import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Typography,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmDialog = ({
  open,
  title,
  message,
  onConfirmOne,
  onConfirmAll,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">{title}</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            {" "}
            <Button
              fullWidth
              disableElevation
              onClick={onConfirmOne}
              variant="text"
            >
              Mudar Selecionadas
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            {" "}
            <Button
              fullWidth
              disableElevation
              onClick={onConfirmAll}
              variant="contained"
            >
              Mudar todas
            </Button>
          </Grid>{" "}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
