import React from "react";
import { IconButton } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const WhatsAppFloatingButton = () => {
  const handleClick = () => {
    window.open("https://wa.me/6284286158", "_blank");
  };

  return (
    <IconButton
      onClick={handleClick}
      sx={{
        width: "60px",
        height: "60px",
        position: "fixed",
        bottom: "5%",
        right: "5%",
        backgroundColor: "#25D366", // Cor do WhatsApp
        color: "#fff",
        "&:hover": {
          backgroundColor: "#128C7E", // Cor do WhatsApp quando hover
        },
        animation: "float 3s ease-in-out infinite",
        zIndex: 1000, // Garante que o botão esteja sempre acima de outros elementos
      }}
    >
      <WhatsAppIcon sx={{ width: "40px", height: "40px" }} />
    </IconButton>
  );
};

export default WhatsAppFloatingButton;
