import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Tab,
  Tabs,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DoneIcon from "@mui/icons-material/Done";
import axios from "../../../utils/configAxios";
import ModalDetalhes from "./ModalDetalhes";
import { styled } from "@mui/material/styles";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "100px",
    zIndex: 0,
  },
  "& .MuiTabs-indicatorSpan": {
    marginTop: "55px",
    height: "40px",
    width: "100%",
    borderRadius: "5px",
    backgroundColor: "#f0f0f0",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    color: "#202020",
    zIndex: 1,
    "&.Mui-selected": {
      color: "#212121",
      backgroundColor: "transparent",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#000",
    },
  })
);

const TabelaPagamentos = ({ alertCustom, setDataBoard }) => {
  const [open, setOpen] = useState({
    detalhes: false,
  });
  const [tabIndex, setTabIndex] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [pendingPayments, setPendingPayments] = useState([]);
  const [paidPayments, setPaidPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const obterCiclo = () => {
    const meses = [
      "JAN",
      "FEV",
      "MAR",
      "ABR",
      "MAI",
      "JUN",
      "JUL",
      "AGO",
      "SET",
      "OUT",
      "NOV",
      "DEZ",
    ];
    const dataAtual = new Date();
    const mesAtual = dataAtual.getMonth();
    const anoAtual = dataAtual.getFullYear();
    const mesAnterior = (mesAtual + 11) % 12;
    const mesSeguinte = (mesAtual + 1) % 12;
    return (
      <div style={{ display: "flex" }}>
        <Typography color="textSecondary">{`${meses[mesAnterior]}/`}</Typography>
        {`${meses[mesAtual]}`}
        <Typography color="textSecondary">{`/${meses[mesSeguinte]} ${anoAtual}`}</Typography>
      </div>
    );
  };

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/volumetria`
      );
      setDataBoard((prev) => ({
        ...prev,
        pendentes: data.naoPagos?.length,
        realizados: data.pagos?.length,
        ciclo: obterCiclo(),
        ...data.fundos,
      }));
      setPendingPayments(
        data.naoPagos.map((item, index) => ({ ...item, id: index }))
      );
      setPaidPayments(
        data.pagos.map((item, index) => ({ ...item, id: index }))
      );
    } catch (error) {
      console.error("Erro ao buscar pagamentos:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpen = async (modal, dados, eventoId, credited = false) => {
    console.log("teste", dados);
    if (modal === "markPaid") {
      alertCustom("Carregando...");
      setLoading(true);
      try {
        const { data } = await axios.put(
          `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/atualizar-status-pagamentos?eventoId=${eventoId}`,
          { status: true, movimentacao: dados, OID: dados.OID }
        );
        alertCustom("Pagamento marcado como pago com sucesso!");
        await fetchData();
      } catch (error) {
        console.error(`Erro ao atualizar status de pagamento:`, error);
        alertCustom("Erro ao marcar pagamento como pago.");
      }
      setLoading(false);
    } else if (modal === "detalhes") {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/buscar-organizador/${dados.OID}?token=${dados.key}`
        );
        setModalData({
          credited: credited,
          ...data.data,
          ...dados,
        });
        setOpen({
          detalhes: true,
        });
      } catch (error) {
        console.error(`Erro ao buscar dados para ${modal}:`, error);
      }
    }
  };

  const handleClose = (modal) => {
    setOpen((prev) => ({ ...prev, [modal]: false }));
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const formatarData = (data) => {
    const [ano, mes, dia] = data.split("-");
    return `${dia}/${mes}/${ano}`;
  };

  const baseColumns = [
    { field: "nome", headerName: "Nome", flex: 1 },
    { field: "evento", headerName: "Evento", flex: 1 },
    {
      field: "totalPagamentosComTaxa",
      headerName: "Valor total",
      flex: 1,
      renderCell: (params) =>
        `R$ ${Number(params.row.totalPagamentosComTaxa).toFixed(2)}`,
    },
    {
      field: "totalPagamentosSemTaxa",
      headerName: "Valor Pago",
      flex: 1,
      renderCell: (params) =>
        `R$ ${Number(params.row.totalPagamentosSemTaxa).toFixed(2)}`,
    },
    {
      field: "dataCobranca",
      headerName: "Data Cobrança",
      flex: 1,
      renderCell: (params) => formatarData(params.row.dataCobranca),
    },
    {
      field: "dataVencimento",
      headerName: "Data Vencimento",
      flex: 1,
      renderCell: (params) => formatarData(params.row.dataVencimento),
    },
    {
      field: "acoes",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => (
        <>
          <Tooltip title="Mais opções">
            <IconButton
              onClick={() => handleOpen("detalhes", params.row, null, true)}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  const columns = [
    { field: "nome", headerName: "Nome", flex: 1 },
    { field: "evento", headerName: "Evento", flex: 1 },
    {
      field: "totalPagamentosComTaxa",
      headerName: "Valor total",
      flex: 1,
      renderCell: (params) =>
        `R$ ${Number(params.row.totalPagamentosComTaxa).toFixed(2)}`,
    },
    {
      field: "totalPagamentosSemTaxa",
      headerName: "Valor a pagar",
      flex: 1,
      renderCell: (params) =>
        `R$ ${Number(params.row.totalPagamentosSemTaxa).toFixed(2)}`,
    },
    {
      field: "dataCobranca",
      headerName: "Data Cobrança",
      flex: 1,
      renderCell: (params) => formatarData(params.row.dataCobranca),
    },
    {
      field: "dataVencimento",
      headerName: "Data Vencimento",
      flex: 1,
      renderCell: (params) => {
        const dataAjustada = new Date(params.row.dataVencimento);
        dataAjustada.setDate(dataAjustada.getDate() + 1);

        const hoje = new Date();
        const emTempo = dataAjustada >= hoje;

        return (
          <Box display="flex" alignItems="center">
            {formatarData(params.row.dataVencimento)}
            <Box
              ml={1}
              width={12}
              height={12}
              borderRadius="50%"
              bgcolor={emTempo ? "green" : "red"}
            />
          </Box>
        );
      },
    },
    {
      field: "acoes",
      headerName: "Ações",
      flex: 1,
      renderCell: (params) => (
        <>
          <Tooltip title="Marcar como pago">
            <IconButton
              disabled={loading}
              onClick={() =>
                handleOpen("markPaid", params.row, params.row.eventoId)
              }
            >
              <DoneIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Mais opções">
            <IconButton onClick={() => handleOpen("detalhes", params.row)}>
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <StyledTabs value={tabIndex} onChange={handleTabChange}>
          <AntTab label="Pagamentos Pendentes" />
          <AntTab label="Pagamentos Pagos" />
        </StyledTabs>
      </Grid>{" "}
      <Grid item xs={12} md={12}>
        {tabIndex === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ height: 250, width: "100%" }}>
                <DataGrid
                  rows={pendingPayments}
                  columns={columns}
                  pageSize={5}
                />
              </div>
            </Grid>
          </Grid>
        )}
        {tabIndex === 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ height: 250, width: "100%" }}>
                <DataGrid
                  rows={paidPayments}
                  columns={baseColumns}
                  pageSize={5}
                />
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ModalDetalhes
        alertCustom={alertCustom}
        action={handleOpen}
        open={open.detalhes}
        onClose={() => handleClose("detalhes")}
        data={modalData}
      />
    </Grid>
  );
};

export default TabelaPagamentos;
