import React, { useState, useEffect } from "react";
import {
  Divider,
  Grid,
  Paper,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
import EditableCard from "./EditableCard";
import axios from "../../../utils/configAxios";
import DataAttemp from "../../../utils/DataAttemp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tour from "../Tools/Tour/";
import { useNavigate } from "react-router-dom";

const Onboarding = ({ alertCustom }) => {
  const DataManager = DataAttemp();
  const [formData, setFormData] = useState(null);
  const [reset, setReset] = useState(false);
  const navigate = useNavigate();

  const getDifferences = (obj1, obj2) => {
    const differences = {};
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        differences[key] = obj2[key];
      }
    });
    return differences;
  };

  const handleSave = async (updatedData, toPath, action = "cadastrar") => {
    const idOrganizador = JSON.parse(localStorage.organizador).id;
    const key = DataManager.userData?.auth?.key;
    const body = getDifferences(formData, updatedData);

    try {
      if (toPath === "contato") {
        const { agencia, banco, chavePix, conta, ...bodyForm } = body;
        await axios
          .put(
            `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/atualizar-contato-organizador/${idOrganizador}`,
            { organizador: bodyForm }
          )
          .then(() =>
            alertCustom("Informações de contato atualizadas com sucesso!")
          )
          .catch(() =>
            alertCustom("Erro ao atualizar informações de contato!")
          );
      } else if (toPath === "bancarios") {
        const { nome, contato, telefone, site, email, ...bodyForm } = body;
        if (action == "atualizar") {
          await axios
            .put(
              `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/atualizar-informacoes-deposito/${idOrganizador}?token=${key}`,
              { dadosBancarios: { id: formData.id, ...bodyForm } }
            )
            .then(() =>
              alertCustom("Informações de depósito atualizadas com sucesso!")
            )
            .catch(() =>
              alertCustom("Erro ao atualizar informações de depósito!")
            );
        } else if (action == "cadastrar") {
          await axios
            .post(
              `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/cadastrar-informacoes-deposito/${idOrganizador}`,
              { dadosBancarios: bodyForm }
            )
            .then(() =>
              alertCustom("Informações de depósito cadastradas com sucesso!")
            )
            .catch(() =>
              alertCustom("Erro ao cadastrar informações de depósito!")
            );
        }
      }
    } catch (error) {
      alertCustom(
        `Erro ao atualizar dados de ${
          toPath === "contato" ? "contato" : "deposito"
        } do organizador!`
      );
      console.error(
        `Erro ao atualizar dados de ${
          toPath === "contato" ? "contato" : "deposito"
        } do organizador:`,
        error
      );
    }
    await fetchData();
  };

  const fetchData = async () => {
    try {
      const idOrganizador = JSON.parse(localStorage.organizador).id;
      const key = DataManager.userData?.auth?.key;
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/buscar-organizador/${idOrganizador}?token=${key}`
      );
      const { organizador } = data.data;
      setFormData({
        banco: data.data.banco,
        agencia: data.data.agencia,
        conta: data.data.conta,
        chavePix: data.data.chavePix,
        nome: organizador.nome,
        contato: organizador.contato,
        telefone: organizador.telefone,
        site: organizador.site,
        email: organizador.email,
      });
    } catch (error) {
      console.error("Erro ao buscar organizador:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alertCustom("Copiado para a área de transferência!");
  };
  const handleReset = () => {
    setReset(true);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography variant="button" sx={{ fontWeight: 600, fontSize: "16px" }}>
          Configurações
        </Typography>

        <Paper
          variant="outlined"
          sx={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-evenly" }}
          >
            {formData && (
              <>
                <Grid item xs={12} md={3.5}>
                  <EditableCard
                    title="Informações de Contato"
                    data={formData}
                    path="contato"
                    fields={["nome", "contato", "telefone", "site"]}
                    handleSave={handleSave}
                  />
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" sx={{ mx: 2 }} />
                </Grid>{" "}
                <Grid item xs={12} md={3.5}>
                  <EditableCard
                    title="Dados Bancários"
                    data={formData}
                    path="bancarios"
                    fields={["banco", "agencia", "conta", "chavePix"]}
                    handleSave={handleSave}
                    alertCustom={alertCustom}
                  >
                    <Grid container spacing={2}>
                      {["banco", "agencia", "conta", "chavePix"].map(
                        (field) => (
                          <Grid item xs={12} key={field}>
                            <TextField
                              fullWidth
                              label={
                                field.charAt(0).toUpperCase() + field.slice(1)
                              }
                              value={formData[field] || ""}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        handleCopy(formData[field] || "")
                                      }
                                    >
                                      <ContentCopyIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </EditableCard>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" sx={{ mx: 2 }} />
                </Grid>
                <Grid item xs={12} md={3.5}>
                  <EditableCard
                    editable={false}
                    title="Informações do Organizador"
                    data={formData}
                    fields={["email"]}
                    handleSave={handleSave}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Grid>{" "}
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography
              variant="button"
              sx={{ fontWeight: 600, width: "100%", fontSize: "16px" }}
            >
              Dúvidas
            </Typography>
          </Grid>
          {reset && <Tour restartTour={reset} />}{" "}
          <Paper variant="outlined" sx={{ p: 2, width: "100%" }}>
            <Grid item xs={12} md={12} sx={{ height: "50px" }}>
              <Typography variant="body1" color="textSecondary">
                Caso tenha alguma dúvida, refaça o tour para auxiliar
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                onClick={handleReset}
              >
                Reexibir tour
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Onboarding;
