import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  IconButton,
  Grid,
  Box,
  Typography,
  Button,
  useMediaQuery,
  Paper,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { motion, useAnimation } from "framer-motion";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/configAxios";
import DataAttemp from "../../utils/DataAttemp";

const FormDialog = ({ open, onClose, alertCustom }) => {
  const navigate = useNavigate();
  const dataManager = DataAttemp();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    nome: "",
    contato: "",
    telefone: "",
    site: "",
    email: dataManager.userData?.auth.email, // Preencher com o email do usuário
  });
  const isMobile = useMediaQuery("(max-width:600px)");
  const controls = useAnimation();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/criar-solicitacao-inscricao`,
        {
          solicitacao: {
            email: dataManager?.userData?.auth.email,
            dataSolicitacao: new Date(),
            status: "PENDING",
            body: formData,
          },
        }
      );
      setSuccess(true);
      localStorage.clear();
      await controls.start({ opacity: 0, scale: 0.5 });
      await controls.start({ opacity: 1, scale: 1 });
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
      alertCustom(
        error.response?.data.message ??
          "Erro ao enviar os dados, tente novamente mais tarde..."
      );
    } finally {
      setLoading(false); // Certifique-se de definir o carregamento como falso após a tentativa
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={isMobile}
      maxWidth={"xs"}
      PaperProps={{
        sx: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        Formulário
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          height: "80vh",
          overflow: "auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        {!success ? (
          <>
            {loading ? (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={100} />
                <Typography variant="h6" color="textSecondary" mt={2}>
                  Enviando seus dados...
                </Typography>
              </Box>
            ) : (
              <Paper
                elevation={0}
                component="form"
                noValidate
                className="allow-sroll"
                sx={{ padding: "30px 0" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <div className="show-box">
                      <Typography variant="body" color="textSecondary">
                        Preencha as informações abaixo, entraremos em contato
                        para firmar a parceria para que você comece a vender o
                        quanto antes!
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      label="Nome do Organizador"
                      name="nome"
                      value={formData.nome}
                      onChange={handleInputChange}
                      variant="outlined"
                      required
                    />{" "}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      label="Nome do Contato"
                      name="contato"
                      value={formData.contato}
                      onChange={handleInputChange}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      label="Telefone"
                      name="telefone"
                      value={formData.telefone}
                      onChange={handleInputChange}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      label="Site"
                      name="site"
                      value={formData.site}
                      onChange={handleInputChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} textAlign="center">
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      endIcon={<ArrowForwardRoundedIcon />}
                    >
                      Enviar solicitação
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </>
        ) : (
          <Box
            sx={{
              textAlign: "center",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CheckCircleIcon color="success" sx={{ fontSize: 150 }} />
            <Typography variant="h6" color="textSecondary" mt={2}>
              Seus dados foram enviados com sucesso!
            </Typography>
            <Typography
              className="show-box"
              variant="body1"
              color="textSecondary"
              mt={2}
            >
              {`Um e-mail será enviado a ${formData.email} assim que seu cadastro for aprovado!`}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {success && (
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={() => navigate("/login")}
            sx={{ mt: 2, marginBottom: "10px" }}
          >
            Voltar ao início
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
