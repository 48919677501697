import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  MenuItem,
  Typography,
  TextField,
  Button,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  CardHeader,
} from "@mui/material";
//import Location from "../../components/Mapa/location";
import { LoadingButton } from "@mui/lab";
import axios from "../../utils/configAxios";
import { format } from "date-fns";
import DataAttemp from "../../utils/DataAttemp";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const EditProfileDialog = ({ open, onClose, alertCustom, acessType }) => {
  const navigate = useNavigate();
  const dataMaganer = DataAttemp();
  const estados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];
  const [isMobile, setIsMobile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [editEndereco, setEditEndereco] = useState({
    bairro: "",
    estado: "",
    cidade: "",
  });
  const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600); // Adjust this threshold as needed
    };

    handleResize(); // Call once to initialize state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const phoneMask = (value) => {
    if (!value) return "";
    if (value.length <= 15) {
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{2})(\d)/, "($1)$2");
      value = value.replace(/(\d)(\d{4})$/, "$1-$2");

      return value;
    } else {
      return value.slice(0, 15);
    }
  };

  const cpfMask = (value) => {
    if (!value) return "";
    if (value.length <= 14) {
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d)/, "$1.$2");
      value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      return value;
    } else {
      return value.slice(0, 14);
    }
  };

  const [editedProfileData, setEditedProfileData] = useState({
    ...dataMaganer.userData,
    cpf: dataMaganer.userData.auth.cpf,
    dataNasc: dataMaganer.userData.dataNascimento
      ? format(
          new Date(
            new Date(dataMaganer.userData.dataNascimento).getTime() +
              3 * 60 * 60 * 1000
          ),
          "yyyy-MM-dd"
        )
      : "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    const parts = editedProfileData.endereco.split(",");
    setEditEndereco({
      bairro: parts[0]?.trim() ?? "",
      cidade: parts[1]?.trim() ?? "",
      estado: parts[2]?.trim() ?? "",
    });
  }, [editedProfileData.endereco]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let valor = "";

    if (name === "telefone") {
      valor = phoneMask(value);
    } else if (name === "cpf") {
      valor = cpfMask(value);
    } else {
      valor = value;
    }

    setEditedProfileData((prevData) => ({
      ...prevData,
      [name]: valor,
    }));
  };

  // const handlePasswordChange = (e) => {
  //   const { name, value } = e.target;
  //   setEditedProfileData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  // const handleShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   try {
  //     const data = {
  //       email: editedProfileData.email,
  //     };
  //     const response = await axios.post(
  //       `${REACT_APP_URL_BIBLIOTECA_RUNNERS}:${`300${Number(dataManager.userData.id??0) % 5}`}/v1/user/recuperar`,
  //       data
  //     );
  //     alertCustom(response.data.message);
  //     if (response.data?.data) {
  //       // Handle success
  //     }
  //   } catch (error) {
  //     alertCustom(
  //       error.response?.data?.error ||
  //         error.response?.data?.message ||
  //         "Erro ao recuperar conta"
  //     );
  //   }
  // };

  const handleSave = async () => {
    try {
      setIsLoadingSave(true); // Set loading state to true

      const dataNascAdjusted = new Date(editedProfileData.dataNasc);
      dataNascAdjusted.setHours(dataNascAdjusted.getHours() + 3);

      // Check if the user is at least 16 years old
      const today = new Date();
      const sixteenYearsAgo = new Date(
        today.setFullYear(today.getFullYear() - 16)
      );
      if (dataNascAdjusted > sixteenYearsAgo) {
        alertCustom("A idade mínima é de 16 anos.");
        setIsLoadingSave(false); // Set loading state back to false
        return;
      }

      // Check if phone number is valid
      if (editedProfileData.telefone.length < 13) {
        alertCustom("O número de telefone informado é inválido.");
        setIsLoadingSave(false); // Set loading state back to false
        return;
      }

      const data = {
        ...editedProfileData,
        endereco: `${editEndereco.bairro}, ${editEndereco.cidade}, ${editEndereco.estado}`,
        auth: {
          ...dataMaganer.userData.auth,
          cpf: editedProfileData.cpf,
        },
        dataNasc: format(dataNascAdjusted, "dd/MM/yyyy"),
      };
      const response = await axios.put(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/atualizar-user-data?id=${dataMaganer.userData.id}&token=${dataMaganer.userData.auth.token}`,
        data
      );
      alertCustom("Alteração realizada com sucesso!");
      localStorage.removeItem("userData");
      localStorage.setItem("userData", JSON.stringify(response.data.data));
      navigate(-1);
    } catch (error) {
      console.log(error);
      alertCustom("Ocorreu um erro, favor, tente novamente mais tarde!");
    } finally {
      setIsLoadingSave(false); // Set loading state back to false after operation completes
    }
    onClose();
  };

  const handleChangePassword = async () => {
    try {
      setIsLoadingChangePassword(true); // Set loading state to true

      const { email } = editedProfileData.auth;
      const data = {
        email: email,
      };
      const response = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/recuperar`,
        data
      );
      alertCustom(response.data.message);
      onClose();
    } catch (error) {
      console.error(error);
      alertCustom("Ocorreu um erro ao tentar recuperar senha.");
    } finally {
      setIsLoadingChangePassword(false); // Set loading state back to false after operation completes
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth={"lg"}
      open={open}
      onClose={handleCancel}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <span>Editar Perfil</span>
        <IconButton onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "10px", justifyContent: "center" }}
        >
          {acessType === "complete" && (
            <Grid item xs={12} md={12}>
              <Typography
                variant="body1"
                color="textSecondary"
                className="show-box"
              >
                Ao informar seus dados está ciente que serão utlizados nos
                cadastros em eventos da plataforma e que possui idade suficiente
                conforme estabelecido nos{" "}
                <a
                  href="https://goianiarunners.com/termos"
                  className="simple-link"
                >
                  <Typography variant="body1">termos e condições</Typography>.
                </a>{" "}
                <Typography color="textSecondary" variant="body1">
                  O seu cadastro precisa estar completo para que ingresse em
                  qualquer evento da plataforma. Evite contratempos
                  atualizando-os sempre que possível!
                </Typography>
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={4}>
            <TextField
              label="Nome"
              variant="outlined"
              fullWidth
              name="nome"
              value={editedProfileData.nome}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Telefone"
              variant="outlined"
              fullWidth
              name="telefone"
              value={phoneMask(editedProfileData.telefone)}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={true}
              label="CPF"
              variant="outlined"
              fullWidth
              name="cpf"
              value={cpfMask(editedProfileData.cpf)}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Data de Nascimento"
              variant="outlined"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              name="dataNasc"
              value={editedProfileData.dataNasc}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="sexo">Sexo</InputLabel>
              <Select
                labelId="sexo"
                id="sexo"
                name="sexo"
                value={editedProfileData.sexo}
                label="Sexo"
                onChange={handleChange}
              >
                <MenuItem value="masculino">Masculino</MenuItem>
                <MenuItem value="feminino">Feminino</MenuItem>
                <MenuItem value="none">Não informar</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Bairro"
              id="bairro"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                maxLenght: 15,
              }}
              name="bairro"
              value={editEndereco.bairro}
              onInput={(e) =>
                setEditEndereco({ ...editEndereco, bairro: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Cidade"
              variant="outlined"
              id="cidade"
              fullWidth
              InputLabelProps={{
                maxLenght: 15,
              }}
              name="cidade"
              value={editEndereco.cidade}
              onInput={(e) =>
                setEditEndereco({ ...editEndereco, cidade: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="estado">Estado</InputLabel>
              <Select
                labelId="estado"
                id="estado"
                value={editEndereco.estado}
                label="Estado"
                onChange={(e) =>
                  setEditEndereco({
                    ...editEndereco,
                    estado: e.target.value,
                  })
                }
              >
                {estados.map((estado, index) => (
                  <MenuItem key={index} value={estado}>
                    {estado}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          spacing={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap-reverse",
          }}
        >
          <Grid item xs={12} md={3}>
            <Button fullWidth onClick={handleCancel}>
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <LoadingButton
              fullWidth
              disableElevation
              onClick={handleChangePassword}
              color="primary"
              sx={{ borderRadius: "50px" }}
              loading={isLoadingChangePassword} // Set the loading state
            >
              Mudar senha
            </LoadingButton>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={3}>
            <LoadingButton
              fullWidth
              disableElevation
              onClick={handleSave}
              variant="contained"
              color="primary"
              loading={isLoadingSave} // Set the loading state
            >
              Salvar
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditProfileDialog;
