import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  IconButton,
  Paper,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActionArea,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PercentIcon from "@mui/icons-material/Percent";
import AddIcon from "@mui/icons-material/Add";
import axios from "../../../utils/configAxios";

// Componente para listar as taxas existentes
const TaxList = ({ taxes, handleDialogOpen }) => {
  return (
    <Grid container spacing={2}>
      {taxes.map((tax, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Card
            elevation={0}
            sx={{ background: "rgba(0,0,0,0.02)", borderRadius: "8px" }}
          >
            <CardActionArea>
              <CardHeader
                avatar={
                  <PercentIcon
                    color={
                      tax.operacao === "percentual" ? "warning" : "secondary"
                    }
                  />
                }
                title={`Tipo: ${
                  tax.operacao === "percentual" ? "Percentual" : "Fixo"
                }`}
                subheader={`Valor: ${tax.taxa}${
                  tax.operacao === "percentual" ? "%" : " R$"
                }`}
              />
              <CardContent>
                <Typography variant="body2">{tax.descricao}</Typography>
                <Typography variant="body2">
                  Aplicação: {tax.aplicacao}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12} md={3}>
        <Card elevation={0} sx={{ height: "100%" }}>
          <CardActionArea
            onClick={handleDialogOpen}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(0,0,0,0.02)",
              height: "100%",
              borderRadius: "8px",
            }}
          >
            <CardContent sx={{ textAlign: "center" }}>
              <AddIcon color="secondary" fontSize="large" />
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

// Componente para o diálogo de configuração de taxa
const TaxConfigDialog = ({ open, onClose, onSave }) => {
  const [taxType, setTaxType] = useState("percentual"); // Percentual preferido
  const [taxValue, setTaxValue] = useState("");
  const [description, setDescription] = useState("");
  const [aplicacao, setAplicacao] = useState("");

  const handleInputChange = (e) => {
    const value = parseFloat(e.target.value);

    if (
      (taxType === "percentual" && value >= 0 && value <= 100) ||
      (taxType === "fixo" && value > 0 && value <= 100)
    ) {
      setTaxValue(value);
    }
  };

  const handleSave = async () => {
    if (taxType && taxValue >= 0 && description && aplicacao) {
      await onSave({
        operacao: taxType,
        taxa: taxValue,
        descricao: description,
        aplicacao,
      });
      setTaxType("percentual");
      setTaxValue("");
      setDescription("");
      setAplicacao("");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        Configuração de Taxa
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          select
          label="Tipo de Taxa"
          value={taxType}
          onChange={(e) => setTaxType(e.target.value)}
          fullWidth
          margin="dense"
        >
          <MenuItem value="percentual">Percentual</MenuItem>
          <MenuItem value="fixo">Fixo</MenuItem>
        </TextField>
        <TextField
          label="Valor"
          value={taxValue}
          onInput={handleInputChange}
          fullWidth
          margin="dense"
          type="number"
        />
        <TextField
          label="Descrição"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="dense"
        />
        <TextField
          select
          label="Aplicação"
          value={aplicacao}
          onChange={(e) => setAplicacao(e.target.value)}
          fullWidth
          margin="dense"
        >
          <MenuItem value="sistema">Sistema</MenuItem>
          <MenuItem value="eventos">Eventos</MenuItem>
        </TextField>
        <Typography
          className="show-box"
          variant="body2"
          color="textSecondary"
          sx={{ margin: "16px 0" }}
        >
          <Typography variant="body1">Atenção</Typography>
          Ao cadastrar uma taxa nova está ciente de que substituirá a taxa
          anterior para novos eventos!
          <Typography variant="body2">
            * A taxa anterior continua em vigor para os eventos que foram
            criados enquanto estava ativa
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Criar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Componente principal
const TaxManagement = ({ alertCustom }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [taxes, setTaxes] = useState([]);

  useEffect(() => {
    fetchTaxes();
  }, []);

  const fetchTaxes = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/listar-taxas`
      );
      setTaxes(data);
    } catch (error) {
      console.log(error);
      alertCustom("Erro ao listar taxas");
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleSaveTax = async (newTax) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/cadastrar-taxa`,
        newTax
      );
      await fetchTaxes();
      handleDialogClose();
    } catch (error) {
      console.log(error);
      alertCustom("Erro ao salvar taxa");
    }
  };

  return (
    <>
      <Typography variant="button" sx={{ fontWeight: 600 }}>
        Gestão de Taxas
      </Typography>

      <Paper variant="outlined" sx={{ padding: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <TaxList taxes={taxes} handleDialogOpen={handleDialogOpen} />
          </Grid>
        </Grid>

        <TaxConfigDialog
          open={openDialog}
          onClose={handleDialogClose}
          onSave={handleSaveTax}
        />
      </Paper>
    </>
  );
};

export default TaxManagement;
