import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useDropzone } from "react-dropzone";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import axios from "../../../utils/configAxios";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import {
  Typography,
  Tooltip,
  CardHeader,
  Divider,
  Avatar,
  CardMedia,
  FormControlLabel,
  Switch,
} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Paper from "@mui/material/Paper";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import NewCategoria from "./NewCategoria";
import RenderCategoria from "./RenderCategoria";
import SimplePaper from "../../../components/Paper";
import UploadDialog from "./UploadDialog";
import BannerDefault from "../../../images/banner.png";
import PerfilDefault from "../../../images/perfil.png";
import SimpleList from "../../../components/List/";
import AlertDialog from "./AlertDialog"; //
import Autocomplete from "../../../components/AutoComplete/";
import AddIcon from "@mui/icons-material/Add";
import ProductCards from "./ProdutosCards";
import DialogCupons from "./CupomPromocional";
import ClassificacaoIndicativa from "./ClassificacaoIndicativa";
import { useNavigate } from "react-router-dom";
const REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM;

const maskHorario = (value) => {
  if (!value) return "";

  value = value.replace(/\D/g, "");

  if (value.length <= 4) {
    let temp = value.padEnd(4, "0");
    let tt = value.split("");
    if (temp > 2359) {
      tt.pop();
      value = tt.join();
    }
    value = value.replace(/(\d{2})(\d)/, "$1:$2");
  } else {
    value = value.slice(0, 4);
  }

  return value;
};

export default function CriarEvento() {
  const [state, setState] = useState({
    open: false,
    Transition: Slide,
    message: "",
  });
  const navigate = useNavigate();
  const [stateAlert, setStateAlert] = useState({
    open: false,
    message: "",
    buttonLabel: "",
    targetRoute: "/dash/dashboard",
  });

  const [categoriaOptions, setCategoriaOptions] = useState([]);
  const [tabelasProdutos, setTabelasProdutos] = useState([]);
  const [formularios, setFormularios] = useState([]);
  const [organizadores, setOrganizadores] = useState([]);
  //const [location, setLocation] = useState("");
  const [uploadFilesExtras, setUploadFilesExtras] = useState([]);
  const [open, setOpen] = useState(false);
  const [cuponsPromocionais, setCuponsPromocionais] = useState([]);
  const [eventoData, setEventoData] = useState({
    status: true,
    titulo: "",
    subTitulo: "",
    regulamento: "",
    bannerImage: "",
    perfilImage: "",
    tabelaPreco: "",
    data: "",
    dataFinal: "",
    dataEvento: "",
    horario: "",
    percurso: "",
    formularioId: "",
    cupons: [],
    localizacao: "",
    senha: gerarSenha(),
    tipo: "gratuito",
    numVagas: 0,
    horarioInscricoes: "",
    classificacao: "livre",
  });

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  useEffect(() => {
    try {
      axios
        .get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/listar-nome-categorias`
        )
        .then((response) => {
          setCategoriaOptions(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/produto/listar-tabelas`
        )
        .then((response) => {
          setTabelasProdutos(response.data);
          response.data.length === 0 &&
            setStateAlert((prev) => ({
              ...prev,
              open: true,
              message:
                "Você precisa cadastrar ao menos um produto / kit para começar seu evento",
              buttonLabel: "Cadastre agora mesmo",
              targetRoute: "/dash/produtos",
            }));
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(`${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/formulario/listar`)
        .then((response) => {
          setFormularios(response.data);
          response.data.length === 0 &&
            setStateAlert((prev) => ({
              ...prev,
              open: true,
              message:
                "Você precisa cadastrar ao menos um formulário para começar seu evento",
              buttonLabel: "Cadastre agora mesmo",
              targetRoute: "/dash/formularios",
            }));
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/organizadores/listar-organizadores`
        )
        .then((response) => setOrganizadores(response.data))
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      alertCustom(
        "Erro ao buscar dados, verifique sua conexão antes de prosseguir!"
      );
    }
  }, []);

  const alertCustom = (message) => {
    setState({
      open: true,
      Transition: SlideTransition,
      message,
    });
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  const [loading, setLoading] = useState(false);
  const [perfilLoading, setPerfilLoading] = useState(PerfilDefault);
  const [bannerLoading, setBannerLoading] = useState(BannerDefault);
  const [selectedFormulario, setSelectedFormulario] = useState("");
  const [selectedOrganizador, setSelectedOrganizador] = useState("");
  const [arquivos, setArquivos] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  function gerarSenha() {
    const caracteres =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let senha = "";
    for (let i = 0; i < 10; i++) {
      const indice = Math.floor(Math.random() * caracteres.length);
      senha += caracteres.charAt(indice);
    }
    return senha;
  }

  const handleUploadFilesExtras = async () => {
    try {
      if (uploadFilesExtras.length === 0) {
        return [];
      }
      const urls = [];

      for (const file of uploadFilesExtras) {
        const formData = new FormData();
        const newName = `${file.titulo}.${file.type}`;
        const novoArquivo = new File([file.file], newName, {
          type: file.file.type,
        });
        formData.append("fotos", novoArquivo);
        try {
          const response = await axios.post(
            `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/upload/${file.campo}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          urls.push({
            titulo: file.titulo,
            campo: file.campo,
            src: response.data.fileNames[0],
            descricao: file.descricao,
            link: file.link,
          });
        } catch (err) {
          throw new Error(err.response.data.error);
        }
      }

      alertCustom("Arquivos adicionais enviados com sucesso!");
      return urls;
    } catch (error) {
      alertCustom("Erro no envio dos arquivos adicionais!");
      throw new Error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (arquivos.length === 0) {
      alertCustom("Nenhuma imagem selecionada!");
      return;
    }

    // Validação dos campos obrigatórios
    const requiredFields = [
      { field: eventoData.titulo, name: "Título" },
      { field: eventoData.subTitulo, name: "Subtítulo" },
      { field: eventoData.regulamento, name: "Regulamento" },
      { field: eventoData.data, name: "Data de início" },
      { field: eventoData.dataFinal, name: "Data final" },
      { field: eventoData.dataEvento, name: "Data do evento" },
      { field: eventoData.horario, name: "Horário" },
      { field: eventoData.horarioInscricoes, name: "Horário das inscrições" },
      { field: eventoData.tabelaPreco, name: "Tabela de preço" },
      { field: selectedFormulario, name: "Formulário" },
      { field: selectedOrganizador, name: "Organizador" },
      { field: eventoData.cupons, name: "Cupom promocional" },
      { field: categorias.length > 0, name: "Categoria" },
    ];

    for (const { field, name } of requiredFields) {
      if (!field) {
        alertCustom(`Por favor, preencha o campo obrigatório: ${name}!`);
        return;
      }
    }

    try {
      let fotoBanner = "";
      let fotoPerfil = "";

      const urls = await handleUploadFilesExtras();

      if (arquivos.length > 0) {
        const formData = new FormData();
        arquivos.forEach((file) => {
          const novoNome = `${file.inputName}-${file.file.name}`;
          const novoArquivo = new File([file.file], novoNome, {
            type: file.file.type,
          });
          formData.append("fotos", novoArquivo);
        });

        try {
          const response = await axios.post(
            `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/upload/Eventos`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          alertCustom(response.data.message);

          const files = response.data.fileNames;

          for (const file of files) {
            if (file.includes("Banner")) {
              fotoBanner = file;
            } else if (file.includes("Perfil")) {
              fotoPerfil = file;
            }
          }
        } catch (error) {
          console.error("Erro:", error);
          throw new Error(error.response.data.error);
        }
      }

      const data = {
        evento: {
          ...eventoData,
          bannerImage: fotoBanner,
          perfilImage: fotoPerfil,
          formularioId: selectedFormulario.id,
          categoriaName: groupCategoria.title,
          cupons: cuponsPromocionais,
          images: urls,
          senha: eventoData.senha || gerarSenha(),
          tipo: eventoData.tipo,
        },
        categorias: categorias,
        organizador: selectedOrganizador,
      };

      const response2 = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/cadastrar`,
        data
      );

      alertCustom(response2.data.message);
      navigate("/dash/dashboard");
    } catch (error) {
      alertCustom(
        `Erro no cadastro, ${error
          .toString()
          .toLowerCase()
          .replaceAll("error:", "")}!`
      );
      console.error("Erro:", error);
    }
  };

  const onDrop = (acceptedFiles, inputName) => {
    console.log(acceptedFiles);
    const filteredFiles = arquivos.filter(
      (file) => file.inputName !== inputName
    );

    if (inputName === "Perfil") {
      setPerfilLoading(URL.createObjectURL(acceptedFiles[0]));
    } else if (inputName === "Banner") {
      setBannerLoading(URL.createObjectURL(acceptedFiles[0]));
    }

    setArquivos([
      ...(filteredFiles ? filteredFiles : {}),
      ...Object.values(acceptedFiles).map((file) => ({ inputName, file })),
    ]);
  };

  // const dropzonePerfil = useDropzone({
  //   onDrop: (acceptedFiles) => onDrop(acceptedFiles, "Perfil"),
  //   onDragEnter: () => setIsHovered(true),
  //   onDragLeave: () => setIsHovered(false),
  //   onDropAccepted: () => setIsHovered(false),
  //   onDropRejected: () => setIsHovered(false),
  // });

  // const dropzoneBanner = useDropzone({
  //   onDrop: (acceptedFiles) => onDrop(acceptedFiles, "Banner"),
  //   onDragEnter: () => setIsHovered(true),
  //   onDragLeave: () => setIsHovered(false),
  //   onDropAccepted: () => setIsHovered(false),
  //   onDropRejected: () => setIsHovered(false),
  // });

  // const {
  //   getRootProps: getPerfilRootProps,
  //   getInputProps: getPerfilInputProps,
  // } = dropzonePerfil;

  // const {
  //   getRootProps: getBannerRootProps,
  //   getInputProps: getBannerInputProps,
  // } = dropzoneBanner;

  const handleDelete = (file) => {
    const filteredFiles = arquivos.filter(
      (f) => f.inputName !== file.inputName
    );
    setArquivos(filteredFiles);

    if (file.inputName === "Perfil") {
      setPerfilLoading(PerfilDefault);
    } else if (file.inputName === "Banner") {
      setBannerLoading(BannerDefault);
    }
  };

  const [categoriaModalOpen, setCategoriaModalOpen] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [groupCategoria, setGroupCategoria] = useState("");
  const [categoriaAtual, setCategoriaAtual] = useState(null);

  useEffect(() => {
    if (groupCategoria && !groupCategoria.created) {
      axios
        .get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/evento/listar-categorias/${groupCategoria.title}`
        )
        .then((response) => {
          const categoriasData = response.data;

          if (Array.isArray(categoriasData)) {
            setCategorias(
              categoriasData.map((categoria) => ({
                valorIngresso: categoria.valorIngresso || 0,
                numVagasDisponiveis: categoria.numVagas,
                numVagas: categoria.numVagas,
                titulo: categoria.titulo,
                criterio: categoria.criterio,
                grupo: categoria.grupo,
              }))
            );
          } else {
            console.error(
              "A resposta do servidor não é um array:",
              categoriasData
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCategorias([]);
    }
  }, [groupCategoria]);

  const handleSalvarCategoria = (novaCategoria, update, categoriaToUpdate) => {
    if (update) {
      const index = categorias.indexOf(categoriaToUpdate);
      categorias[index] = novaCategoria;
      setCategorias(categorias);
    } else {
      setCategorias([...categorias, novaCategoria]);
    }

    setCategoriaAtual(null);
    setCategoriaModalOpen(false);
  };

  const handleEditarCategoria = (categoria) => {
    setCategoriaAtual(categoria);
    setCategoriaModalOpen(true);
  };

  const handleDeleteCategoria = (categoriaToDelete) => {
    const updatedCategorias = categorias.filter(
      (categoria) => categoria !== categoriaToDelete
    );
    setCategorias(updatedCategorias);
    setCategoriaModalOpen(false);
  };
  const [arquivosToSend, setArquivosToSend] = useState([]);

  const deleteArquivo = (index) => {
    const newFiles = [...uploadFilesExtras];
    newFiles.splice(index, 1);
    setUploadFilesExtras(newFiles);
  };
  const perfilRef = useRef(null);
  const bannerRef = useRef(null);
  return (
    <Grid container style={{ position: "relative" }}>
      <input
        ref={perfilRef}
        id="perfil-upload"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          const files = e.target.files;
          if (files.length > 0) {
            onDrop(files, "Perfil");
          }
        }}
      />

      <input
        ref={bannerRef}
        id="banner-upload"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          const files = e.target.files;
          if (files.length > 0) {
            onDrop(files, "Banner");
          }
        }}
      />

      {localStorage.acessType === "@manager" && stateAlert.open && (
        <AlertDialog
          open={stateAlert.open}
          message={stateAlert.message}
          buttonLabel={stateAlert.buttonLabel}
          targetRoute={stateAlert.targetRoute}
        />
      )}

      <Snackbar
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        message={state.message}
      />
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "start",
          position: "relative",
        }}
      >
        <CardMedia
          className="tour_14"
          component="img"
          sx={{
            height: "auto",
            minHeight: "150px",
            width: "100%",
            borderRadius: "8px",
            objectFit: "cover",
          }}
          image={bannerLoading}
          elevation={0}
        />
        <div
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          <ClassificacaoIndicativa
            classificacao={eventoData.classificacao}
            setClassificacao={(classificacao) =>
              setEventoData({
                ...eventoData,
                classificacao: classificacao,
              })
            }
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: -5,
            right: 0,
          }}
        >
          <Button
            disableElevation
            variant="contained"
            size="small"
            className="no-round tour_15"
            onClick={() =>
              setEventoData({ ...eventoData, status: !eventoData.status })
            }
            color={eventoData.status ? "success" : "secondary"}
            sx={{ marginTop: "1px", borderRadius: "0 0 5px 5px" }}
          >
            {eventoData.status ? "Ativo" : "Inativo"}
          </Button>
          <Tooltip title="Adicionar Banner">
            <IconButton onClick={() => bannerRef.current.click()} name="banner">
              <AddPhotoAlternateIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            height: "150px",
            width: "150px",
            display: "flex",
            justifyContent: "end",
            alignItems: "start",
            position: "absolute",
            left: "20px",
            bottom: "-20px",
          }}
        >
          <img
            src={perfilLoading}
            alt="Perfil"
            elevation={1}
            style={{
              borderRadius: "5px",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
            }}
          />
          <Tooltip title="Adicionar imagem de perfil">
            <IconButton
              style={{ position: "absolute" }}
              onClick={() => perfilRef.current.click()}
              name="perfil"
            >
              <AddPhotoAlternateIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <div
        className="arquives"
        style={{
          display: "flex",
          justifyContent: "end",
          overflow: "hidden",
          width: "100%",
          minHeight: "60px",
        }}
      >
        {arquivos.map((file, index) => (
          <Chip
            style={{
              backgroundColor: "transparent",
              maxWidth: "calc(25% - 5px)",
              margin: "0px 0px 5px 5px",
              borderRadius: " 0 0  10px 10px",
            }}
            key={index}
            label={file.file.name}
            onDelete={() => handleDelete(file)}
          />
        ))}
      </div>

      <Grid item xs={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="body1"
              color="textSecondary"
              className="show-box"
            >
              Atenção: Programe seus eventos considerando que a transferência
              pode ser efetuada em até 7 dias após o término das inscrições
            </Typography>
          </Grid>{" "}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Nome do evento"
              variant="standard"
              value={eventoData.titulo}
              onChange={(e) =>
                setEventoData({ ...eventoData, titulo: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              id="standard-basic"
              label="Subtitulo do evento"
              variant="standard"
              value={eventoData.subTitulo}
              onChange={(e) =>
                setEventoData({ ...eventoData, subTitulo: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="date"
              label="Inscrições (Início)"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={eventoData.data}
              onChange={(e) => {
                const data = new Date(e.target.value);
                const dataFinal = eventoData.dataFinal
                  ? new Date(eventoData.dataFinal)
                  : null;
                const dataEvento = eventoData.dataEvento
                  ? new Date(eventoData.dataEvento)
                  : null;

                if (
                  (!dataFinal || data.getTime() < dataFinal.getTime()) &&
                  (!dataEvento || data.getTime() < dataEvento.getTime())
                ) {
                  setEventoData({ ...eventoData, data: e.target.value });
                } else {
                  alertCustom(
                    "A data de início das inscrições deve ser inferior à data final das inscrições e à data de realização do evento!"
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="date"
              label="Inscrições (Final)"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={eventoData.dataFinal}
              onChange={(e) => {
                const dataFinal = new Date(e.target.value);
                const dataInicio = eventoData.data
                  ? new Date(eventoData.data)
                  : null;
                const dataEvento = eventoData.dataEvento
                  ? new Date(eventoData.dataEvento)
                  : null;

                if (
                  (!dataInicio || dataFinal.getTime() > dataInicio.getTime()) &&
                  (!dataEvento || dataFinal.getTime() < dataEvento.getTime())
                ) {
                  setEventoData({ ...eventoData, dataFinal: e.target.value });
                } else {
                  alertCustom(
                    "A data final das inscrições deve ser posterior à data de início e inferior à data de realização do evento!"
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              type="date"
              label="Data realização evento"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={eventoData.dataEvento}
              onChange={(e) => {
                const dataEvento = new Date(e.target.value);
                const dataInicio = eventoData.data
                  ? new Date(eventoData.data)
                  : null;
                const dataFinal = eventoData.dataFinal
                  ? new Date(eventoData.dataFinal)
                  : null;

                if (
                  (!dataInicio ||
                    dataEvento.getTime() > dataInicio.getTime()) &&
                  (!dataFinal || dataEvento.getTime() > dataFinal.getTime())
                ) {
                  // Verificação da distância de 7 dias entre a data final e a data do evento
                  if (dataFinal) {
                    const diffTime = dataEvento.getTime() - dataFinal.getTime();
                    const diffDays = diffTime / (1000 * 60 * 60 * 24); // Converter para dias

                    if (diffDays < 7) {
                      alertCustom(
                        "A data do evento deve ser pelo menos 7 dias após a data final das inscrições!"
                      );
                      return;
                    }
                  }

                  setEventoData({ ...eventoData, dataEvento: e.target.value });
                } else {
                  alertCustom(
                    "A data de realização do evento deve ser superior ao período de inscrições!"
                  );
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Largada"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={maskHorario(eventoData.horario)}
              onChange={(e) =>
                setEventoData({
                  ...eventoData,
                  horario: maskHorario(e.target.value),
                })
              }
              type="text"
              inputProps={{
                pattern: "([01]?[0-9]|2[0-3]):[0-5][0-9]", // Apenas permite valores de hora válidos
                placeholder: "HH:mm",
              }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Horário das inscrições"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={maskHorario(eventoData.horarioInscricoes)}
              onChange={(e) =>
                setEventoData({
                  ...eventoData,
                  horarioInscricoes: maskHorario(e.target.value),
                })
              }
              type="text"
              inputProps={{
                placeholder: "HH:mm",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} className="tour_16">
            <FormControl fullWidth>
              <InputLabel className="input-type" id="formulario-label">
                Formulário
              </InputLabel>
              <Select
                labelId="formulario-label"
                id="formulario"
                value={selectedFormulario}
                label="Formulário"
                onChange={(e) => setSelectedFormulario(e.target.value)}
              >
                {formularios.map((item, index) => (
                  <MenuItem key={index} value={item} title={item.titulo}>
                    {item.nome}
                  </MenuItem>
                )) || <MenuItem>Não há dados cadastrados</MenuItem>}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel className="input-type" id="organizador-label">
                Organizador
              </InputLabel>
              <Select
                labelId="organizador-label"
                id="organizador"
                value={selectedOrganizador}
                label="Organizador"
                onChange={(e) => setSelectedOrganizador(e.target.value)}
              >
                {organizadores.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item.nome}
                  </MenuItem>
                )) || <MenuItem>Não há dados cadastrados</MenuItem>}
              </Select>
            </FormControl>
          </Grid>{" "}
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              id="outlined-mult
              outlined-static"
              value={eventoData.localizacao}
              label="Localização"
              placeholder="Informe a localização do evento"
              onInput={(e) =>
                setEventoData({ ...eventoData, localizacao: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              type="number"
              id="outlined-mult
              outlined-static"
              value={eventoData.numVagas}
              label="Número de vagas"
              placeholder="Informe número de vagas"
              onInput={(e) =>
                setEventoData({ ...eventoData, numVagas: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel className="input-type" id="type-label">
                Tipo de evento
              </InputLabel>
              <Select
                labelId="type-label"
                id="type"
                value={eventoData.tipo}
                label="Tipo de evento"
                onChange={(e) =>
                  setEventoData({ ...eventoData, tipo: e.target.value })
                }
              >
                <MenuItem key={1} value={"pago"}>
                  Pago
                </MenuItem>
                <MenuItem key={2} value={"gratuito"}>
                  Gratuito
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel className="input-type" id="configurar-valores-label">
                Configurar Kits
              </InputLabel>
              <Select
                labelId="configurar-valores-label"
                id="configurar-valores"
                value={eventoData.tabelaPreco}
                label="Configurar valores"
                onChange={(e) =>
                  setEventoData({ ...eventoData, tabelaPreco: e.target.value })
                }
              >
                {tabelasProdutos.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                )) || <MenuItem>Não há dados cadastrados</MenuItem>}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              id="outlined-mult
              outlined-static"
              value={eventoData.senha}
              label="Senha do Evento"
              placeholder="Informe uma senha para o evento"
              onInput={(e) =>
                setEventoData({ ...eventoData, senha: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <DialogCupons
              alertCustom={alertCustom}
              setCuponsPromocionais={setCuponsPromocionais}
              cuponsPromocionais={cuponsPromocionais}
            />
          </Grid>
          {eventoData && eventoData.tabelaPreco && (
            <Grid item xs={12} md={12}>
              <ProductCards tabela={eventoData.tabelaPreco} />
            </Grid>
          )}
          <Grid item xs={12} md={12} className="tour_17">
            <TextField
              fullWidth
              id="outlined-mult
              outlined-static"
              value={eventoData.regulamento}
              label="Regulamento"
              placeholder="Adicione tags usando '#' para facilitar a pesquisa do evento, exemplo: '#CorridaAcimaDos5km' "
              multiline
              rows={12}
              onInput={(e) =>
                setEventoData({ ...eventoData, regulamento: e.target.value })
              }
            />
          </Grid>{" "}
          <Grid item xs={12} md={12}>
            <Paper elevation={0} variant="outlined" sx={{ padding: 2 }}>
              <Grid container sx={{ justifyContent: "end" }}>
                <Grid item md={12} xs={12}>
                  <Typography variant="h6">
                    Upload de arquivos adicionais
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  {uploadFilesExtras && (
                    <SimpleList
                      arquivos={uploadFilesExtras}
                      clickFunction={handleOpenDialog}
                      deleteArquivo={deleteArquivo}
                    />
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <CardHeader
                    className="show-box"
                    avatar={<ReportProblemIcon fontSize="small" />}
                    title={
                      <Typography color="textSecondary" variant="body2">
                        Certifique-se quanto ao formato dos arquivos, procure
                        usar pdf's para regulamento e png, jpeg, jpg's para
                        imagens de circuito e kits!
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper elevation={0} variant="outlined" sx={{ padding: 2 }}>
              <Grid
                container
                xs={12}
                md={12}
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Typography variant="h6" xs={12} md={12}>
                    Configurar Categorias
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={groupCategoria ? 3 : 12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    padding: groupCategoria ? 0 : "30px",
                  }}
                >
                  <div style={{ minWidth: "280px" }} className="tour_18">
                    <Autocomplete
                      placeholder="Pesquise ou digite uma nova"
                      options={categoriaOptions.map((categoria) => ({
                        title: categoria,
                      }))}
                      setValue={setGroupCategoria}
                      value={groupCategoria}
                      label={"Grupo de categorias"}
                      variant="outlined"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={1}>
                    {categorias &&
                      categorias.map((item, index) => (
                        <Grid item xs={12} md={3} key={index}>
                          <SimplePaper
                            edit={true}
                            setFunction={handleEditarCategoria}
                            data={item}
                          />
                        </Grid>
                      ))}
                    {groupCategoria && (
                      <Grid item xs={12} md={3}>
                        <SimplePaper
                          edit={true}
                          setFunction={() => {
                            setCategoriaAtual(null);
                            setCategoriaModalOpen(true);
                          }}
                          icon={
                            <Avatar>
                              <AddIcon />
                            </Avatar>
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                  <CardHeader
                    className="show-box"
                    avatar={<ReportProblemIcon fontSize="small" />}
                    title={
                      <Typography color="textSecondary" variant="body2">
                        Você pode selecionar e editar um grupo de categorias
                        existente ou criar um novo grupo informando um novo
                        nome, desde que não esteja previamente cadastrado!
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              {/*modais*/}
              {categoriaAtual ? (
                <RenderCategoria
                  nameGroup={groupCategoria?.title || ""}
                  alertCustom={alertCustom}
                  open={categoriaModalOpen}
                  onClose={() => setCategoriaModalOpen(false)}
                  categoriaEditar={categoriaAtual}
                  onSave={handleSalvarCategoria}
                  onDelete={handleDeleteCategoria}
                />
              ) : (
                <NewCategoria
                  nameGroup={groupCategoria?.title || ""}
                  alertCustom={alertCustom}
                  open={categoriaModalOpen}
                  onClose={() => setCategoriaModalOpen(false)}
                  onSave={handleSalvarCategoria}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container sx={{ display: "flex", justifyContent: "end" }}>
              <Grid item xs={12} md={3}>
                <LoadingButton
                  fullWidth
                  disableElevation
                  type="submit"
                  onClick={handleSubmit}
                  loading={loading}
                  variant="contained"
                >
                  Criar novo evento
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <UploadDialog
            setUploadFilesExtras={setUploadFilesExtras}
            alertCustom={alertCustom}
            handleCloseDialog={handleCloseDialog}
            open={open}
            setEventoData={setEventoData}
            eventoData={eventoData}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
