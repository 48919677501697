import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavegationBar/";
import FeedEventos from "../../components/Feed/Eventos";
import image from "../../components/Icons/run.png";
import Teste from "../../images/ban2.png";
import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid } from "@mui/material";
import EventoModal from "./ModalEvento";
import Footer from "../../components/Footer";
import CadReminderDialog from "./cadReminderDialog";
import MarcaAg from "../../images/marcaAg.png";
import axios from "axios";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

function Home({ alertCustom, userData }) {
  const params = useParams();
  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [evento, setEvento] = useState(null);
  const [dadosCarrossel, setDadosCarrossel] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/config/listar-banners-image`
        );
        setDadosCarrossel(response.data.urls);
      } catch (error) {
        console.error("Erro ao carregar conteúdo do site:", error);
        alertCustom("Erro ao carregar conteúdo do site!");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!params.evento) {
      closeModal();
    }
  }, [window.location.href]);

  const closeModal = () => {
    setIsModalOpen(false);
    setEvento(null);
  };

  return (
    <>
      {" "}
      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          background: `url(${MarcaAg})`,
        }}
      >
        <Grid
          container
          sx={{
            paddingTop: { md: "5px", xs: "16px" },
            maxWidth: { md: "1210px", xs: "100vw" },
            width: "calc(100vw - 5px)",
          }}
        >
          <NavBar contrast={true} />
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <FeedEventos
                  dadosCarrossel={dadosCarrossel}
                  eventoShow={params.evento}
                  setEvento={setEvento}
                  setIsModalOpen={setIsModalOpen}
                  alertCustom={alertCustom}
                  setLoadingOneEvent={setLoading}
                />
                {params.evento === "firstAcess" && <CadReminderDialog />}
              </Grid>
            </Grid>
          </Grid>
          {evento && (
            <EventoModal
              loading={loading}
              EventoData={evento}
              alertCustom={alertCustom}
              handleClose={closeModal}
              isModalOpen={isModalOpen}
            />
          )}
        </Grid>
      </div>
      <Footer />
    </>
  );
}

export default Home;
