import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import axios from "../../../../utils/configAxios"; // Ajuste a importação conforme necessário

const { REACT_APP_URL_BIBLIOTECA_RUNNERS } = process.env;

const NumeroVagasDialog = ({
  idEvento,
  alertCustom,
  setEventoToPut,
  eventoToPut,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numeroVagas, setNumeroVagas] = useState("");
  const [vagasRestantes, setVagasRestantes] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNumeroVagas("");
  };

  const handleConfirm = async (flag = 1) => {
    try {
      if (!numeroVagas) {
        return;
      }
      setLoading(true);

      const response = await axios.put(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/atualizar-lote?idEvento=${idEvento}`,
        {
          lote: {
            vagasAdicionais: Number(numeroVagas) * flag,
          },
        }
      );
      const { data } = response;
      setEventoToPut({ ...eventoToPut, numVagas: data.data.vagas });
      alertCustom(
        `${flag === 1 ? "Novo lote" : "Decremento"} de ${numeroVagas} vagas ${
          flag === 1 ? "liberado" : "realizado"
        }!`
      );
    } catch (error) {
      alertCustom(
        `Ocorreu um erro ao ${
          flag === 1 ? "liberar novo lote" : "decrementar lote"
        }, tente novamente mais tarde!`
      );
      console.error("Erro ao atualizar o lote:", error);
    }
    setLoading(false);
    handleClose();
  };

  const predefinedVagas = [50, 100, 200, 500];

  return (
    <div>
      <Button
        disableElevation
        fullWidth
        variant="contained"
        color="success"
        onClick={handleClickOpen}
        sx={{ height: 40 }}
      >
        Adicionar Novo Lote
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Lote</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="numeroVagas"
            label="Número de Vagas"
            type="number"
            fullWidth
            value={numeroVagas}
            onChange={(e) => setNumeroVagas(e.target.value)}
          />
          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            {predefinedVagas.map((vagas) => (
              <Chip
                key={vagas}
                label={vagas + " vagas"}
                onClick={() => setNumeroVagas(String(vagas))}
              />
            ))}
          </Stack>
          {vagasRestantes !== null && (
            <div style={{ marginTop: "20px" }}>
              Vagas restantes: {vagasRestantes}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleConfirm(-1)} color="primary">
            Decrementar
          </Button>
          <Button
            disabled={loading}
            onClick={() => handleConfirm(1)}
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NumeroVagasDialog;
