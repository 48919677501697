import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "../../../utils/configAxios";

const { REACT_APP_URL_BIBLIOTECA_RUNNERS } = process.env;

const PdfUploader = ({ alertCustom, data }) => {
  const [loading, setLoading] = useState(false);
  const [notaFiscalExistente, setNotaFiscalExistente] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const buscarNotaFiscal = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/buscar-nota-fiscal`,
          {
            params: {
              OID: data.OID,
              eventoId: data.eventoId,
            },
          }
        );

        if (response.data && response.data.anexo) {
          setNotaFiscalExistente(response.data.anexo);
        }
      } catch (error) {
        console.error("Erro ao buscar a nota fiscal:", error);
        alertCustom("Erro ao buscar a nota fiscal!");
      }
    };

    buscarNotaFiscal();
  }, [data.OID, data.eventoId]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile || selectedFile.type !== "application/pdf") {
      alertCustom("Por favor, selecione um arquivo PDF.");
      return;
    }

    setFile(selectedFile);
    handleFileUpload(selectedFile); // Inicia o upload imediatamente após a seleção
  };

  const handleFileUpload = async (file) => {
    if (!file) return;

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("fotos", file, file.name);

      // Primeiro POST: Upload do arquivo PDF
      const uploadResponse = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/upload/NFE`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const uploadedFileName = uploadResponse.data.fileNames[0];

      // Segundo POST: Anexar o nome do arquivo ao evento (anexar nota fiscal)
      await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/anexar-nota-fiscal`,
        {
          nomeArquivo: uploadedFileName,
          OID: data.OID,
          eventoId: data.eventoId,
        }
      );

      alertCustom("Arquivo PDF enviado e nome anexado com sucesso!");
      setNotaFiscalExistente(uploadedFileName); // Atualizar estado para refletir o novo upload
    } catch (error) {
      console.error("Erro ao enviar o arquivo:", error);
      alertCustom("Erro ao enviar o arquivo!");
    }

    setLoading(false);
  };

  return (
    <>
      {notaFiscalExistente ? (
        <>
          <Button
            variant="contained"
            color="warning"
            sx={{ borderRadius: "50px", color: "#fff" }}
            disableElevation
            onClick={() =>
              window.open(
                `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/images?file=NFE/${notaFiscalExistente}`,
                "_blank"
              )
            }
          >
            Abrir Nota Fiscal
          </Button>
        </>
      ) : (
        <>
          <input
            type="file"
            id="file-upload"
            accept="application/pdf"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <label htmlFor="file-upload">
            <Button
              disableElevation
              variant="contained"
              color="primary"
              component="span"
              startIcon={
                loading ? <CircularProgress size={20} /> : <AttachFileIcon />
              }
              disabled={loading || notaFiscalExistente}
            >
              {loading ? "Enviando..." : "Anexar Nota Fiscal"}
            </Button>
          </label>
        </>
      )}
    </>
  );
};

export default PdfUploader;
