import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Paper,
  CircularProgress,
  Grid,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Button,
  Divider,
} from "@mui/material";
import { format, parseISO } from "date-fns"; // Importe a função parseISO para converter a string em um objeto Date
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";
import axios from "../../../utils/configAxios";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PaymentIcon from "@mui/icons-material/Payment";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { useTheme } from "@mui/material/styles";
const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const Confirmacao = ({ alertCustom }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const parametros = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const [transacao, eventoId] = [parametros.transacao, urlParams.get("evento")];

  const [userData, setUserData] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState({
    color: "#0C8CE9",
  });
  const [loading, setLoading] = useState(true);

  const sendTransaction = async () => {
    setLoading(true); // Defina loading como true no início da função

    try {
      const { data } = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/buscar-filtro-participantes`,
        {
          transacao: transacao,
        }
      );
      setUserData(data);
    } catch (error) {
      setError(error.message);
    }

    setLoading(false); // Defina loading como false no final da função
  };

  useEffect(() => {
    sendTransaction(); // Ao montar o componente, faz a requisição para o endpoint
  }, []); // Array vazio como segundo argumento faz com que useEffect execute apenas uma vez após a montagem do componente

  const handleAprover = async () => {
    try {
      const { data } = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/marcar-presenca`,
        {
          transacoes: [transacao],
          status: "confirmed",
          docs: userData.map((item) => item.documento),
          evento: { id: eventoId },
        }
      );
      Swal.fire({
        text: `Usuário verificado!`,
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
        background: theme.palette.mode === "light" ? "#fff" : "#212121",
        iconColor: "#0EB816",
      });
      navigate("/jobs/scan");
    } catch (error) {
      console.log(error);
      alertCustom("Não foi possível atualizar status do participante");
    }
  };

  useEffect(() => {
    if (userData && userData.length > 0) {
      switch (userData[0].status) {
        case "approved":
          setMessage({
            color: "#2E7D32",
            message: "Pagamento aprovado!",
            status: true,
            icon: <CheckRoundedIcon sx={{ fontSize: 50 }} />,
          });
          break; // Adicione break aqui
        case "pending":
          setMessage({
            color: "#FFC102",
            message: "Pagamento ainda não aprovado!",
            status: false,
            icon: <PaymentIcon sx={{ fontSize: 50 }} />,
          });
          break; // Adicione break aqui
        case "signing_up":
          setMessage({
            color: "#0C8CE9",
            message: "Inscrição não finalizada!",
            status: false,
            icon: <PendingActionsIcon sx={{ fontSize: 50 }} />,
          });
          break; // Adicione break aqui
        case "confirmed":
          setMessage({
            color: "#2E7D32",
            message: "Verificação já realizada!",
            status: false,
            icon: <CheckRoundedIcon sx={{ fontSize: 50 }} />,
          });
          break; // Adicione break aqui
        case "cancelled":
          setMessage({
            color: "#f0f0f0",
            message: "Inscrição cancelada!",
            status: false,
            icon: <InfoRoundedIcon sx={{ fontSize: 100 }} />,
          });
          break; // Adicione break aqui
        default:
          setMessage({
            color: "#FFC102",
            message: "Ocorreu um erro!",
            status: false,
            icon: <InfoRoundedIcon sx={{ fontSize: 100 }} />,
          });
          break; // Adicione break aqui
      }
    }
  }, [userData]);

  return (
    <Paper
      elevation={0}
      sx={{
        background: "transparent",
        marginTop: { md: "70px", xs: "50px" },
        height: "100vh",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        padding: 2,
        maxWidth: "600px",
        textAlign: "center",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Avatar
                sx={{ backgroundColor: message.color, width: 100, height: 100 }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "#fff" }} />
                ) : (
                  message.icon
                )}
              </Avatar>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="button" color="textSecondary">
                {message.message}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {userData.length > 0 &&
          userData.map((user, index) => (
            <Paper
              variant="outlined"
              key={index}
              sx={{ padding: 2, margin: "20px 0" }}
            >
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" gutterBottom>
                    {user.nome}
                    <Typography variant="body1">
                      {user.categoriaName}
                      <Typography variant="body2" color="textSecondary">
                        {user.documento}
                      </Typography>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="body1" className="space">
                    Valor Total:
                    <Typography color="textSecondary">{`R$${Number(
                      user.valorTotal
                    ).toFixed(2)}`}</Typography>
                  </Typography>
                  <Typography variant="body1" className="space">
                    Código da Transação:{" "}
                    <Typography color="textSecondary">
                      {user.transacao}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Accordion elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>Produtos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {user.produtos &&
                          user.produtos.map((produto, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                primary={`${produto.nome} - R$${produto.preco} - ${produto.variacao}`}
                                secondary={`Quantidade: ${produto.quantidade}`}
                              />
                            </ListItem>
                          ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Respostas ao Formulário</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {user.formulario &&
                          user.formulario.map((pergunta, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                primary={pergunta.input}
                                secondary={`Resposta: ${pergunta.resposta}`}
                              />
                            </ListItem>
                          ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Paper>
          ))}
        <Grid item xs={12} md={12}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            fullWidth
            disabled={message.status === false}
            sx={{ maxWidth: "250px" }}
            onClick={handleAprover}
          >
            Aprovar
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            disableElevation
            variant="text"
            color="primary"
            fullWidth
            sx={{ maxWidth: "250px" }}
            onClick={() => navigate("/jobs/scan")}
          >
            Ler Outro QR CODE
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            disableElevation
            variant="text"
            color="primary"
            fullWidth
            sx={{ maxWidth: "250px" }}
            onClick={() => navigate("/dash/dashboard")}
          >
            Sair
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Confirmacao;
