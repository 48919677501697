import React, { useState, useEffect } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import NavBar from "../../components/NavegationBar/";
import Stepper from "./Steps/stepper";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "../../utils/configAxios";
import SwipeableEdgeDrawer from "../../components/Drawer/SwipeableEdgeDrawer";
import DataAttemp from "../../utils/DataAttemp";
import NotFound from "./notFound";
import ConfirmDialog from "./dialog";
import marcaAg from "../../images/marcaAg.png";
import { format } from "date-fns";
import Timer from "./timer";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const IncricaoPage = ({ alertCustom }) => {
  const dataManager = DataAttemp();
  const navigate = useNavigate();
  const parametros = useParams();
  const [evento, setEvento] = useState(null);
  const [pivo, setPivo] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [route, setRoute] = useState("/login");
  const [start, setStart] = useState(false);
  const [justificativa, setJustificativa] = useState(false);

  const now = new Date();
  const [dadosCadastro, setDadosCadastro] = useState({
    percDesconto: 0,
    dataExpiracao: new Date(now.getTime() + 15 * 60000),
    dependentes: [
      {
        nome: dataManager.userData.nome,
        doc: dataManager.userData.auth.cpf,
        email: dataManager.userData.auth.email,
        dataNascimento: dataManager.userData.dataNascimento ?? new Date(),
        sexo: dataManager.userData.sexo ?? "Não informado",
        dataInscricao: new Date(),
        prop: true,
        formulario: [],
        kits: [],
      },
    ],
  });
  const [eventoFormated, setEventoFormated] = useState({});
  const [financeiro, setFinanceiro] = useState();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [height, setHeight] = useState("20px");

  const handleClick = () => {
    setHeight("100px");
  };

  const handleBlur = () => {
    setHeight("20px");
  };
  const fetchData = async (force = false, senha = "") => {
    setLoading(true);
    try {
      const id = Number(parametros.id) || 0;
      const response = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/buscar-evento`,
        {
          idEvento: id,
          force: force,
          idUser: dataManager.userData.id,
          senha: senha,
        }
      );

      if (response.data) {
        if (response.data.complete == false) {
          setConfirmMessage(
            "Atenção: Seus dados não estão completos ou são inválidos!"
          );
          setRoute("/perfil/complete");
          return setOpenDialog(true);
        }

        if (response.data.repetida == true) {
          setConfirmMessage("Atenção: Você já se inscreveu para esse evento!");
          setRoute("/home");
          return setOpenDialog(true);
        }

        if (response.data.ban == true) {
          setConfirmMessage(
            "Atenção: Você não pode se inscrever para esse evento!"
          );
          setRoute("/home");
          setJustificativa(true);
          return setOpenDialog(true);
        }

        const dataDoEvento = new Date(response.data.evento.data);
        const horarioInscricoes = {
          horas:
            24 + Number(response.data.evento.horarioInscricoes.split(":")[0]),
          minutos: Number(response.data.evento.horarioInscricoes.split(":")[1]),
        };
        dataDoEvento.setHours(
          horarioInscricoes.horas,
          horarioInscricoes.minutos,
          0,
          0
        );
        const dataFinalEvento = new Date(response.data.evento.dataFinal);
        dataFinalEvento.setHours(dataFinalEvento.getHours() + 27);
        var currentDate = new Date();

        if (!response.data.evento.status) {
          alertCustom("Não é possível continuar, evento indisponível!");
        } else if (currentDate.getTime() > dataFinalEvento.getTime()) {
          alertCustom("Não é possível continuar, evento expirado!");
          navigate("/home");
        } else if (currentDate.getTime() <= dataDoEvento.getTime()) {
          alertCustom(
            format(
              dataDoEvento,
              "'Evento estará disponível a partir de 'dd/MM/yyyy' às 'HH:mm'"
            )
          );
          navigate("/home");
        } else {
          setDadosCadastro({
            transacao: response.data?.transacao,
            ...dadosCadastro,
          });

          setEvento(response.data.evento);
          setLoading(false);
        }
      }
    } catch (error) {
      alertCustom("Serviço temporariamente insdiponível!");
      setLoading(false);
    }
  };

  useEffect(() => {
    const senha = localStorage.getItem("senhaLink");
    fetchData(false, senha);
  }, [parametros.id]);

  const valorComTaxa = (valorBase) => {
    let valorFormated = valorBase;
    if (!evento) return 0;
    const { taxas, tipo } = evento;

    if (tipo === "pago") {
      for (const taxa of taxas) {
        if (taxa.operacao === "percentual") {
          valorFormated += valorBase * (taxa.taxa / 100);
        } else if (taxa.operacao === "fixo") {
          valorFormated += taxa.taxa;
        }
      }
    }
    return valorFormated;
  };

  useEffect(() => {
    let valTotal = 0;
    let valTotalSemTaxa = 0;
    const taxaText = evento
      ? Object.entries(
          evento.taxas.reduce((acc, taxa) => {
            const key = taxa.operacao; // 'fixo' ou 'percentual'
            if (!acc[key]) {
              acc[key] = 0;
            }
            acc[key] += taxa.taxa;
            return acc;
          }, {})
        )
          .map(
            ([operacao, soma]) =>
              `${operacao === "fixo" ? `R$ ${soma.toFixed(2)}` : `${soma}%`}`
          )
          .join(" + ")
      : "";

    const ingressosText =
      dadosCadastro.dependentes.length > 0
        ? dadosCadastro.dependentes
            .map((ingresso) => {
              valTotal += Number(ingresso?.valorIngresso ?? 0);
              return `R$ ${ingresso?.valorIngresso ?? 0}`;
            })
            .join("\n+ ")
        : "";

    const kitsText =
      dadosCadastro.dependentes &&
      dadosCadastro.dependentes
        .map((dependente) =>
          dependente.kits
            .map((kit) => {
              valTotal += Number(kit.preco) * Number(kit.quantidade);
              return `R$ ${kit.preco} x ${kit.quantidade}`;
            })
            .join("\n+ ")
        )
        .join("\n+ ");
    const descontoText = dadosCadastro.desconto
      ? valorComDesconto(dadosCadastro.desconto, valTotal).text
      : "-";
    valTotalSemTaxa = valorComDesconto(
      dadosCadastro.desconto,
      valTotal
    ).montante;
    valTotal = valorComTaxa(
      valorComDesconto(dadosCadastro.desconto, valTotal).montante
    );

    setFinanceiro({
      valTotalSemTaxa,
      taxaText,
      ingressosText,
      kitsText,
      descontoText,
      valorTotal: Number(valTotal),
    });
  }, [dadosCadastro]);

  const valorComDesconto = (desconto, valor) => {
    if (!desconto || valor <= 0 || isNaN(desconto.valor) || isNaN(valor)) {
      return { text: "Não adicionado", montante: valor };
    }

    let valorDesconto;

    if (desconto.type === "porcentagem") {
      valorDesconto = (valor * desconto.valor) / 100;
    } else if (desconto.type === "valor") {
      valorDesconto = desconto.valor;
    } else {
      return { text: "Tipo de desconto inválido", montante: valor };
    }

    const valorFinal = valor - valorDesconto;
    return {
      text: `- R$ ${valorDesconto.toFixed(2)}`,
      montante: valorFinal < 0 ? 0 : valorFinal,
    };
  };

  const formatarEvento = (EventoData) => {
    const dataEvento = new Date(EventoData.dataEvento);
    dataEvento.setHours(
      parseInt(EventoData.horario.split(":")[0]) + 24,
      parseInt(EventoData.horario.split(":")[1]),
      0,
      0
    );

    const dataInicial = new Date(EventoData.data);
    dataInicial.setHours(24, 0, 0, 0);

    const dataFinal = new Date(EventoData.dataFinal);
    dataFinal.setHours(47, 59, 0, 0);

    let text = "";

    if (
      new Date().getTime() < dataFinal.getTime() &&
      new Date().getTime() > dataInicial.getTime()
    ) {
      text = "Inscreva-se";
    } else if (new Date().getTime() > dataFinal.getTime()) {
      text = "Expirado";
    } else {
      text = "Não iniciado";
    }

    setEventoFormated({
      data: dataEvento,
      dataInscricaoInicial: dataInicial,
      dataInscricaoFinal: dataFinal,
      horario: EventoData.horario,
      buttonText: text,
    });
  };

  useEffect(() => {
    if (evento) {
      formatarEvento(evento);
    }
  }, [evento]);

  return (
    <div
      style={{
        width: "100vw",
        paddingBottom: "10vh",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        background: `url(${marcaAg})`,
      }}
    >
      <ConfirmDialog
        justificativa={justificativa}
        open={openDialog}
        title={"Opps!!"}
        route={route}
        message={confirmMessage}
        onClose={
          route == "/cadastro"
            ? () => window.location.reload()
            : () => navigate(route)
        }
        onConfirm={
          route == "/cadastro"
            ? () => window.location.reload()
            : () => navigate(route)
        }
      />
      <Grid
        container
        sx={{
          marginTop: { md: "70px", xs: "40px" },
          maxWidth: { md: "950px", xs: "100vh" },
        }}
        spacing={2}
      >
        {evento ? (
          <>
            <Grid item xs={12} md={isSmallScreen ? 12 : 9}>
              <div
                style={{
                  width: "100%",
                  height: height,
                  display: isSmallScreen ? "flex" : "none",
                  transition: "height 0.3s ease",
                  background: `url(${REACT_APP_URL_BIBLIOTECA_RUNNERS}/images?file=Eventos/${evento.perfilImage})`,
                }}
                onClick={handleClick}
                onBlur={handleBlur}
                tabIndex={0}
              />
              <Stepper
                start={start}
                setStart={setStart}
                financeiro={financeiro}
                dadosCadastro={dadosCadastro}
                setDadosCadastro={setDadosCadastro}
                alertCustom={alertCustom}
                setRoute={setRoute}
                evento={evento}
                pivo={pivo}
                setPivo={setPivo}
              />
            </Grid>

            <NavBar />
            {isSmallScreen ? (
              <SwipeableEdgeDrawer
                dadosCadastro={dadosCadastro}
                financeiro={financeiro}
              />
            ) : (
              <Grid item xs={12} md={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Paper
                      variant="outlined"
                      sx={{
                        padding: "20px",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          width: "100%",
                          display: "flex",
                          textAlign: "right",
                          justifyContent: "space-between",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <span>Ingressos: </span>
                        <span>{financeiro && financeiro.ingressosText}</span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          width: "100%",
                          display: "flex",
                          textAlign: "right",
                          justifyContent: "space-between",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <span>Kits:</span>
                        <span>{financeiro && financeiro.kitsText}</span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ textAlign: "right" }}>Cupom:</span>
                        <span style={{ textAlign: "right" }}>
                          {financeiro.descontoText}
                        </span>
                      </Typography>
                      {evento && evento.tipo === "pago" && (
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                          }}
                        >
                          <span style={{ textAlign: "right" }}>
                            Taxa de serviço:
                          </span>
                          <span style={{ textAlign: "right" }}>
                            {financeiro.taxaText}
                          </span>
                        </Typography>
                      )}
                      <Typography
                        variant="h6"
                        sx={{
                          width: "100%",
                          display: "flex",
                          textAlign: "left",
                          justifyContent: "space-between",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <span>Total:</span>
                        <span>
                          R${" "}
                          {financeiro &&
                            Number(financeiro.valorTotal).toFixed(2)}
                        </span>
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Timer
                      dataInscrição={
                        dadosCadastro?.dependentes[0].dataInscricao
                      }
                      start={start}
                      setRoute={setRoute}
                      setOpenDialog={setOpenDialog}
                      setConfirmMessage={setConfirmMessage}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
                    <img
                      src={`${REACT_APP_URL_BIBLIOTECA_RUNNERS}/images?file=Eventos/${evento.perfilImage}`}
                      style={{
                        display: { xs: "none", md: "flex" },
                        width: "100%",
                        borderRadius: "4px",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          !loading && <NotFound />
        )}
      </Grid>
    </div>
  );
};

export default IncricaoPage;
