import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "../../../utils/configAxios";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const MudarSenha = ({ alertCustom, formData, setFormulario, fetchData }) => {
  const navigate = useNavigate();
  const parametros = useParams();
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    const verificar = async () => {
      if (!parametros.hash || !parametros.hash.includes("-")) {
        navigate("/login");
      } else {
        try {
          const data = {
            idUser: parametros.hash.split("-")[0].trim(),
            codigo: parametros.hash.split("-")[1].trim(),
          };

          const response = await axios.post(
            `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/verificar-codigo`,
            data
          );
          if (response.data.status) {
            localStorage.setItem("token", response.data.token);
          }
        } catch (error) {
          alertCustom("Link expirado ou inválido");
        }
      }
    };
    verificar();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validação de senha
    if (formData.password.length < 5) {
      alertCustom("Sua nova senha deve possuir 5 caracteres ou mais");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      alertCustom("As senhas não correspondem");
      return;
    }

    // Envio da requisição para troca de senha
    setLoading(true);
    try {
      const data = {
        key: parametros.hash.split("-")[1].trim(),
        idUser: parametros.hash.split("-")[0].trim(),
        senha: formData.password,
      };
      const response = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/trocar-senha`,
        data
      );
      if (response.data) {
        alertCustom(response.data.message ?? "Autenticação bem sucedida!");
        localStorage.setItem("userData", JSON.stringify(response.data.user));
        localStorage.setItem("acessType", response.data.authorizationKey);
        response.data.organizador &&
          localStorage.setItem(
            "organizador",
            JSON.stringify(response.data.organizador)
          );
        await fetchData();
        if (["@adm", "@manager"].includes(response.data.authorizationKey)) {
          return navigate("/dash/dashboard");
        }
        return navigate(
          ["dash"].some((item) =>
            localStorage.getItem("lastRoute")?.includes(item)
          ) || !localStorage.getItem("lastRoute")
            ? "/home"
            : localStorage.getItem("lastRoute")
        );
      }
    } catch (error) {
      alertCustom(
        error.response?.data?.message ||
          "Ocorreu um erro ao tentar trocar a senha"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        padding: 2,
      }}
      component="form"
      onSubmit={handleSubmit}
      noValidate
    >
      <Typography variant="h6" sx={{ textAlign: "center", marginTop: "20px" }}>
        Mudar Senha
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="password"
            label="Nova Senha"
            type={showPass ? "text" : "password"}
            variant="standard"
            value={formData.password}
            onChange={(e) =>
              setFormulario({ ...formData, password: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="confirmPassword"
            label="Confirme a Nova Senha"
            type={showPass ? "text" : "password"}
            variant="standard"
            value={formData.confirmPassword}
            onChange={(e) =>
              setFormulario({ ...formData, confirmPassword: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showPass}
                onChange={(e) => setShowPass(e.target.checked)}
              />
            }
            label="Mostrar Senhas"
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit}
            loading={loading}
            loadingPosition="end"
          >
            Trocar Senha
          </LoadingButton>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="text"
            disableElevation
            onClick={() => navigate("/login")}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MudarSenha;
