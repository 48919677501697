import React, { useEffect, useState } from "react";
import {
  Typography,
  CardActionArea,
  Divider,
  CardMedia,
  Paper,
  Grid,
  Chip,
  Button,
  Card,
  Box,
  Select,
  InputBase,
  FormControl,
  MenuItem,
  CircularProgress,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Tooltip from "@mui/material/Tooltip";
import DataAttemp from "../../../utils/DataAttemp";
import SimpleCard from "../../../components/Cards/SimpleCard";
import axios from "../../../utils/configAxios";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import { useNavigate } from "react-router-dom";
import BannerAD from "./InfoPages/banner";
const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

function formatarData(data) {
  const meses = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const dia = data.getDate();
  const mes = meses[data.getMonth()];
  const hora = data.getHours();
  const minutos = data.getMinutes();

  const horaFormatada = hora < 10 ? `0${hora}` : hora;
  const minutosFormatados = minutos < 10 ? `0${minutos}` : minutos;

  return `Última atualização ${dia}/${mes} às ${horaFormatada}:${minutosFormatados}`;
}

function RenderEventos({
  loadingSearch,
  eventos,
  setEvento,
  customAlert,
  setOpenModal,
  setLoading,
  fetchData,
  count,
}) {
  const [eventosRemaped, setEventosRemaped] = useState(eventos);
  const [seeMore, setSeeMore] = useState(10);

  const navigate = useNavigate();
  const dataManager = DataAttemp();
  const [hoje, setHoje] = useState(0);
  const [mesReferencia, setMesReferencia] = useState(new Date());
  const [ultReq, setUltReq] = useState(new Date());
  const [financas, setFinancas] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [errorPerfil, setErrorPerfil] = useState([]);
  const handleOpenModal = async (evento) => {
    setLoading(true);
    setOpenModal(true);
    customAlert("Aguarde, carregando...");
    try {
      const { data } = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/buscar-evento`,
        {
          idEvento: evento.id,
          force: true,
          token: dataManager.userData?.auth?.token,
        }
      );

      setEvento(data.evento);
    } catch (error) {
      console.log(error);
      customAlert("Ocorreu um erro ao exibir o evento!");
    }
    customAlert("Aguarde, carregando...", true);
    setLoading(false);
  };

  const handleImageError = (index) => {
    setErrorPerfil((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = true;
      return newErrors;
    });
  };

  useEffect(() => {
    setEventosRemaped(eventos);
  }, [eventos]);

  useEffect(() => {
    const buscarFinanças = async () => {
      try {
        setUltReq(new Date());
        const response = await axios.post(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/pagamentos/financeiro-status`,
          {
            mesReferencia: mesReferencia,
          }
        );
        setHoje(
          (response.data.totalDiario.valor * 100) /
            (response.data.approved.valor || 1)
        );
        setFinancas(response.data);
      } catch (error) {
        console.log(error);
        customAlert("Ocorreu um erro ao buscar os dados financeiros");
      }
    };
    buscarFinanças();
  }, [mesReferencia]);

  const handleSeeMore = () => {
    setSeeMore((prev) => prev + 5);
    fetchData(seeMore);
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length == 0) {
      fetchData(seeMore, e.target.value ?? "");
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <BannerAD />
      </Grid>
      <Grid item xs={12} md={4}>
        {financas && (
          <>
            <SimpleCard
              variant="contained"
              height={"auto"}
              titulo={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    flexWrap: "wrap",
                  }}
                >
                  <span>{`R$ ${financas.approved.valor.toFixed(2)}`}</span>
                  <ArrowUpwardIcon fontSize="10" color="success" />
                  <FormControl
                    variant="outlined"
                    sx={{ position: "absolute", right: 0, top: 0 }}
                  >
                    <Select
                      value={`${mesReferencia.getMonth()}-${mesReferencia.getFullYear()}`}
                      onChange={(event) => {
                        const [selectedMonth, selectedYear] = event.target.value
                          .split("-")
                          .map(Number);
                        const selectedDate = new Date(
                          selectedYear,
                          selectedMonth + 1,
                          0
                        ); // Definir o último dia do mês
                        setMesReferencia(
                          selectedDate.getMonth() == new Date().getMonth()
                            ? new Date()
                            : selectedDate
                        );
                      }}
                      input={<InputBase />}
                    >
                      {[...Array(6)].map((_, index) => {
                        const monthDate = new Date();
                        monthDate.setMonth(new Date().getMonth() - index, 1);
                        const monthAbbreviation = monthDate.toLocaleString(
                          "default",
                          {
                            month: "short",
                          }
                        );
                        const year = monthDate.getFullYear();
                        return (
                          <MenuItem
                            key={index}
                            value={`${monthDate.getMonth()}-${year}`}
                          >
                            {`${monthAbbreviation
                              .toUpperCase()
                              .replace(".", "")} ${year}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Typography
                    sx={{
                      width: "100%",
                      marginTop: "-8px",
                      color: "#17C64F",
                      fontSize: 14,
                    }}
                    color="success"
                    variant="body1"
                  >
                    {`+ ${financas.totalDiario.valor.toFixed(
                      2
                    )} (${hoje.toFixed(1)}%) acumulados hoje`}
                  </Typography>
                  <Typography style={{ fontSize: 12, width: "100%" }}>
                    {`R$ ${(financas.pending.valor ?? 0).toFixed(2)} pendentes`}
                    <Typography style={{ fontSize: 12, width: "100%" }}>{`R$ ${(
                      financas.cancelled.valor ?? 0
                    ).toFixed(2)} em cancelamentos`}</Typography>
                  </Typography>
                </div>
              }
            />
            <Typography
              className="show-box"
              variant="body2"
              color="textSecondary"
              sx={{
                textAlign: "center",
                width: "100%",
                padding: 1,
                margin: "10px 0",
                fontSize: 12,
              }}
            >
              {formatarData(ultReq)}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid
          container
          spacing={0}
          sx={{ display: "flex", flexWrap: "wrap", justifyContent: "right" }}
        >
          <Grid item xs={12} md={6}>
            <TextField
              placeholder="Pesquisar"
              variant="outlined"
              fullWidth
              size="small"
              margin="normal"
              onChange={handleSearch}
              sx={{ margin: 0, marginBottom: "5px" }}
              InputProps={{
                endAdornment: <SearchIcon color="secondary" />,
                type: "search",
                sx: {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  background: "rgba(0,0,0,0.02)",
                  borderRadius: "50px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Card
              elevation={0}
              className={isMobile ? "" : "allow-scroll"}
              sx={{
                maxHeight: "80vh",
                overflow: "scroll",
              }}
            >
              {" "}
              <Grid container>
                {eventosRemaped.length > 0 ? (
                  eventosRemaped.map((evento, index) => (
                    <Grid item xs={12}>
                      <CardActionArea
                        key={index}
                        style={{
                          borderRadius: "8px",
                          padding: "10px",
                          width: "100%",
                          margin: "5px 0",
                          background: "rgba(0,0,0,0.02)",
                        }}
                        onClick={() => handleOpenModal(evento)}
                      >
                        <Grid container spacing={2}>
                          <Grid item sx={{ width: { md: "15%", xs: "25%" } }}>
                            {errorPerfil[index] ? (
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                bgcolor="#f9f9f9"
                                sx={{
                                  width: "75px",
                                  height: "75px",
                                  textAlign: "center",
                                  marginRight: "10px",
                                }}
                                color="text.secondary"
                                fontSize={14}
                              >
                                <ImageNotSupportedOutlinedIcon />
                              </Box>
                            ) : (
                              <CardMedia
                                component="img"
                                alt="imagem do evento"
                                height="75"
                                width="75"
                                onError={() => handleImageError(index)}
                                image={`${REACT_APP_URL_BIBLIOTECA_RUNNERS}/images?file=Eventos/${evento.perfilImage}`}
                                style={{
                                  borderRadius: "5px",
                                  maxWidth: "75px",
                                }}
                              />
                            )}
                          </Grid>
                          <Grid
                            item
                            sx={{
                              textAlign: "center",
                              maxWidth: { md: "10%", xs: "25%" },
                            }}
                          >
                            <Typography variant="h4">
                              {evento.data.split("-").reverse()[0]}
                              <Typography variant="body1" color="textSecondary">
                                {new Date(
                                  1,
                                  evento.data.split("-").reverse()[1] - 1,
                                  1
                                )
                                  .toLocaleDateString("pt-br", {
                                    month: "short",
                                  })
                                  .toUpperCase()
                                  .replace(".", "")}
                              </Typography>
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            sx={{
                              textAlign: "center",
                              maxWidth: { md: "10%", xs: "25%" },
                            }}
                          >
                            <Typography variant="h6">
                              {evento.dataFinal.split("-").reverse()[0]}

                              <Typography variant="body2" color="textSecondary">
                                {new Date(
                                  1,
                                  evento.dataFinal.split("-").reverse()[1] - 1,
                                  1
                                )
                                  .toLocaleDateString("pt-br", {
                                    month: "short",
                                  })
                                  .toUpperCase()
                                  .replace(".", "")}
                              </Typography>
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              width: { md: "70%", xs: "40%" },
                            }}
                          >
                            <Typography variant="h6">
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: isMobile
                                    ? "end"
                                    : "space-between",
                                }}
                              >
                                {!isMobile ? evento.titulo : ""}
                                <Chip
                                  sx={{
                                    borderRadius: "0 0 5px 5px",

                                    marginTop: "-10px",
                                  }}
                                  size="small"
                                  color={
                                    evento.status ? "success" : "secondary"
                                  }
                                  label={evento.status ? "Ativo" : "Inativo"}
                                />
                              </span>

                              {!isMobile && (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  <AccessTimeIcon fontSize="small" />
                                  {`${evento.dataEvento
                                    .split("-")
                                    .reverse()
                                    .join("/")} ${
                                    evento.horario
                                      ? `às ${evento.horario}`
                                      : " com horário a definir"
                                  }`}
                                </Typography>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardActionArea>
                    </Grid>
                  ))
                ) : !loadingSearch ? (
                  <Grid item xs={12} md={12} className="tour_1">
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      textAlign="center"
                      sx={{ margin: "15px" }}
                    >
                      Nenhum evento cadastrado!{" "}
                    </Typography>
                  </Grid>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
                {count > 4 && (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button onClick={handleSeeMore}>Ver Mais</Button>
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RenderEventos;
