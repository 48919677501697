import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useSVGOverlay } from "react-leaflet/SVGOverlay";
import { DialogTitle } from "@mui/material";

const UnclosableDialog = ({ open, message, buttonLabel, targetRoute }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    console.log(targetRoute);
    navigate(targetRoute);
  };

  return (
    <Dialog open={open} fullWidth="md" disableEscapeKeyDown onClose={() => {}}>
      <DialogContent>
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>{message}</DialogContent>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
          >
            {buttonLabel}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UnclosableDialog;
