import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

const UploadDialog = ({
  alertCustom,
  open,
  handleCloseDialog,
  setUploadFilesExtras,
  uploadFilesExtras,
}) => {
  const [uploadFiles, setUploadFiles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedName, setSelectedName] = useState("Carrossel");
  const [local, setLocal] = useState("");
  const [descricao, setDescricao] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);

  const onDrop = (acceptedFiles) => {
    setModalOpen(true);
    setFileToUpload(acceptedFiles[0]);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedName("Carrossel");
    setFileToUpload(null);
    setLocal("");
  };

  const handleFileUpload = () => {
    if (!selectedName || !local) {
      alertCustom(
        "Por favor, selecione um destino e link válido para o arquivo."
      );
      return;
    }

    const term = fileToUpload.path.split(".");
    const newFile = {
      id: Date.now(), // Use um ID único, neste caso estou usando o timestamp
      titulo: `${selectedName}${local.replace(/\D/g, "")}`,
      campo: selectedName,
      file: fileToUpload,
      link: local,
      descricao: descricao || `${selectedName}${local.replace(/\D/g, "")}`,
      type: term[term.length - 1],
    };

    setUploadFilesExtras([...uploadFilesExtras, newFile]);
    handleModalClose();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  return (
    <div>
      <Grid container style={{ position: "relative" }}>
        <Dialog open={modalOpen} onClose={handleModalClose}>
          <DialogTitle>
            <Typography variant="body1">
              Escolha o destino do arquivo
              <Typography variant="body2" color="textSecondary">
                Será exibido conforme sua escolha
              </Typography>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={1}
              sx={{ padding: "10px 0", maxWidth: "300px" }}
            >
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Destino do arquivo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedName}
                    label="Destino do arquivo"
                    onChange={(event) => setSelectedName(event.target.value)}
                  >
                    {["Carrossel"].map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  value={local}
                  onChange={(e) => setLocal(e.target.value)}
                  label="Link ao clicar"
                  placeholder="https://goianiarunners.com/home/xxx"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose}>Cancelar</Button>
            <Button onClick={handleFileUpload} color="primary">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            Upload de Arquivos
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div {...getRootProps()} style={{ cursor: "pointer" }}>
                  <input {...getInputProps()} />
                  <Typography variant="body1" className="show-box-dash">
                    Arraste e solte os arquivos aqui ou clique para selecionar
                    <Typography variant="body2" color="textSecondary">
                      Formatos aceitos: .pdf, .png, .jpeg, .jpg
                    </Typography>
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              onClick={handleCloseDialog}
              color="primary"
              disabled={uploadFilesExtras.length === 0}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
};

export default UploadDialog;
