import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // Ícone de seta com "rabinho"
import { useNavigate } from "react-router-dom";
import DataAttemp from "../../utils/DataAttemp";
import CompleteYourProfileImage from "../../images/complete_your_profile.png";

const CompleteProfileModal = () => {
  const [open, setOpen] = useState(true);
  const isMobile = window.innerWidth <= 600;
  const dataManager = DataAttemp();
  const navigate = useNavigate();

  const handleAgree = () => {
    navigate("/perfil/complete");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
    >
      <Box position="relative" sx={{ height: "100%", alignContent: "center" }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={1}>
          <Grid item xs={12} md={7}>
            <Box p={isMobile ? 0 : 3}>
              <DialogTitle sx={{ textAlign: "left" }}>
                {`Bem vindo, ${dataManager.userData.nome.toString()}!`}
              </DialogTitle>
              <DialogContent>
                <Typography variant="body1" gutterBottom>
                  Complete seu cadastro e você terá...
                </Typography>
                <List sx={{ mt: 2 }}>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      textAlign="justify"
                      primary="Acesso aos eventos esportivos da plataforma"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Será melhor identificado nas corridas" />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Evita contratempos com informações durante a organização" />
                  </ListItem>
                </List>
                <Typography
                  variant="body2"
                  gutterBottom
                  color="textSecondary"
                  className="show-box"
                  sx={{ textAlign: "justify" }}
                >
                  Assim, poderemos fornecer uma melhor experiência nos eventos
                  que você vai participar em breve...
                </Typography>
              </DialogContent>
            </Box>
          </Grid>
          {!isMobile && (
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={CompleteYourProfileImage}
                alt="Imagem ilustrativa"
                sx={{ width: "100%", objectFit: "contain" }}
              />
            </Grid>
          )}{" "}
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              textAlign: "center",
              margin: { md: "-30px 0 30px 0", xs: "30px 10px" },
            }}
          >
            <Button
              fullWidth={isMobile}
              disableElevation
              variant="contained"
              onClick={handleAgree}
              color="primary"
              endIcon={!isMobile && <ArrowForwardIcon />}
            >
              Completar Meu cadastro
            </Button>{" "}
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default CompleteProfileModal;
