import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Paper,
  CardActionArea,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import axios from "../../../utils/configAxios";
import NewForm from "./newForm";
import RenderForm from "./renderForm";

const REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM;

const Forms = (props) => {
  const { alertCustom } = props;
  const [listForms, setListForms] = useState([]);
  const [formSelected, setFormSelected] = useState(null);
  const [newForm, setNewForm] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleForms, setVisibleForms] = useState(3);
  const [openDialog, setOpenDialog] = useState(false);

  const handleItemClick = (item) => {
    setFormSelected(item);
    setNewForm(false);
    setActiveItem(item.id);
    setOpenDialog(true);
  };

  const loadData = async () => {
    try {
      const { data } = await axios.get(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/formulario/listar`
      );
      setListForms(data);
    } catch (error) {
      alertCustom("Houve um erro ao buscar os formulários!");
      console.error("Error loading forms:", error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const resetPage = () => {
    setFormSelected(null);
    setNewForm(false);
    setActiveItem(null);
    setSearchTerm("");
    setVisibleForms(3);
    loadData();
    handleCloseDialog();
  };

  const filteredForms = listForms
    .filter(
      (form) =>
        form.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
        form.subtitle.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(0, visibleForms);

  const handleShowMore = () => {
    setVisibleForms((prevVisibleForms) => prevVisibleForms + 3);
  };

  const handleShowLess = () => {
    setVisibleForms(3);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography variant="h6">
            Gerenciar Formulários
            <Typography variant="body2" color="textSecondary">
              Formulários são usados para saber mais sobre seus participantes
            </Typography>
          </Typography>
          <TextField
            placeholder="Pesquisar"
            variant="outlined"
            fullWidth
            size="small"
            margin="normal"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: <SearchIcon color="secondary" />,
              type: "search",
              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                background: "rgba(0,0,0,0.05)",
                borderRadius: "50px",
              },
            }}
          />
          <List
            style={{
              maxHeight: "65vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {filteredForms.length > 0 ? (
              filteredForms.map((form, index) => (
                <CardActionArea
                  sx={{ margin: "10px 0", borderRadius: "8px" }}
                  key={index}
                  onClick={() => handleItemClick(form)}
                >
                  <ListItem
                    selected={activeItem === form.id}
                    sx={{ borderRadius: "8px" }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <FolderIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={form.nome}
                      secondary={form.subtitle}
                    />
                  </ListItem>
                </CardActionArea>
              ))
            ) : (
              <Typography variant="body2">
                Nenhum resultado encontrado.
              </Typography>
            )}
            {listForms.length > visibleForms ? (
              <Button onClick={handleShowMore} color="primary">
                Visualizar Mais
              </Button>
            ) : (
              listForms.length < visibleForms &&
              visibleForms > 3 && (
                <Button onClick={handleShowLess} color="primary">
                  Visualizar Menos
                </Button>
              )
            )}
            <CardActionArea
              onClick={() => {
                setNewForm(true);
                setFormSelected(null);
                setActiveItem(null);
                setOpenDialog(true);
              }}
              sx={{ borderRadius: "8px" }}
              className="tour_4"
            >
              <ListItem sx={{ borderRadius: "8px" }}>
                <ListItemAvatar>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Criar novo formulário"}
                  secondary={"Mais informações sobre os participantes"}
                />
              </ListItem>
            </CardActionArea>
          </List>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullScreen={window.innerWidth <= 600}
        fullWidth
      >
        <DialogContent>
          {formSelected !== null && (
            <RenderForm
              resetPage={resetPage}
              formData={formSelected}
              alertCustom={alertCustom}
            />
          )}
          {newForm && (
            <NewForm resetPage={resetPage} alertCustom={alertCustom} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Forms;
