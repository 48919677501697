import React, { useState } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditDialog from "./EditDialog";

const EditableCard = ({
  editable = true,
  title,
  path,
  data,
  fields,
  handleSave,
  alertCustom,
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alertCustom("Chave Pix copiada!");
  };
  return (
    <>
      <Card elevation={0}>
        <CardContent>
          <Typography
            variant="subtitle1"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {title}
            {editable && (
              <IconButton onClick={handleOpenDialog}>
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </Typography>
          {fields.map((field) => (
            <Typography
              key={field}
              onClick={() => field === "chavePix" && handleCopy(data[field])}
            >
              {" "}
              {`${field.charAt(0).toUpperCase() + field.slice(1)}:`}{" "}
              <span className={field === "chavePix" ? "link-copy" : ""}>
                {data[field]}
              </span>
            </Typography>
          ))}
        </CardContent>
      </Card>

      <EditDialog
        open={openDialog}
        onClose={handleCloseDialog}
        data={data}
        fields={fields}
        path={path}
        handleSave={handleSave}
        alertCustom={alertCustom}
      />
    </>
  );
};

export default EditableCard;
