import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber"; // Ícone importado

const CupomDialog = ({
  setCuponsPromocionais,
  cuponsPromocionais,
  alertCustom,
  edit = 3,
}) => {
  const [open, setOpen] = useState(false);
  const [cupomType, setCupomType] = useState("");
  const [cupomName, setCupomName] = useState("");
  const [cupomValue, setCupomValue] = useState("");

  const cupomTypes = [
    { label: "Desconto em Valor", value: "valor" },
    { label: "Desconto em Porcentagem", value: "porcentagem" },
  ];

  const handleClickOpen = () => {
    if (cuponsPromocionais && cuponsPromocionais.length === 2)
      return alertCustom("Máximo de cupons atingido! (2)");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (cupomType === "porcentagem" && (cupomValue < 0 || cupomValue > 100)) {
      alert("O valor da porcentagem deve estar entre 0 e 100.");
      return;
    }
    if (!cupomValue) return;
    if (cupomType === "valor" && isNaN(cupomValue)) {
      alert("O valor do desconto deve ser um número válido.");
      return;
    }
    setCuponsPromocionais((prev) => [
      ...(Array.isArray(prev) ? prev : []),
      { type: cupomType, nome: cupomName, valor: parseFloat(cupomValue) },
    ]);

    setCupomType("");
    setCupomName("");
    setCupomValue("");
    handleClose();
  };

  const handleDelete = (index) => {
    setCuponsPromocionais(cuponsPromocionais.filter((_, i) => i !== index));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={edit}>
        <Button
          fullWidth
          color="primary"
          disableElevation
          variant="contained"
          sx={{ height: 40 }}
          onClick={handleClickOpen}
        >
          Cadastrar Cupom
        </Button>
        <Dialog open={open} onClose={handleClose} fullWidth="sm">
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            Criar Novo Cupom
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              select
              label="Tipo de Cupom"
              value={cupomType}
              onChange={(e) => {
                setCupomType(e.target.value);
                setCupomValue("");
              }}
              fullWidth
              margin="dense"
            >
              {cupomTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Nome do Cupom"
              value={cupomName}
              onChange={(e) =>
                setCupomName(
                  e.target.value.toLocaleUpperCase().replace(" ", "")
                )
              }
              fullWidth
              margin="dense"
            />
            <TextField
              label="Valor do Desconto"
              value={cupomValue}
              onChange={(e) => {
                const value = e.target.value;
                (cupomType === "valor" ||
                  (cupomType === "porcentagem" && value > 0 && value <= 100)) &&
                  setCupomValue(e.target.value);
              }}
              onBlur={() =>
                cupomType === "valor" && setCupomValue((+cupomValue).toFixed(2))
              }
              fullWidth
              margin="dense"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {cupomType === "porcentagem" ? "%" : "R$"}
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit} color="primary">
              Criar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      {Array.isArray(cuponsPromocionais) &&
        cuponsPromocionais &&
        cuponsPromocionais.length > 0 &&
        cuponsPromocionais.map((cupom, index) => (
          <Grid item xs={12} md={edit} key={index}>
            <Card
              elevation={0}
              sx={{
                borderRadius: "50px",
                background: "rgba(0,0,0,0.02)",
                maxWidth: 345,
                height: 40,
              }}
            >
              <CardHeader
                sx={{ marginTop: "-2px", padding: "1px 0px 1px 10px" }}
                avatar={
                  <ConfirmationNumberIcon
                    sx={{ marginLeft: 1 }}
                    color="warning"
                  />
                }
                action={
                  <div
                    style={{
                      height: 50,
                      marginRight: 10,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton onClick={() => handleDelete(index)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                }
                title={`${cupom.nome} ${
                  cupom.type === "porcentagem" ? "" : "R$"
                }${cupom.valor}${cupom.type === "porcentagem" ? "%" : ""}`}
              />
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default CupomDialog;
