import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Importa o ícone de seta
import FormDialog from "./FormDialog"; // Importa o componente de diálogo
import FooterPage from "./FooterPage";
import ImageBack from "../../images/background-image.jpg";
import WhatsAppFloatingButton from "./WhatsAppFloatingButton";
import axios from "../../utils/configAxios"; // Ajuste o caminho conforme necessário

const LandingPage = ({ alertCustom }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [taxas, setTaxas] = useState([]);

  useEffect(() => {
    const fetchTaxas = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/listar-taxas`
        );
        setTaxas(response.data);
      } catch (error) {
        console.error("Erro ao buscar taxas:", error);
      }
    };

    fetchTaxas();
  }, []);

  const handleBack = () => {
    // Adicione a lógica para o botão de voltar aqui
    // Por exemplo, você pode redirecionar para a página anterior ou para uma página específica
    window.history.back(); // Redireciona para a página anterior
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundImage: `url('${ImageBack}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          zIndex: 1,
        },
      }}
    >
      <WhatsAppFloatingButton />
      <IconButton
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
          zIndex: 2,
          color: "white",
          zIndex: 999,
          background: "rgba(0,0,0,0.3)",
        }}
        onClick={handleBack}
      >
        <ArrowBackIcon />
      </IconButton>
      <Container
        sx={{
          maxWidth: "1200px",
          zIndex: 2,
          position: "relative",
          padding: 4,
          color: "white",
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            paddingTop: "50px",
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              <b
                style={{
                  background: "#2ab13f",
                  borderRadius: "8px",
                  padding: "2px 8px",
                }}
              >
                GR
              </b>{" "}
              <b> ORGANIZADORES</b>
              <Typography variant="h6" gutterBottom>
                Crie, gerencie e organize eventos com facilidade.
              </Typography>
            </Typography>
            <div style={{ margin: 10 }}></div>

            <Typography variant="h6" paragraph>
              Junte-se a nós agora mesmo e aproveite todos os benefícios de uma
              plataforma robusta e intuitiva. Oferecemos ferramentas poderosas
              para gerenciar cada aspecto do seu evento com eficiência.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className="button-wave"
              onClick={() => setOpenDialog(true)}
              sx={{ marginTop: 2 }}
            >
              Entre em Contato
            </Button>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              Veja o reflexo em nossas taxas{" "}
              <Typography variant="body1" gutterBottom>
                São cobradas por sobre cada inscrição e aplicadas ao valor final
                do participante
              </Typography>
            </Typography>
            <Grid container spacing={2}>
              {taxas.length > 0 ? (
                taxas
                  .filter((taxa) => taxa.taxa > 0)
                  .map((taxa) => (
                    <Grid item xs={12} md={6} key={taxa.id}>
                      <Card
                        sx={{
                          backgroundColor: "#1c1c1c", // Fundo escuro
                          color: "white", // Texto branco
                          borderRadius: 2,
                          boxShadow: 3,
                          borderRadius: "16px",
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {taxa.descricao}
                          </Typography>
                          <Typography variant="body1" paragraph>
                            {`Taxa ${
                              taxa.operacao === "fixo" ? "fixa" : "percentual"
                            } no valor de `}
                            <b>{`${
                              taxa.operacao === "percentual"
                                ? `${taxa.taxa}%`
                                : `R$ ${taxa.taxa}`
                            } `}</b>
                            {`referente ${
                              taxa.aplicacao === "sistema"
                                ? "ao sistema"
                                : "a eventos"
                            }`}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
              ) : (
                <Grid item xs={12}>
                  <Typography variant="body1" color="textSecondary">
                    Nenhuma taxa de inscrição disponível no momento.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FooterPage onClick={() => setOpenDialog(true)} />
          </Grid>
        </Grid>
      </Container>
      <FormDialog
        alertCustom={alertCustom}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </Box>
  );
};

export default LandingPage;
