import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Paper,
  IconButton,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import infoImagem from "../../../../images/info_image.jpeg";
import GRLogo from "../../../../images/logo_filter.png";

const { REACT_APP_URL_BIBLIOTECA_RUNNERS } = process.env;

const BannerComponent = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [bannerData, setBannerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/config/buscar-banner-message`
        );
        setBannerData(response.data);
      } catch (error) {
        setError("Erro ao carregar dados do banner");
      } finally {
        setLoading(false);
      }
    };

    fetchBannerData();

    const bannerClosed = localStorage.getItem("bannerClosed");
    if (bannerClosed === "true") {
      setIsVisible(false);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem("bannerClosed", "true");
  };

  const handleActionClick = () => {
    if (bannerData?.action) {
      window.open(bannerData.action, "_blank");
    }
  };

  if (loading) {
    return <Typography>Carregando...</Typography>;
  }

  if (error) {
    return <Typography color="error">Erro: {error}</Typography>;
  }

  if (!isVisible || !bannerData) {
    return null;
  }

  return (
    <Box sx={{ position: "relative", margin: "16px 0" }}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: "8px",
          width: "100%",
          background: bannerData.color || "#000",
          backgroundSize: "cover",
          color: "#f9f9f9",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2} sx={{ padding: "32px" }}>
          <Grid item xs={12} md={12}>
            <Grid container sx={{ height: "100%", alignContent: "center" }}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  height: "80%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h3" fontWeight={600} gutterBottom>
                  {bannerData.titulo || "Título"}
                  <Typography variant="h6">
                    {bannerData.message ||
                      "Explore os novos horizontes que a tecnologia permite, seja livre e visite os mais diversos locais"}
                  </Typography>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  height: "20%",
                  display: "flex",
                  alignItems: "flex-end",

                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  GR Eventos & Esportes
                </Typography>
                <Button
                  onClick={handleActionClick}
                  variant="contained"
                  disableElevation
                  color="success"
                  endIcon={<ArrowForwardIcon fontSize="medium" />}
                >
                  <b>Saiba mais</b>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "#f9f9f9",
          }}
        >
          <CloseIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default BannerComponent;
