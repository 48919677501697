import * as React from "react";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";

export default function SimpleCard({
  titulo,
  subtitulo,
  img,
  content,
  width,
  height = 300,
  variant = "outlined",
}) {
  const [alt, setAlt] = React.useState("Imagem não carregada");
  return (
    <Card sx={{ minWidth: width, height: height }} variant={variant}>
      {img && (
        <CardMedia component="img" minHeight={height} image={img} alt="Kit" />
      )}

      {titulo && (
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ width: "100%" }}
          >
            {titulo}

            <Typography variant="body2" color="text.secondary">
              {subtitulo}
            </Typography>
          </Typography>
          {content}
        </CardContent>
      )}
    </Card>
  );
}
