import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { CardActionArea, Paper, Grid, Box, Chip } from "@mui/material";
import { format } from "date-fns";
import { formatarLocalizacao } from "../../utils/localFormater";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

export default function RecipeReviewCard({
  handleSelectEvento,
  evento,
  index,
}) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [imagemCarregada, setImagemCarregada] = useState(true);
  const dataEvento = new Date(evento.dataEvento);
  dataEvento.setHours(
    parseInt(evento.horario.split(":")[0]) + 24,
    parseInt(evento.horario.split(":")[1]),
    0,
    0
  );

  const dataInicial = new Date(evento.data);
  dataInicial.setHours(
    parseInt(evento.horarioInscricoes.split(":")[0]) + 24,
    parseInt(evento.horarioInscricoes.split(":")[1]),
    0,
    0
  );

  const dataFinal = new Date(evento.dataFinal);
  dataFinal.setHours(47, 59, 0, 0);
  let text = "";

  if (
    new Date().getTime() < dataFinal.getTime() &&
    new Date().getTime() > dataInicial.getTime()
  ) {
    text = "Inscreva-se";
  } else if (new Date().getTime() > dataFinal.getTime()) {
    text = "Expirado";
  } else {
    text = "Não iniciado";
  }

  const [eventoFormated, setEventoFormated] = useState({
    data: dataEvento,
    dataInscricaoInicial: dataInicial,
    dataInscricaoFinal: dataFinal,
    horario: evento.horario,
    buttonText: text,
  });
  const handleImagemError = () => {
    setImagemCarregada(false);
  };

  return (
    <Card
      variant="outlined"
      className="Card"
      sx={{
        position: "relative",
        borderRadius: "8px",
        //overflow: "hidden", // Oculta o conteúdo que ultrapassa a altura máxima
      }}
    >
      {new Date().getTime() < eventoFormated.dataInscricaoFinal.getTime() &&
        new Date().getTime() >
          eventoFormated.dataInscricaoInicial.getTime() && (
          <Chip
            variant="contained"
            color={evento.numVagas > 0 ? "success" : "primary"}
            label={
              isMdScreen
                ? evento.numVagas > 0
                  ? "Inscrições abertas"
                  : "Vagas esgotadas"
                : (evento.numVagas > 0
                    ? "Inscrições abertas"
                    : "Vagas esgotadas"
                  ).toUpperCase()
            }
            sx={{
              fontWeight: isMdScreen ? 400 : 510,

              position: "absolute",
              right: "10px",
              top: "10px",
              zIndex: 22,
              borderRadius: "5px",
            }}
          />
        )}

      <CardActionArea
        style={{ zIndex: "1", borderRadius: "5px", position: "relative" }}
        variant="outlined"
        onClick={() => handleSelectEvento(evento)}
      >
        <Paper
          className="text-gradient-background-white"
          sx={{
            position: "absolute",
            zIndex: "2",
            top: 10,
            left: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { md: "50px", xs: "65px" },
            height: { md: "55px", xs: "70px" },
            textAlign: "center",
          }}
          elevation={0}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {evento.dataEvento.split("-")[2]}
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontWeight: "bold", marginTop: "-5px" }}
            >
              {new Date(1, evento.dataEvento.split("-")[1] - 1, 1)
                .toLocaleDateString("pt-br", { month: "short" })
                .toUpperCase()
                .replace(".", "")}
            </Typography>
          </Typography>
        </Paper>

        {imagemCarregada ? (
          <CardMedia
            component="img"
            image={`${REACT_APP_URL_BIBLIOTECA_RUNNERS}/images?file=Eventos/${evento.perfilImage}`}
            alt="imageAS"
            style={{
              pointerEvents: "none",
              width: "100%",
              height: "100%",
              minHeight: "300px",
              objectFit: "cover",
            }}
            onError={handleImagemError}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            bgcolor="#f0f0f0"
            sx={{ minHeight: "300px" }}
            color="text.secondary"
            fontSize={14}
          >
            Imagem não disponível
          </Box>
        )}

        <Grid
          container
          sx={{
            position: "absolute",
            bottom: 0,
            height: "50%",
            color: "#fff",
          }}
          className="text-gradient-background"
        >
          <Grid item xs={12} md={12} sx={{ margin: "8px 16px" }}>
            <Typography
              variant="button"
              sx={{
                display: "flex",
                fontWeight: "bold",
                height: "100%",
                alignItems: "flex-end",

                fontSize: isMdScreen ? "16px" : "20px",
              }}
            >
              {evento.titulo}
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
