import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Menu,
  Chip,
  CardActionArea,
  MenuItem,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PendingIcon from "@mui/icons-material/Pending";

const ListItemWithMenu = ({ title, subtitle, flags, content, alertCustom }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function status(status) {
    switch (status.status) {
      case "approved":
        return {
          value: "Pagamento aprovado",
          icon: <DoneIcon />,
          color: "success",
          variant: "contained",
        };
      case "pending":
        return {
          value:
            new Date(status.dataExp) > new Date()
              ? "Pendente de pagamento"
              : "Pagamento expirado",
          color:
            new Date(status.dataExp) > new Date() ? "primary" : "secondary",
          variant:
            new Date(status.dataExp) > new Date() ? "outlined" : "contained",
        };
      case "cancelled":
        return {
          value: "Cancelado",
          color: "secondary",
          variant: "contained",
        };
      case "reimbursementrequired":
        return {
          value: "Reembolso requerido",
          color: "secondary",
          variant: "contained",
        };
      case "reimbursement":
        return {
          value: "Reembolsado",
          color: "secondary",
          variant: "contained",
        };
      case "gratuito":
        return {
          value: "Evento Gratuito",
          icon: <DoneIcon />,
          color: "success",
          variant: "contained",
        };
      case "confirmed":
        return {
          value: "Concluído",
          icon: <DoneIcon />,
          color: "primary",
          variant: "contained",
        };
      default:
        return {
          value: "Status desconhecido",
          icon: <ErrorOutlineIcon />,
          color: "warning",
          variant: "contained",
        };
    }
  }

  return (
    <ListItem
      sx={{
        margin: "5px 0",
        background: "#f0f0f0",
        borderRadius: "5px",
      }}
    >
      <ListItemText
        primary={title}
        secondary={subtitle}
        sx={{ whiteSpace: "pre-wrap" }}
      />
      <div>
        {content.map((item) => {
          return (
            <Chip
              sx={{ padding: "0 2px", margin: "10px" }}
              onDelete={status(item).icon ? () => console.log("") : undefined}
              color={status(item).color}
              variant={status(item).variant}
              size="small"
              label={status(item).value}
              deleteIcon={status(item).icon}
            />
          );
        })}
      </div>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          onClick={(e) => {
            if (flags && !["confirmed", "cancelled"].includes(flags[0].value)) {
              handleMenuOpen(e);
            } else {
              flags && flags[0].value == "cancelled"
                ? alertCustom("Indisponível!")
                : alertCustom("Evento Concluído!");
            }
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {flags &&
            flags.map((flag, index) => (
              <>
                <MenuItem
                  key={index}
                  onClick={flag.function}
                  color="textSecondary"
                >
                  {flag.value}
                </MenuItem>
                {flags.length - 1 != index && <Divider />}
              </>
            ))}
        </Menu>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ListItemWithMenu;
