import React, { useEffect, useState } from "react";
import { initMercadoPago, Payment } from "@mercadopago/sdk-react";
import Dialog from "./Dialog";
import axios from "../../../../utils/configAxios";
import DataAttemp from "../../../../utils/DataAttemp";
import { format } from "date-fns";

const REACT_APP_URL_BIBLIOTECA_RUNNERS_HTTPS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS_HTTPS;
const REACT_APP_EXTERNAL_REFERENCE_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_EXTERNAL_REFERENCE_BIBLIOTECA_RUNNERS;
const REACT_APP_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

initMercadoPago(REACT_APP_PUBLIC_KEY, { locale: "pt-BR" });

const CheckoutMercadoPago = ({
  eventoData,
  dadosCadastro,
  financeiro,
  themeType,
  alertCustom,
}) => {
  const [response, setResponse] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const dataMaganer = DataAttemp();

  const handleOpenDialog = (respostas) => {
    setResponse(respostas.data);
    setOpenDialog(true);
  };

  let addressParts = [];
  if (dataMaganer.userData.endereco) {
    addressParts = dataMaganer.userData.endereco
      .split(",")
      .map((part) => part.trim());
  }

  const initialization = dataMaganer.userData &&
    dataMaganer.userData.nome && {
      amount: Number(financeiro.valorTotal).toFixed(2),
      description: `Ingresso Platorma Goiania Runners Tickets: ${eventoData.titulo}`,
      payer: {
        firstName: dataMaganer.userData.nome.split(" ")[0],
        lastName:
          dataMaganer.userData.nome.split(" ")[
            dataMaganer.userData.nome.split(" ").length - 1
          ],
        email: dataMaganer.userData.auth.email,
        identification: {
          type: "CPF",
          number: dataMaganer.userData.auth.cpf, // Adicionando CPF do usuário
        },
        address: {
          street_name: addressParts[0] || "", // Bairro
          street_number: "0", // Número da casa
          neighborhood: addressParts[0] || "", // Bairro
          city: addressParts[1] || "", // Cidade
          federal_unit: addressParts[2] || "", // Unidade Federativa (Estado)
        },
      },
      external_reference: REACT_APP_EXTERNAL_REFERENCE_BIBLIOTECA_RUNNERS,
    };

  const customization = {
    visual: {
      hideFormTitle: true,
      style: {
        theme: themeType,
        customVariables: {
          formBackgroundColor: "transparent",
          borderRadiusMedium: "5px",
        },
      },
    },
    paymentMethods: {
      ticket: "all",
      bankTransfer: "all",
      creditCard: "all",
      mercadoPago: "all",
    },
  };

  const onSubmit = async ({ selectedPaymentMethod, formData, customAlert }) => {
    return new Promise((resolve, reject) => {
      const dataEvento = new Date(eventoData.dataEvento);
      dataEvento.setHours(
        parseInt(eventoData.horario.split(":")[0]) + 24,
        parseInt(eventoData.horario.split(":")[1]),
        0,
        0
      );
      let cupomText = "Não adicionado";
      if (dadosCadastro.desconto) {
        cupomText = `${dadosCadastro.desconto.type == "valor" ? "R$" : ""}${
          dadosCadastro.desconto.valor
        }${dadosCadastro.desconto.type != "valor" ? "%" : ""} - ${
          dadosCadastro.desconto.nome
        }`;
      }

      axios
        .post(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS_HTTPS}/v1/pagamentos/cadastrar`,
          {
            body: {
              ...formData,

              external_reference:
                REACT_APP_EXTERNAL_REFERENCE_BIBLIOTECA_RUNNERS,
              notification_url:
                "https://srv488264.hstgr.cloud/v1/pagamentos/notifications",
            },
            amountNoTax: Number(financeiro.valTotalSemTaxa).toFixed(2),
            percDesconto: Number(dadosCadastro.percDesconto) ?? 0,
            cupomAplicado: cupomText,
            transacao: dadosCadastro.transacao,
            user: {
              id: dataMaganer.userData.id,
              nome: dataMaganer.userData.nome,
              email: dataMaganer.userData.auth.email,
            },
            docs: dadosCadastro.dependentes.map(({ doc }) => doc),
            evento: {
              id: eventoData.id,
              nome: eventoData.titulo,
              subtitle: eventoData.subTitulo,
              dataEvento: dataEvento,
              dataFinal: eventoData.dataFinal,
              horario: eventoData.horario,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          handleOpenDialog(response.data);
          resolve();
        })
        .catch((error) => {
          alertCustom(
            "Ocorreu um erro ao processar solicitação, tente mais tarde ou selecione outra forma de pagamento!"
          );
          reject();
        });
    });
  };

  const onError = async (error) => {
    console.log(error);
  };

  const onReady = async () => {};

  return (
    <>
      <Payment
        initialization={initialization}
        customization={customization}
        onSubmit={onSubmit}
        onReady={onReady}
        onError={onError}
      />
      {response && (
        <Dialog
          alertCustom={alertCustom}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          response={response}
        />
      )}
    </>
  );
};

export default CheckoutMercadoPago;
