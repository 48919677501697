import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box"; // Importando o Box do Material-UI
import axios from "../../../utils/configAxios";
import { validateEmail } from "../../../utils/formatFunctions";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

const RecuperarConta = ({ alertCustom, formData, setFormulario }) => {
  const navigate = useNavigate();
  const { email, agreedToTerms } = formData;
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verifica se o email é válido
    if (email.length === 0 || !validateEmail(email)) {
      alertCustom("Por favor, insira um endereço de email válido.");
      return;
    }

    // Verifica se o usuário concordou com os termos
    if (!agreedToTerms) {
      alertCustom("Para prosseguir, aceite os termos!");
      return;
    }

    setLoading(true);

    try {
      const data = {
        email: email,
      };
      const response = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/user/recuperar`,
        data
      );
      alertCustom(response.data.message);
      if (response.data?.data) {
        setEmailSent(true);
      }
    } catch (error) {
      alertCustom(
        error.response?.data?.error ||
          error.response?.data?.message ||
          "Erro ao recuperar conta"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setFormulario((prevFormData) => ({
      ...prevFormData,
      email: value,
    }));
    // Validate the email and update the error state if necessary
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        justifyContent: "space-between",
        flexDirection: "row",
        flexWrap: "wrap",
        padding: 2,
      }}
      component="form"
      onSubmit={handleSubmit}
      noValidate
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", marginTop: "20px" }}
          >
            Recuperação de conta
          </Typography>
        </Grid>
        <Grid item xs={12} alignContent="center">
          {" "}
          <Typography
            className="show-box"
            variant="body2"
            color="textSecondary"
            sx={{ textAlign: "center" }}
          >
            {emailSent
              ? "Não recebeu o e-mail? Verifique sua Caixa de SPAM ou tente novamente."
              : "Informe seu e-mail para recuperar sua conta."}
          </Typography>
        </Grid>
        <Grid item xs={12} alignContent="center">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                id="email"
                label="Email"
                value={email}
                onChange={handleEmailChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                fullWidth
                control={
                  <Checkbox
                    checked={agreedToTerms}
                    onChange={(e) =>
                      setFormulario((prevFormData) => ({
                        ...prevFormData,
                        agreedToTerms: e.target.checked,
                      }))
                    }
                  />
                }
                label={
                  <Typography variant="body1" color="textSecondary">
                    <span>
                      Concordo com os{" "}
                      <Link onClick={() => navigate("/termos")}>
                        termos e condições
                      </Link>
                    </span>
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} alignContent="end">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                disableElevation
                type="submit"
                variant="contained"
                color="primary"
                loading={loading}
                loadingPosition="end"
              >
                {emailSent ? "Reenviar" : "Recuperar Conta"}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                disableElevation
                color="primary"
                onClick={() => navigate("/login")}
                disabled={loading}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecuperarConta;
