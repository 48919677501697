import React, { useEffect, useState, useRef } from "react";
import { QrReader } from "react-qr-reader";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const QRCodeReader = () => {
  const [facingMode, setFacingMode] = useState("environment");
  const [key, setKey] = useState(Date.now());
  const [isScanning, setIsScanning] = useState(true);
  const videoRef = useRef(null);
  const [cameraStyle, setCameraStyle] = useState({});
  const navigate = useNavigate();
  const toggleFacingMode = () => {
    setFacingMode((prevFacingMode) => {
      console.log(prevFacingMode);
      return prevFacingMode === "environment" ? "user" : "environment";
    });

    setKey(Date.now());
  };

  const handleScan = (result, error) => {
    if (result && isScanning) {
      const qrCodeValue = result.text.trim();
      if (qrCodeValue) {
        const { transacao, eventoId } = JSON.parse(qrCodeValue);
        setIsScanning(false);
        window.location.href = `https://goianiarunners.com/jobs/${transacao}?evento=${eventoId}`;
      }
    }
  };

  useEffect(() => {
    toggleFacingMode();
  }, []);

  // useEffect(() => {
  //   if (videoRef.current) {
  //     const videoElement = videoRef.current.querySelector("#video");
  //     if (videoElement) {
  //       const width = videoElement.offsetWidth;
  //       const marginLeft = `calc(50vw - ${width}px / 2)`;
  //       setCameraStyle({ marginLeft });
  //     }
  //   }
  // }, []);

  const stopScanning = () => {
    setIsScanning(false);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        maxWidth: "900px",
        width: "100%",
        height: "100vh",
      }}
    >
      <Grid container sx={{ position: "relative" }}>
        <Grid item xs={12} md={12} className="videoRoot">
          {isScanning && (
            <QrReader
              key={key}
              onResult={handleScan}
              constraints={{ facingMode: "environment" }}
              style={{ display: "block", transform: "scaleX(-1)" }} // Inverte horizontalmente
              videoRef={videoRef}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            position: "absolute",
            bottom: 48,
            width: "100%",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              padding: 1,
              justifyContent: "center",
            }}
          >
            {/* <Grid item xs={12} md={7}>
              <Button
                fullWidth
                disableElevation
                variant="contained"
                color="primary"
                onClick={toggleFacingMode}
              >
                Trocar Câmera
              </Button>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                disableElevation
                variant="contained"
                color="secondary"
                onClick={() => navigate("/dash/dashboard")}
                className="tour_20"
              >
                Voltar para Dashboard
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QRCodeReader;
