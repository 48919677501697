import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Search from "../../../components/Search/";
import Card from "../../../components/Cards/CardEvento";
import Button from "@mui/material/Button";
import { CircularProgress, Paper, Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../../../utils/configAxios";
import RunTimeIcon from "../../../images/run_time_icon.png";
import Carousel from "../../../components/Carrosel/images";
const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

export default function BasicGrid({
  dadosCarrossel,
  eventoShow,
  setEvento,
  setIsModalOpen,
  alertCustom,
  setLoadingOneEvent,
}) {
  const navigate = useNavigate();
  const [visibleCards, setVisibleCards] = useState(8);
  const [eventosList, setEventosList] = useState([]);
  const [filtredEventos, setFiltredEventos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [senha, setSenha] = useState(null);
  const isMobile = window.innerWidth <= 600;

  useEffect(() => {
    const hash = localStorage.getItem("senhaLink");
    if (window.location.href.includes("password")) {
      const pass = window.location.href
        .split("?")[1]
        .replace(/password|[^a-zA-Z0-9]/g, "");

      setSenha(pass);
      localStorage.setItem("senhaLink", pass);
    } else if (hash) {
      setSenha(hash);
    } else {
      setSenha("vazio");
    }
  }, []);

  useEffect(() => {
    if (
      eventoShow != "firstAcess" &&
      eventosList.length > 0 &&
      filtredEventos.length > 0 &&
      eventoShow &&
      senha != null
    ) {
      const eventoShowRemaped = eventoShow.split("?")[0];
      const idEvento = eventoShowRemaped.replace(/\D/g, "");
      const palavrasChave = eventoShowRemaped
        .split("-")
        .filter((item) => !!item);

      const eventoEncontrado = eventosList.find(
        (evento) =>
          evento.id === Number(idEvento) ||
          palavrasChave.every((palavra) =>
            evento.titulo
              .replace(/[^a-zA-Z\u00C0-\u017F\s]/g, "")
              .toLowerCase()
              .split(" ")
              .filter((item) => !!item)
              .includes(palavra)
          )
      );

      if (eventoEncontrado) {
        if (eventoEncontrado.status) {
          handleSelectEvento(eventoEncontrado);
        } else {
          alertCustom("Evento indisponível!");
          localStorage.removeItem("senhaLink");
        }
      } else {
        alertCustom(
          "Evento que você tentou acessar não existe, veja mais no feed!"
        );
      }
    }
  }, [eventosList]);

  const handleShowMore = () => {
    setVisibleCards((prevVisibleCards) =>
      Math.min(prevVisibleCards + 8, filtredEventos.length)
    );
  };

  const handleSelectEvento = async (evento) => {
    alertCustom("Aguarde, carregando...");
    if (evento) {
      setLoadingOneEvent(true);
      setIsModalOpen(true);

      try {
        const senha = localStorage.getItem("senhaLink");
        navigate(`/home/${evento.id}`);
        const { data } = await axios.post(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/evento/buscar-evento`,
          {
            idEvento: evento.id,
            force: true,
            senha: senha,
          }
        );
        setEvento(data.evento);
      } catch (error) {
        console.log(error);
        alertCustom("Ocorreu um erro ao exibir o evento!");
      }
      setLoadingOneEvent(false);
    }
    alertCustom("Aguarde, carregando...", true);
  };

  const handleShowLess = () => {
    setVisibleCards((prevVisibleCards) => Math.max(prevVisibleCards - 5, 5));
  };

  return (
    <>
      {" "}
      {senha !== null && isMobile && (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            marginTop: "60px",
            padding: "10px",
          }}
        >
          <Search
            senha={senha}
            eventosList={eventosList}
            setFiltredEventos={setFiltredEventos}
            setEventosList={setEventosList}
            alertCustom={alertCustom}
            setLoading={setLoading}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          padding: "10px",
          marginTop: isMobile ? "0" : "60px",
        }}
      >
        {dadosCarrossel && dadosCarrossel.length > 0 && (
          <Carousel type={"image"} ArrayItems={dadosCarrossel} />
        )}
      </Grid>{" "}
      {senha !== null && !isMobile && (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            padding: "10px",
          }}
        >
          <Search
            senha={senha}
            eventosList={eventosList}
            setFiltredEventos={setFiltredEventos}
            setEventosList={setEventosList}
            alertCustom={alertCustom}
            setLoading={setLoading}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          padding: "10px 15px",
          display: "flex",
          order: { md: 0, xs: 999 },
        }}
      >
        <img
          src={RunTimeIcon}
          style={{
            width: isMobile ? "40px" : "50px",
            height: isMobile ? "40px" : "50px",
            opacity: "55%",
          }}
        />
        <Typography
          variant="h6"
          sx={{ alignContent: "center", fontSize: isMobile ? "20px" : "18px" }}
          color="textSecondary"
        >
          Calendário de eventos
        </Typography>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          justifyContent: { md: "start", xs: "center" },
          padding: 1,
          alignContent: "start",
        }}
      >
        {loading ? (
          [0, 0, 0, 0].map(() => (
            <Grid item xs={12} md={3}>
              <Skeleton
                variant="rounded"
                width="100%"
                sx={{ minHeight: "350px" }}
              />
            </Grid>
          ))
        ) : Array.isArray(filtredEventos) &&
          filtredEventos.filter((evento) => evento.status == true).length ==
            0 ? (
          <Grid item xs={12} md={12}>
            <Typography
              className="show-box-dash"
              color="textSecondary"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              Olá atleta, sem eventos por agora...
            </Typography>
          </Grid>
        ) : (
          filtredEventos
            .filter((evento) => evento.status == true)
            .slice(0, visibleCards)

            .map((evento, index) => (
              <Grid key={index} item xs={12} md={3} sx={{ padding: "10px 0" }}>
                <Card
                  evento={evento}
                  index={index}
                  handleSelectEvento={handleSelectEvento}
                />
              </Grid>
            ))
        )}
      </Grid>
      <Grid
        container
        justifyContent="center"
        marginTop={2}
        sx={{ minHeight: "1500px", alignContent: "flex-start" }}
      >
        {visibleCards <
          filtredEventos.filter((evento) => evento.status == true).length && (
          <Button variant="contained" disableElevation onClick={handleShowMore}>
            Ver Mais
          </Button>
        )}
        {visibleCards > 8 && (
          <Button variant="contained" disableElevation onClick={handleShowLess}>
            Ver Menos
          </Button>
        )}
      </Grid>
    </>
  );
}
