import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Typography,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material/";
import DataAttemp from "../../utils/DataAttemp"

const ConfirmDialog = ({ open, route, title, message, onConfirm, onClose,justificativa }) => {
const dataManager = DataAttemp()
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2" color="textSecondary">
          {message}
        </Typography>
      </DialogTitle>
{justificativa && <DialogContent>
  <Typography variant="body2" color="textSecondary">Informe logo abaixo o motivo pel</Typography>
  
  </DialogContent>}
    
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button fullWidth disableElevation onClick={onConfirm} variant="text">
          {(route == "/home" || route == "/login") && "Voltar à navegação"}
          {route == "/perfil/complete" && "Completar cadastro"}
          {route == "/cadastro" && "Tentar novamente"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
