import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import LogoutIcon from "@mui/icons-material/Logout";
import { MainListItems, SecondaryListItems } from "./ListItems";
import NovoEvento from "./Cadastrar/";
import PagamentosPage from "../CadastroPage/Steps/Pagamentos";
import Forms from "./Forms";
import Organizadores from "./Organizadores";
import Produtos from "./Produtos";
import Dashboard from "./Dashboard";
import DataAttemp from "../../utils/DataAttemp";
import { useNavigate, useParams } from "react-router-dom";
import ParticipantesPage from "./Participante";
import InscricoesMassivas from "./Tools/Inscricoes Massivas";
import Volumetria from "./Volumetria";
import { useMediaQuery } from "@mui/material";
import MarcaAg from "../../images/marcaAg.png";
import Configuracoes from "./Configuracoes";
import OnboardManager from "./Onboard/";
import Notifications from "./Tools/Notifications";
import AccessManager from "../Jobs/AccessManager";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import ImageFilter from "../../images/logo_contrast.png";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import Tour from "./Tools/Tour/";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `100%`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth - 1}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    position: "relative",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: `transparent`,
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(0),
    }),
  },
}));

const EmDesenvolvimento = () => {
  return (
    <Typography
      className="show-box"
      style={{ width: "100%" }}
      color="textSecondary"
    >
      Em Desenvolvimento
    </Typography>
  );
};

export default function Main({ alertCustom }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { local } = useParams();
  const [open, setOpen] = useState(!isMobile);
  const dataManager = DataAttemp();
  const [selectedComponent, setSelectedComponent] = useState("dashboard");
  const [isTransitionEnd, setIsTransitionEnd] = useState(isMobile);
  const [anchorEl, setAnchorEl] = useState(null);

  const componentMap = {
    dashboard: <Dashboard alertCustom={alertCustom} />,
    novoEvento: <NovoEvento alertCustom={alertCustom} />,
    gerenciarInscricoes: <ParticipantesPage alertCustom={alertCustom} />,
    pagamentos: <PagamentosPage />,
    formularios: <Forms alertCustom={alertCustom} />,
    organizadores: <Organizadores alertCustom={alertCustom} />,
    produtos: <Produtos alertCustom={alertCustom} />,
    configuracoes: <Configuracoes alertCustom={alertCustom} />,
    configuracoesManager: <OnboardManager alertCustom={alertCustom} />,
    notificacoes: <Notifications alertCustom={alertCustom} />, //<InscricoesMassivas alertCustom={alertCustom} />, //<Seletor />, //<Configuracoes alertCustom={alertCustom} />,
    accessManager: <AccessManager alertCustom={alertCustom} />,
    volumetria: <Volumetria alertCustom={alertCustom} />,
  };

  const handleListItemClick = (componentKey) => {
    setSelectedComponent(componentKey);
  };

  const handleDrawerTransitionEnd = () => {
    setIsTransitionEnd(true);
  };

  const toggleDrawer = () => {
    setOpen(!open);
    setIsTransitionEnd(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Tour />
      <CssBaseline />
      <AppBar position="absolute" open={open} elevation={0} color="terciary">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            style={{ marginRight: "20px" }}
            onClick={toggleDrawer}
            className="tour_3"
          >
            {!open && isTransitionEnd ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
          <Box sx={{ flexGrow: 0 }} spacing={2}>
            <IconButton
              onClick={handleClick}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                className="tour_2"
                size="small"
                sx={{
                  width: 40,
                  height: 40,

                  bgcolor: "#0c8ce9",
                  color: "#fff",
                }}
              >
                {dataManager && dataManager.userData.nome
                  ? dataManager.userData.nome[0].toUpperCase()
                  : ""}
              </Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                }}
              >
                <ListItemIcon sx={{ marginRight: "10px" }}>
                  <Avatar sx={{ bgcolor: "#0c8ce9", color: "#fff" }}>
                    {dataManager && dataManager.userData.nome
                      ? dataManager.userData.nome[0].toUpperCase()
                      : ""}
                  </Avatar>
                </ListItemIcon>
                <Typography variant="body1">
                  {dataManager && dataManager.userData.nome
                    ? `Bem vindo, ${dataManager.userData.nome}!`
                    : "Bem vindo"}
                  <Typography variant="body2" color="textSecondary">
                    Perfil administrativo
                  </Typography>
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => navigate("/home")}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                Página incial
              </MenuItem>
              <MenuItem onClick={() => navigate("/pricing")}>
                <ListItemIcon>
                  <LocalMallOutlinedIcon />
                </ListItemIcon>
                Vendas e taxas
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Sair
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
        <Divider />
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        onTransitionEnd={handleDrawerTransitionEnd}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            maxHeight: "10vh",
            position: "relative",
          }}
        >
          {" "}
          <Typography
            variant="h2"
            sx={{
              position: "absolute",
              color: "rgba(0,0,0,0.05)",
              fontWeight: 600,
              top: -10,
              left: 5,
              zIndex: 0,
            }}
          >
            <img src={ImageFilter} style={{ width: "50px" }} />
          </Typography>
          {/* <Typography
            variant="body1"
            sx={{
              color: "rgba(0,0,0,0.2)",
              fontWeight: 600,
              position: "absolute",
              top: 23,
              left: 60,
              zIndex: 1,
            }}
          >
            {localStorage.organizador
              ? JSON.parse(localStorage.organizador)?.nome
              : "Eventos & Esportes"}
          </Typography> */}
        </Toolbar>
        <Divider />
        <List
          component="nav"
          style={{
            maxHeight: "89vh",
            height: "100%",
            overflowY: "auto",
          }}
        >
          {" "}
          <MainListItems
            route={local}
            AccessType={localStorage.getItem("acessType")}
            opened={open}
            onItemClick={handleListItemClick}
            alertCustom={alertCustom}
          />
          {/* <Divider sx={{ my: 1 }} />
          {SecondaryListItems()} */}
        </List>
      </Drawer>
      <Box
        onClick={() => isMobile && setOpen(false)}
        className={isMobile ? "" : "allow-scroll"}
        component="main"
        sx={{
          background: `url(${MarcaAg})`,
          backgroundColor: "#fff",
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ padding: "10px" }}>
          <Grid container sx={{ minWidth: "300px", maxWidth: "100vw" }}>
            <Grid item xs={12} md={12}>
              {selectedComponent in componentMap &&
                componentMap[selectedComponent]}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
