import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { arrayMoveImmutable } from "array-move";
import CardHeader from "@mui/material/CardHeader";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import DataAttemp from "../../../utils/DataAttemp";
import UploadDialog from "./UploadDialog";
import axios from "../../../utils/configAxios";

const REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM;

const DragHandle = sortableHandle(({ children }) => (
  <span style={{ cursor: "grab" }}>{children}</span>
));

const SortableItem = SortableElement(({ file, index, onRemove }) => {
  return (
    <Grid item xs={12} md={6} sx={{ position: "relative", cursor: "move" }}>
      <DragHandle>
        <img
          src={
            file.file
              ? URL.createObjectURL(file.file)
              : `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/images?file=Carrossel/${file.image}`
          }
          alt={file.titulo}
          style={{
            width: "100%",
            borderRadius: "10px",
          }}
        />
      </DragHandle>
      <IconButton
        sx={{
          position: "absolute",
          zIndex: 10,
          right: "8px",
          top: "16px",
          background: "rgba(256,256,256,0.1)",
        }}
        onClick={(event) => {
          event.stopPropagation();
          onRemove(file.id);
        }}
      >
        <CloseIcon />
      </IconButton>
    </Grid>
  );
});

const SortableList = SortableContainer(
  ({ items, onSortEnd, onRemove, handleOpenDialog }) => {
    return (
      <Grid container spacing={1} sx={{ margin: 1, alignItems: "stretch" }}>
        <Grid item xs={12} md={6}>
          <ListItem
            button={true}
            onClick={handleOpenDialog}
            sx={{ height: "100%", borderRadius: "10px" }}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={"Adicionar Imagem"}
              secondary={"Carrossel principal"}
            />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={12}>
          <CardHeader
            className="show-box"
            avatar={<ReportProblemIcon fontSize="small" />}
            title={
              <Typography variant="body2">
                Certifique-se quanto ao formato e dimensões dos arquivos,
                procure usar png, jpeg ou jpg's para imagens!
                <Typography color="textSecondary" variant="body2">
                  É possível arrastar as imagens para reordenar sua exibição
                </Typography>
              </Typography>
            }
          />
        </Grid>
        {items &&
          items.length > 0 &&
          items.map((file, index) => (
            <SortableItem
              key={file.id}
              index={index}
              file={file}
              onRemove={onRemove}
            />
          ))}
      </Grid>
    );
  }
);

const ConfigPage = ({ alertCustom }) => {
  const [uploadFilesExtras, setUploadFilesExtras] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filesExtrasToRemove, setFilesExtrasToRemove] = useState([]);
  const dataManager = DataAttemp();
  const isMobile = window.innerWidth <= 600;

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/config/listar-banners-image`
      );

      // Adiciona um campo de ID único para cada item usando o índice
      const filesWithIds = response.data.urls.map((file, index) => ({
        ...file,
        id: index, // Usando o índice como identificador único
        order: index, // Atributo 'order' inicialmente igual ao índice
      }));

      setUploadFilesExtras(filesWithIds);
    } catch (error) {
      console.error("Erro ao carregar conteúdo do site:", error);
      alertCustom("Erro ao carregar conteúdo do site!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRemoveFile = (id) => {
    // Encontra o arquivo a ser removido pelo ID
    const fileToRemove = uploadFilesExtras.find((file) => file.id === id);

    if (!fileToRemove) {
      return;
    }

    // Se o arquivo a ser removido NÃO tiver a propriedade 'file', significa que é um arquivo existente no backend e deve ser adicionado à lista de arquivos a serem removidos
    if (!fileToRemove.file) {
      setFilesExtrasToRemove((prev) => [...prev, fileToRemove]);
    }

    // Atualiza a lista de arquivos exibidos removendo o arquivo com o ID correspondente
    const updatedFiles = uploadFilesExtras.filter((file) => file.id !== id);
    setUploadFilesExtras(updatedFiles);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedFiles = arrayMoveImmutable(
      uploadFilesExtras,
      oldIndex,
      newIndex
    ).map((file, index) => ({
      ...file,
      order: index,
    }));
    setUploadFilesExtras(updatedFiles);
  };

  const handleUploadFilesExtras = async () => {
    setLoading(true);

    try {
      if (uploadFilesExtras.length === 0) {
        setLoading(false);
        return;
      }

      const formData = new FormData();
      const newUrls = [];

      uploadFilesExtras.forEach((file) => {
        if (file.file) {
          formData.append("fotos", file.file, `${file.titulo}.${file.type}`);
        } else {
          newUrls.push({
            image: file.image,
            describe: file.campo,
            link: file.link,
            order: file.order,
          });
        }
      });

      let request = "";
      if (filesExtrasToRemove.length > 0) {
        request = `?anteriores=${JSON.stringify(
          filesExtrasToRemove.map((file) => file.image)
        )}`;
      }

      const response = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/upload/Carrossel${request}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      uploadFilesExtras.forEach((file, index) => {
        const fileAtual = response.data.fileNames.find((item) =>
          item.includes(file.titulo)
        );
        if (fileAtual) {
          newUrls.push({
            image: fileAtual,
            describe: file.campo,
            link: file.link,
            order: file.order,
          });
        }
      });

      // response.data.fileNames.forEach((fileName, index) => {
      //   newUrls.push({
      //     image: fileName,
      //     describe: uploadFilesExtras[index].campo,
      //     link: uploadFilesExtras[index].link,
      //     order: uploadFilesExtras[index].order,
      //   });
      // });

      await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/config/atualizar-banners-image?token=${dataManager.userData?.auth?.token}`,
        { urls: newUrls }
      );

      alertCustom("Banners atualizados com sucesso!");
      setFilesExtrasToRemove([]);
      fetchData();
    } catch (error) {
      console.error("Erro ao enviar arquivos:", error);
      alertCustom("Erro ao enviar arquivos!");
    }

    setLoading(false);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  return (
    <Grid container spacing={1} className="unselectable">
      <Grid item xs={12} md={12}>
        <Typography variant="h6">
          Imagens
          <Typography variant="body2" color="textSecondary">
            Banners e exibição de conteúdo
          </Typography>
        </Typography>
      </Grid>

      <SortableList
        handleOpenDialog={handleOpenDialog}
        items={uploadFilesExtras}
        onSortEnd={onSortEnd}
        onRemove={handleRemoveFile}
        axis="xy"
        pressDelay={isMobile ? 100 : 10}
        useDragHandle
      />

      <Grid item xs={12} md={10}></Grid>
      <Grid item xs={12} md={2}>
        {uploadFilesExtras && uploadFilesExtras.length > 0 && (
          <Button
            fullWidth
            disableElevation
            variant="contained"
            color="success"
            disabled={loading}
            onClick={handleUploadFilesExtras}
          >
            Salvar
          </Button>
        )}
      </Grid>

      <UploadDialog
        setUploadFilesExtras={setUploadFilesExtras}
        alertCustom={alertCustom}
        uploadFilesExtras={uploadFilesExtras}
        handleCloseDialog={handleCloseDialog}
        open={open}
        setFilesExtrasToRemove={setFilesExtrasToRemove}
      />
    </Grid>
  );
};

export default ConfigPage;
