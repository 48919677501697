import { useState, useEffect } from "react";

const useUserData = () => {
  const [userData, setUserData] = useState(() => {
    try {
      const acessType = localStorage.getItem("acessType");
      const dados = JSON.parse(localStorage.getItem("userData"));
      if (
        dados &&
        dados.nome &&
        dados.auth &&
        dados.auth.email &&
        dados.auth.cpf &&
        dados.id &&
        dados.tema
      ) {
        return { ...dados, acessType: acessType };
      } else {
        throw new Error("Dados do usuário incompletos");
      }
    } catch (error) {
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.removeItem("acessType");
      return null;
    }
  });
  return { userData };
};

export default useUserData;
