import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PeopleIcon from "@mui/icons-material/People";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

export const MainListItems = (props) => {
  const { AccessType, onItemClick, opened, alertCustom, disable, route } =
    props;

  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState("dashboard");

  const handleClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    const rotasPermitidas = [
      ...collapsedcAssets[AccessType],
      ...(AccessType === "@adm" ? adminItems : managerItems),
    ];

    const rotaPermitida = rotasPermitidas.find(({ id }) => id === route);
    if (!rotaPermitida) {
      navigate("/login");
    } else {
      onItemClick(route);
      setActiveItem(route);
    }
  }, [route]);

  const handleItemClick = (item) => {
    const rotasPermitidas = [
      ...collapsedcAssets[AccessType],
      ...(AccessType === "@adm" ? adminItems : managerItems),
    ];

    const rotaPermitida = rotasPermitidas.find(({ id }) => id === route);
    if (!rotaPermitida) {
      navigate("/login");
    } else {
      navigate(`/dash/${item}`);
      onItemClick(item);
      setActiveItem(item);
    }
  };

  const adminItems = [
    { text: "Dashboard", icon: <DashboardIcon />, id: "dashboard" },
    // { text: "Novo Evento", icon: <EmojiEventsIcon />, id: "novoEvento" },
    {
      text: "Gerenciar Perfis",
      icon: <ManageAccountsIcon />,
      id: "accessManager",
    },
    { text: "Inscrições", icon: <PeopleIcon />, id: "gerenciarInscricoes" },
    // {
    //   text: "Inscrições Massivas",
    //   icon: <GroupAddIcon />,
    //   id: "inscricoesMassivas",
    // }, // Moved here for admin
    {
      text: "Volumetria",
      icon: <CurrencyExchangeRoundedIcon />,
      id: "volumetria",
    },
    { text: "Notificações", icon: <EmailRoundedIcon />, id: "notificacoes" },
    { text: "Configurações", icon: <SettingsIcon />, id: "configuracoes" },

    {
      text: "Escanear",
      icon: <QrCodeScannerRoundedIcon />,
      id: "scan",
      onClick: () =>
        isMobile
          ? navigate("/jobs/scan")
          : alertCustom("Recomendamos que acesse esse recurso por um celular"),
    },
  ];
  const collapsedcAssets = {
    "@adm": [
      {
        text: "Organizadores",
        icon: <AssignmentIcon />,
        id: "organizadores",
        onClick: () => handleItemClick("organizadores"),
      },
    ],
    "@manager": [
      {
        text: "Formulários",
        icon: <AssignmentIcon />,
        id: "formularios",
        onClick: () => handleItemClick("formularios"),
      },
      {
        text: "Produtos",
        icon: <AssignmentIcon />,
        id: "produtos",
        onClick: () => handleItemClick("produtos"),
      },
    ],
  };

  const managerItems = [
    { text: "Dashboard", icon: <DashboardIcon />, id: "dashboard" },
    { text: "Novo Evento", icon: <EmojiEventsIcon />, id: "novoEvento" },
    { text: "Inscrições", icon: <PeopleIcon />, id: "gerenciarInscricoes" },

    { text: "Notificações", icon: <EmailRoundedIcon />, id: "notificacoes" },
    {
      text: "Configurações",
      icon: <SettingsIcon />,
      id: "configuracoesManager",
    },
    {
      text: "Escanear",
      icon: <QrCodeScannerRoundedIcon />,
      id: "scan",
      onClick: () =>
        isMobile
          ? navigate("/jobs/scan")
          : alertCustom("Recomendamos que acesse esse recurso por um celular"),
    },
  ];

  const items = AccessType === "@adm" ? adminItems : managerItems;

  return (
    <React.Fragment>
      {items.map((item) => (
        <ListItemButton
          sx={{ margin: "8px", borderRadius: "8px" }}
          key={item.id}
          onClick={() =>
            item.onClick ? item.onClick() : handleItemClick(item.id)
          }
          selected={activeItem === item.id}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItemButton>
      ))}
      <ListItemButton
        onClick={handleClick}
        sx={{ margin: "8px", borderRadius: "8px" }}
      >
        <ListItemIcon>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
        <ListItemText primary="Componentes" />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {" "}
        <List component="div" disablePadding>
          {collapsedcAssets[AccessType].map((item) => (
            <ListItemButton
              sx={{ pl: 4, margin: "8px", borderRadius: "8px" }}
              onClick={item.onClick}
              selected={activeItem === item.id}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          ))}{" "}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
