import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
  useTheme,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "../../../utils/configAxios";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import UploadDanfe from "./Upload";

const ModalDetalhes = ({ open, onClose, data, alertCustom, action }) => {
  const [qrCode, setQrCode] = useState("");
  const [qrCodeLoading, setQrCodeLoading] = useState(false);
  const [qrCodeCopiaCola, setQrCodeCopiaCola] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    const gerarPix = async () => {
      setQrCodeLoading(true);
      if (data?.chavePix) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/gerar-pix/${data.OID}?token=${data.key}&amount=${data.totalPagamentosSemTaxa}`
          );
          setQrCode(response.data.qrCode);
          setQrCodeCopiaCola(response.data.payload);
        } catch (error) {
          console.error("Erro ao gerar o QR code do Pix:", error);
        }
      }
      setQrCodeLoading(false);
    };

    gerarPix();
  }, [data]);

  const handleGerarDanfe = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/organizadores/gerarDanfe/${data.id}`
      );
      // Lidar com a resposta para gerar o DANFE
      console.log(response.data);
    } catch (error) {
      console.error("Erro ao gerar DANFE:", error);
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      async () => alertCustom("Texto copiado para a área de transferência!"),
      (err) => console.error("Erro ao copiar texto: ", err)
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={isMobile}
      maxWidth="md"
      PaperProps={{
        style: {
          minHeight: "90vh",
        },
      }}
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        Detalhes do Pagamento
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ height: isMobile ? "calc(100vh - 64px)" : "auto" }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={6}
            style={{
              textAlign: "center",
              marginBottom: "16px",
            }}
          >
            <Typography variant="h6">
              Total: R$ {Number(data?.totalPagamentosSemTaxa || 0).toFixed(2)}
            </Typography>
          </Grid>{" "}
          <Grid item xs={6}></Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {qrCode ? (
              <div style={{ textAlign: "center", width: "350px " }}>
                <img
                  src={qrCode}
                  alt="QR Code Pix"
                  style={{ width: "250px", height: "auto" }}
                />
                <Typography
                  variant="body1"
                  color="textSecondary"
                  className="show-box"
                  sx={
                    data?.credited && {
                      background: "#17C64F",
                      color: "#fff",
                    }
                  }
                >
                  {data?.credited
                    ? "Pagamento já realizado!"
                    : "Antes de realizar o pagamento, verifique as informações do destinatário!"}
                </Typography>
              </div>
            ) : qrCodeLoading ? (
              <Typography variant="body1" color="textSecondary">
                Gerando QR Code do Pix...
              </Typography>
            ) : (
              <Typography variant="body1" color="textSecondary">
                Sem chave pix cadastrada!
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6} style={{}}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  textAlign: "left",
                  marginTop: "16px",
                  marginLeft: "16px",
                  marginBottom: "16px",
                }}
                className="show-box"
              >
                <Typography variant="h6">
                  {" "}
                  {data?.organizador?.nome || "Não disponível"}
                </Typography>

                <Typography variant="body1">
                  {data?.organizador?.contato || "Contato Responsável"} -{" "}
                  {data?.organizador?.email || "E-mail não disponível"}
                </Typography>
                <Typography variant="body1">
                  {data?.organizador?.telefone || "Telefone não disponível"}
                </Typography>
                <Typography variant="body1">
                  {data?.organizador?.site || "Site não disponível"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Pix Copia e Cola"
                  disabled={!qrCode && !qrCodeLoading}
                  value={qrCodeCopiaCola || ""}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <IconButton
                        onClick={() => handleCopy(qrCodeCopiaCola || "")}
                      >
                        <Tooltip title="Copiar">
                          <ContentCopyIcon />
                        </Tooltip>
                      </IconButton>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Banco"
                  value={data?.banco || ""}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <IconButton onClick={() => handleCopy(data?.banco || "")}>
                        <Tooltip title="Copiar">
                          <ContentCopyIcon />
                        </Tooltip>
                      </IconButton>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Agência"
                  value={data?.agencia || ""}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <IconButton
                        onClick={() => handleCopy(data?.agencia || "")}
                      >
                        <Tooltip title="Copiar">
                          <ContentCopyIcon />
                        </Tooltip>
                      </IconButton>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Conta"
                  value={data?.conta || ""}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <IconButton onClick={() => handleCopy(data?.conta || "")}>
                        <Tooltip title="Copiar">
                          <ContentCopyIcon />
                        </Tooltip>
                      </IconButton>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!data?.credited && (
          <Button
            endIcon={<DoneIcon />}
            onClick={() => {
              onClose();
              action("markPaid", data.eventoId);
            }}
            color="success"
            disableElevation
            variant="contained"
          >
            Marcar Como Pago
          </Button>
        )}

        <UploadDanfe alertCustom={alertCustom} data={data} />
        {/* <Button
          endIcon={<PictureAsPdfIcon />}
          onClick={handleGerarDanfe}
          color="primary"
          disableElevation
          variant="contained"
        >
          Gerar DANFE
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default ModalDetalhes;
