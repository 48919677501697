import React from "react";

const LinkOrText = ({ value, alertCustom }) => {
  const isEmail = (value) => /\S+@\S+\.\S+/.test(value);
  const isLink = (value) => /^(https?:\/\/)/.test(value);

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(value);
    alertCustom("Email copiado para a área de transferência!");
  };

  if (isEmail(value)) {
    return (
      <span
        style={{
          color: "#0c8ce9",
          cursor: "pointer",
        }}
        onClick={handleCopyEmail}
      >
        {value}
      </span>
    );
  } else if (isLink(value)) {
    return (
      <a
        href={value}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#0c8ce9", textDecoration: "underline" }}
      >
        {value}
      </a>
    );
  } else {
    return <span>{value}</span>;
  }
};

export default LinkOrText;
