import React, { useEffect, useState } from "react";
import axios from "../../../../utils/configAxios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const { REACT_APP_URL_BIBLIOTECA_RUNNERS } = process.env;

const ProductCards = ({ tabela }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_URL_BIBLIOTECA_RUNNERS}/v1/produto/buscar-tabela/${tabela}`
        );
        setProducts(response.data);
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      }
    };

    fetchProducts();
  }, [tabela]); // Adicionei 'tabela' como dependência para atualização quando mudar

  return (
    <Grid container spacing={2}>
      {products &&
        products.length > 0 &&
        products
          .filter((product) => product.status == true)
          .map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
              <Card elevation={0}>
                <CardContent sx={{ background: "rgba(0,0,0,0.02)" }}>
                  <Typography variant="h6" component="div">
                    {product.produto ? (
                      <>
                        {product.produto.nomeProduto}
                        {product.produto.variacoes &&
                          product.produto.variacoes.length > 0 &&
                          "   "}
                        {product.produto.variacoes &&
                          product.produto.variacoes.map((variacao, index) => (
                            <Typography
                              key={`${product.id}-${index}`}
                              variant="h6"
                              component="span"
                              color="text.secondary"
                            >
                              {variacao}
                              {index !== product.produto.variacoes.length - 1 &&
                                ", "}
                            </Typography>
                          ))}
                      </>
                    ) : (
                      "Produto Sem Nome"
                    )}
                  </Typography>
                  <Typography color="text.secondary">
                    {product.produto
                      ? product.produto.descricao
                      : "Descrição não disponível"}
                  </Typography>
                  <Typography color="text.secondary">
                    Preço: R$ {parseFloat(product.preco).toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
    </Grid>
  );
};

export default ProductCards;
