import React from "react";
import { Typography, Paper, Button, IconButton, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoFooter from "../../../images/logo_footer.jpg";
import LogoFooterDropCode from "../../../images/drop_code.png";

const TermosCondicoes = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <Paper
      variant="outlined"
      sx={{ margin: { md: 10, xs: 2 }, padding: { md: 10, xs: 2 } }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          sx={{ display: { md: "none", xs: "flex" } }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
        </IconButton>
        <div>Termos e Condições</div>
        <div></div>
        <Button
          fullWidth={isMobile}
          disableElevation
          color="primary"
          variant="text"
          sx={{ display: { xs: "none", md: "block" } }}
          onClick={() => navigate(-1)}
        >
          Voltar à navegação
        </Button>
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
        Bem-vindo aos Termos e Condições - GR eventos e esportes. Estes Termos
        descrevem condições gerais aplicáveis ao uso exclusivo do nosso site e
        dos nossos serviços. Ao acessar ou usar o nosso site, você concorda com
        o conteúdo deste proposto artigo, condições e com todas as leis e
        regulamentos aplicáveis. Se você não concorda com algum destes termos,
        não nos responsabilizamos sobre o uso para fins inadequados.
        <div>
          * Regulamentos paralelos exibidos nos eventos cadastrados acrescentam
          sobre estes termos e devem ser considerados no ato da inscrição.
        </div>
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Aceitação dos Termos
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
        Dado o momento de cadastro e uso da plataforma, você concorda em cumprir
        estes termos e expressamente com todas as disposições nele contidas.
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. Uso do Site
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
        O uso deste site está sujeito a certas restrições. Seu conteúdo visa
        informação geral e vendas. Garantimos a precisão, atualidade,
        desempenho, integridade, adequação das informações e materiais
        encontrados ou oferecidos neste site para finalidade específica de
        veicular eventos esportivos, sob nossa organização ou de outrem, venda
        de ingressos para participação em grupo ou individual de corridas
        urbanas, estando responsável aquele que possuir 18 anos ou mais, ou
        mediante responsável adulto, a qualquer idade, exceto em casos em que
        dada competição não seja adequada ou não possua categoria apropriada ao
        público mais jovem.
        <div>
          * O descumprimento do termo pressuposto acarretará em desclassificação
          e medidas internas dos que estiverem em desacordo aos critérios
          estabelecidos.
        </div>
      </Typography>
      <Typography variant="h6" gutterBottom>
        3. Reembolsos
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
        Reembolsos podem ser solicitados em caso de problemas com os serviços
        prestados. Embora a distribuição em etapas assegure contra erros,
        estamos comprometidos em fornecer um serviço de qualidade e resolver
        quaisquer problemas que possam surgir. Entre em contato conosco para
        obter assistência.
      </Typography>
      <Typography variant="h6" gutterBottom>
        4. Contato
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: "justify" }}>
        Se você tiver alguma dúvida sobre estes Termos, entre em contato conosco
        por e-mail{" "}
        <span style={{ fontWeight: "bold" }}> goanianiarunners@gmail.com</span>.
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ textAlign: "justify" }}>
        5. Seus dados
      </Typography>
      <Typography variant="body1" paragraph>
        Os dados fornecidos ao GR eventos e esportes são usados exclusivamente
        para personalizar sua experiência na corrida, garantir a sua segurança e
        dos outros participantes e implementar as políticas e conteúdos do site.
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ textAlign: "justify" }}
        >
          Comunicações podem ser feitas para o seu e-mail pessoal para fins de
          recuperação de conta, informações e atualizações importantes, além
          promoções em eventos. Assim como qualquer tipo de e-mail massivo,
          alguns de nossos e-mails podem se alocar na sua caixa de SPAM, pedimos
          que a verifique sempre para fins, por exemplo, de recuperação de
          conta, processo esse em que o e-mail é imprescindível.
          <div>
            * Caso haja perda de acesso ao e-mail cadastrado, visando a sua
            segurança, não é possível mudar ou recuperar a conta. Portanto, não
            nos responsabilizamos caso haja perda de acesso ao que fora
            cadastrado. É um serviço terceirizado e, portanto, não se estabelece
            sobre nossos domínios.
          </div>
        </Typography>
      </Typography>
      <Typography variant="body1" paragraph>
        <Grid container justifyContent={"center"}>
          <Grid item>
            <img
              style={{
                maxWidth: 150,
                marginBottom: "10px",
              }}
              src={LogoFooter}
              alt="Logo"
              onClick={() => navigate("/home")}
            />
          </Grid>{" "}
        </Grid>
      </Typography>
    </Paper>
  );
};

export default TermosCondicoes;
