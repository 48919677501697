import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const REACT_APP_URL_BIBLIOTECA_RUNNERS =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS;

export default function SimplifiedCard({
  handleSelectItem,
  item,
  image,
  alt,
  title,
  disabled = false,
}) {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [imagemCarregada, setImagemCarregada] = useState(true);

  const handleImagemError = () => {
    setImagemCarregada(false);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        position: "relative",
        borderRadius: "8px",
      }}
    >
      <CardActionArea
        disabled={disabled}
        style={{ zIndex: "1", borderRadius: "5px", position: "relative" }}
        variant="outlined"
        onClick={() => handleSelectItem(item)}
      >
        {imagemCarregada ? (
          <CardMedia
            component="img"
            image={image}
            alt={alt}
            style={{
              pointerEvents: "none",
              width: "100%",
              height: "auto",
              minHeight: "300px",
              objectFit: "cover",
            }}
            onError={handleImagemError}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
            bgcolor="#f0f0f0"
            sx={{ minHeight: "300px" }}
            color="text.secondary"
            fontSize={14}
          >
            Imagem não disponível
          </Box>
        )}

        <Grid
          container
          sx={{
            position: "absolute",
            bottom: 0,
            color: "#fff",
            background: "rgba(0,0,0,0.4)",
          }}
        >
          <Grid item xs={12} md={12} sx={{ margin: "8px 16px" }}>
            <Typography
              variant="button"
              sx={{
                fontWeight: "bold",
                fontSize: isMdScreen ? "16px" : "20px",
              }}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
