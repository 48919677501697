import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import { Link, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import LogoForce from "../../images/logo_force.png";
import LogoContrast from "../../images/logo_contrast.png";
import { Button, Grid, Divider, ListItemIcon } from "@mui/material";
import DataAttemp from "../../utils/DataAttemp";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ResponsiveAppBar = ({ contrast }) => {
  const isMobile = window.innerWidth <= 600;
  const [anchorEl, setAnchorEl] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const dataManager = DataAttemp();
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        maxHeight: "60px",
        zIndex: 999,
        color: scrollPosition > 0 ? "#fff" : "#30473F ",
        md: "#17c64f",
        background: "transparent",
      }}
    >
      <Toolbar
        sx={{
          transition: "background-color 0.3s ease-out",
          background: scrollPosition > 0 ? "#17c64f" : "transparent",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            maxWidth: { md: "1180px", xs: "100vw" },
            width: "calc(100vw - 5px)",
          }}
        >
          <Grid item md={2.4}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link to="/home">
                <img
                  src={scrollPosition > 0 ? LogoForce : LogoContrast}
                  alt="Logo"
                  style={{ height: "60px" }}
                />
              </Link>
            </Typography>
          </Grid>
          {isMobile ? (
            <Box sx={{ flexGrow: 0 }} spacing={2}>
              <Button
                disableElevation
                aria-controls={Boolean(anchorEl) ? "account-menu" : undefined}
                aria-haspopup="true"
                size="small"
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                variant="text"
                color="secondary"
                onClick={handleMenuOpen}
                className="gray-link"
                sx={{
                  color: scrollPosition > 0 ? "#fff" : "#30473F ",
                  padding: "5px 10px 5px 20px",
                  fontSize: "14px",
                  borderRadius: "50px",
                }}
                endIcon={
                  dataManager && dataManager.userData?.nome ? (
                    <Avatar
                      size="small"
                      sx={{
                        width: 36,
                        height: 36,
                        bgcolor: "rgba(0,0,0,0.1)",
                        color: "#fff",
                      }}
                    >
                      {dataManager && dataManager.userData?.nome
                        ? dataManager.userData?.nome[0].toUpperCase()
                        : ""}
                    </Avatar>
                  ) : (
                    <MoreVertIcon
                      size="large"
                      sx={{ color: scrollPosition > 0 ? "#fff" : "#30473F" }}
                    />
                  )
                }
              >
                <span
                  style={{ color: scrollPosition > 0 ? "#fff" : "#30473F" }}
                >
                  Sua conta
                </span>
              </Button>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    ["@adm", "@manager"].includes(localStorage.acessType)
                      ? navigate("/dash/dashboard")
                      : navigate("/perfil");
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon sx={{ marginRight: "10px" }}>
                    <Avatar sx={{ bgcolor: "#0c8ce9", color: "#fff" }}>
                      {dataManager && dataManager.userData?.nome
                        ? dataManager.userData?.nome[0].toUpperCase()
                        : ""}
                    </Avatar>
                  </ListItemIcon>
                  <Typography variant="body1">
                    {dataManager && dataManager.userData?.nome
                      ? `Bem vindo, ${dataManager.userData?.nome}!`
                      : "Bem vindo"}
                    <Typography variant="body2" color="textSecondary">
                      {dataManager?.auth?.email}
                    </Typography>
                  </Typography>
                </MenuItem>
                <Divider />
                {["@adm", "@manager"].includes(localStorage.acessType) ? (
                  <MenuItem
                    component={Link}
                    to="/dash/dashboard"
                    onClick={handleMenuClose}
                  >
                    Dashboard
                  </MenuItem>
                ) : (
                  <MenuItem
                    component={Link}
                    to="/home"
                    onClick={handleMenuClose}
                  >
                    Página Inicial
                  </MenuItem>
                )}{" "}
                {["@manager", "@adm"].includes(localStorage.acessType) ? (
                  <MenuItem
                    component={Link}
                    to="/dash/dashboard"
                    onClick={handleMenuClose}
                  >
                    Meu perfil
                  </MenuItem>
                ) : (
                  <MenuItem
                    component={Link}
                    to="/perfil"
                    onClick={handleMenuClose}
                  >
                    Meu perfil
                  </MenuItem>
                )}
                <MenuItem
                  component={Link}
                  onClick={() => {
                    window.open("https://goianiarunners.com.br", "_blank");
                    handleMenuClose();
                  }}
                >
                  Blog
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/termos"
                  onClick={handleMenuClose}
                >
                  Políticas e Termos de Uso
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    {dataManager && dataManager.userData?.nome ? (
                      <LogoutIcon fontSize="small" />
                    ) : (
                      <LoginIcon fontSize="small" />
                    )}
                  </ListItemIcon>
                  {dataManager && dataManager.userData?.nome ? "Sair" : "Login"}
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <>
              <Grid item md={2.4}>
                {["@adm", "@manager"].includes(localStorage.acessType) ? (
                  <a
                    style={{
                      color: scrollPosition > 0 ? "#fff" : "#30473F ",
                    }}
                    className="gray-link"
                    component={Link}
                    href="/dash/dashboard"
                    color="inherit"
                  >
                    DASHBOARD
                  </a>
                ) : (
                  <a
                    style={{
                      color: scrollPosition > 0 ? "#fff" : "#30473F ",
                    }}
                    className="gray-link"
                    component={Link}
                    href="/home"
                    color="inherit"
                  >
                    PAGINA INICIAL
                  </a>
                )}
              </Grid>
              <Grid item md={2.4}>
                <a
                  style={{
                    color: scrollPosition > 0 ? "#fff" : "#30473F",
                  }}
                  className="gray-link"
                  component={Link}
                  href="https://goianiarunners.com.br"
                  target="_blank"
                  color="inherit"
                >
                  BLOG
                </a>
              </Grid>
              <Grid item md={2.4}>
                <a
                  style={{
                    color: scrollPosition > 0 ? "#fff" : "#30473F ",
                  }}
                  className="gray-link"
                  component={Link}
                  href="/termos"
                  color="inherit"
                >
                  POLÍTICA E TERMOS DE USO
                </a>
              </Grid>
              <Grid
                item
                md={2.4}
                sx={{ display: "flex" }}
                justifyContent="right"
              >
                <Button
                  size="small"
                  variant="text"
                  color="secondary"
                  disableElevation
                  onClick={handleMenuOpen}
                  sx={{
                    padding: "5px 10px 5px 20px",
                    fontSize: "14px",
                    borderRadius: "50px",
                  }}
                  className="gray-link"
                  endIcon={
                    <Avatar
                      size="small"
                      sx={{
                        width: 36,
                        height: 36,
                        bgcolor: "rgba(0,0,0,0.1)",
                        color: "#fff",
                      }}
                    >
                      {dataManager && dataManager.userData
                        ? dataManager.userData?.nome[0].toUpperCase()
                        : ""}
                    </Avatar>
                  }
                >
                  <span
                    style={{
                      color: scrollPosition > 0 ? "#fff" : "#30473F",
                    }}
                  >
                    Sua conta
                  </span>
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={() => {
                      ["@adm", "@manager"].includes(localStorage.acessType)
                        ? navigate("/dash/dashboard")
                        : navigate("/perfil");
                      handleMenuClose();
                    }}
                  >
                    <ListItemIcon sx={{ marginRight: "10px" }}>
                      <Avatar sx={{ bgcolor: "#0c8ce9", color: "#fff" }}>
                        {dataManager && dataManager.userData?.nome
                          ? dataManager.userData?.nome[0].toUpperCase()
                          : ""}
                      </Avatar>
                    </ListItemIcon>
                    <Typography variant="body1">
                      {dataManager && dataManager.userData?.nome
                        ? `Bem vindo, ${dataManager.userData?.nome}!`
                        : "Bem vindo"}
                      <Typography variant="body2" color="textSecondary">
                        {dataManager?.auth?.email}
                      </Typography>
                    </Typography>
                  </MenuItem>{" "}
                  <Divider />
                  {["@manager", "@adm"].includes(localStorage.acessType) ? (
                    <MenuItem
                      component={Link}
                      to="/perfil"
                      onClick={handleMenuClose}
                    >
                      Meu Perfil
                    </MenuItem>
                  ) : (
                    <MenuItem
                      component={Link}
                      to="/dash/dashboard"
                      onClick={handleMenuClose}
                    >
                      Meu Perfil
                    </MenuItem>
                  )}
                  <Divider />
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      {dataManager && dataManager.userData?.nome ? (
                        <LogoutIcon fontSize="small" />
                      ) : (
                        <LoginIcon fontSize="small" />
                      )}
                    </ListItemIcon>
                    {dataManager && dataManager.userData?.nome
                      ? "Sair"
                      : "Login"}
                  </MenuItem>
                </Menu>
              </Grid>
            </>
          )}
        </Grid>
      </Toolbar>
      {/* <Divider fullWidth /> */}
    </AppBar>
  );
};

export default ResponsiveAppBar;
