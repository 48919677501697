import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "../../../components/AutoComplete/";
import ChipInput from "../../../components/ChipInput";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "../../../utils/configAxios";
import { IconButton, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM =
  process.env.REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM;

const NewProduto = ({ setNewProduto, tabelas, alertCustom, resetPage }) => {
  const [dadosProduto, setDadosProduto] = useState({
    nomeTabela: "DEFAULT",
    preco: "",
    percentualDesconto: "0",
    nomeProduto: "",
    quantidade: "",
    descricao: "",
    variacoes: [],
    custo: "0",
    status: true,
  });
  const [selectedTabelaPreco, setSelectedTabelaPreco] = useState("");
  const [variacoes, setVariacoes] = useState([]);
  const [descricaoProduto, setDescricaoProduto] = useState("");

  const formatNumber = (value) => {
    if (!value) return "";

    value = value.replace(/\D/g, "");
    value = value.replace(/(\d+)(\d{2})$/, "$1.$2");

    return value;
  };

  const isFormValid = () => {
    const { nomeProduto, preco, percentualDesconto, quantidade, nomeTabela } =
      dadosProduto;

    if (!nomeProduto || !preco || !percentualDesconto || !nomeTabela) {
      alertCustom("Por favor, preencha todos os campos obrigatórios.");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      const data = {
        ...dadosProduto,
        preco: dadosProduto.preco.replace(/[^0-9.]/g, ""),
        custo: dadosProduto.custo.replace(/[^0-9.]/g, ""),
        variacoes: variacoes,
        nomeTabela: selectedTabelaPreco.title,
        descricao: descricaoProduto,
      };
      const response = await axios.post(
        `${REACT_APP_URL_BIBLIOTECA_RUNNERS_ADM}/v1/produto/cadastrar-produto`,
        data
      );
      reset();
      setNewProduto(data);
      alertCustom(response.data.message);
      resetPage();
    } catch (error) {
      alertCustom("Não foi possível cadastrar o produto!");
    }
  };

  const reset = () => {
    setDadosProduto({
      nomeTabela: "DEFAULT",
      preco: "",
      percentualDesconto: "0",
      nomeProduto: "",
      quantidade: "",
      descricao: "",
      variacoes: [],
      custo: "0",
      status: true,
    });
    setSelectedTabelaPreco(tabelas[0] || "");
  };

  const handleToggleStatus = () => {
    setDadosProduto({
      ...dadosProduto,
      status: !dadosProduto.status,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">
          Novo produto
          <Typography variant="body2" color="textSecondary">
            Informações sobre o produto
          </Typography>
        </Typography>
        <IconButton className="tour_12" onClick={() => resetPage(false)}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          type="text"
          id="nome"
          label="Nome do produto"
          variant="standard"
          placeholder="Nome do produto"
          value={dadosProduto.nomeProduto}
          onInput={(e) =>
            setDadosProduto({
              ...dadosProduto,
              nomeProduto: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={12} md={4} className="tour_8">
        <TextField
          fullWidth
          type="text"
          id="preco"
          label="Preço"
          variant="standard"
          placeholder="Preço do produto"
          value={formatNumber(dadosProduto.preco)}
          onInput={(e) =>
            setDadosProduto({
              ...dadosProduto,
              preco: formatNumber(e.target.value),
            })
          }
          onBlur={(e) =>
            setDadosProduto({
              ...dadosProduto,
              preco: Number(e.target.value).toFixed(2),
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </Grid>
      {/* <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          type="number"
          id="percentualDesconto"
          label="Percentual de Desconto"
          variant="standard"
          placeholder="Percentual de desconto"
          value={dadosProduto.percentualDesconto}
          onInput={(e) =>
            e.target.value >= 0 &&
            e.target.value <= 100 &&
            e.target.value.toString().length < 4
              ? setDadosProduto({
                  ...dadosProduto,
                  percentualDesconto: e.target.value,
                })
              : ""
          }
        />
      </Grid> */}

      {/* <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          type="text"
          id="quantidade"
          label="Quantidade"
          variant="standard"
          placeholder="Quantidade [un]"
          value={dadosProduto.quantidade}
          onInput={(e) =>
            setDadosProduto({
              ...dadosProduto,
              quantidade: e.target.value.replace(/[.,]/g, ""),
            })
          }
        />
      </Grid> */}
      {/* <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          type="text"
          id="custo"
          label="Custo"
          variant="standard"
          placeholder="Custo do produto"
          value={formatNumber(dadosProduto.custo)}
          onInput={(e) =>
            setDadosProduto({
              ...dadosProduto,
              custo: formatNumber(e.target.value),
            })
          }
          onBlur={(e) =>
            setDadosProduto({
              ...dadosProduto,
              custo: Number(e.target.value).toFixed(2),
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </Grid> */}
      <Grid item xs={12} md={6} className="tour_9">
        <Autocomplete
          options={tabelas.map((tabela) => ({
            title: tabela,
          }))}
          placeholder={"Selecione uma existente ou digite uma nova"}
          setValue={setSelectedTabelaPreco}
          value={selectedTabelaPreco}
          label={"Tabela de Preço"}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          type="text"
          id="descricao"
          label="Descrição do produto (opcional)"
          variant="outlined"
          placeholder="Descrição do produto"
          value={descricaoProduto}
          onChange={(e) => setDescricaoProduto(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6} className="tour_10">
        <ChipInput
          label={"Variações"}
          value={variacoes}
          setValue={setVariacoes}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: "flex", alignItems: "center" }}
        className="tour_11"
      >
        <Button
          disableElevation
          variant="contained"
          onClick={handleToggleStatus}
          sx={{
            backgroundColor: dadosProduto.status ? "#4CAF50" : "#FF5733",
            "&:hover": {
              backgroundColor: dadosProduto.status ? "#45a049" : "#FF4500",
            },
          }}
        >
          {dadosProduto.status ? "Ativo" : "Inativo"}
        </Button>
        <Typography variant="body2" color="textSecondary" sx={{ margin: 1 }}>
          {dadosProduto.status
            ? "O produto está visível"
            : "O produto não está disponível para venda"}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2} style={{ justifyContent: "flex-end" }}>
          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={() => resetPage(false)}
              disableElevation
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disableElevation
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewProduto;
