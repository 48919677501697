import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const EditDialog = ({
  open,
  onClose,
  data,
  fields,
  handleSave,
  path,
  alertCustom,
}) => {
  const [formData, setFormData] = useState(data);
  const handleChange = (e) => {
    const { name, value } = e.target;

    let newValue = value;

    if (name === "telefone") {
      // Formatação de telefone
      newValue = value.replace(/\D/g, "").slice(0, 11);
      newValue = newValue.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else if (["nome", "contato", "banco", "chavePix"].includes(name)) {
      // Limita organizador a 30 caracteres
      newValue = value.slice(0, 40);
    } else if (["site"].includes(name)) {
      // Limita o campo de site a 40 caracteres e adiciona 'https://' se o valor tiver mais de 5 caracteres e não tiver 'https'
      newValue = value.slice(0, 40);
      if (newValue.length > 8 && !/^https?:\/\//i.test(newValue)) {
        newValue = `https://${newValue}`;
      }
    } else if (["agencia", "conta"].includes(name)) {
      // Limita dados bancários a 10 dígitos
      newValue = value.slice(0, 10);
    }

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };
  const getDifferences = (obj1, obj2) => {
    const differences = {};
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        differences[key] = obj2[key];
      }
    });
    return differences;
  };

  const handleSubmit = () => {
    const { agencia, banco, chavePix, conta } = getDifferences(data, formData);
    const info = { agencia, banco, chavePix, conta };
    const flag = Object.keys(info).every((key) => !!info[key]);

    handleSave(formData, path, flag ? "cadastrar" : "atualizar");
    onClose();
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alertCustom("Chave Pix copiada!");
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        Editar Informações
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ padding: 1 }}>
          {fields.map((field) => (
            <Grid item xs={12} md={6} key={field}>
              <TextField
                label={field.charAt(0).toUpperCase() + field.slice(1)}
                name={field}
                value={formData[field] || ""}
                onChange={handleChange}
                fullWidth
                InputProps={
                  field === "chavePix"
                    ? {
                        endAdornment: (
                          <IconButton
                            onClick={() => handleCopy(formData.chavePix)}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        ),
                      }
                    : null
                }
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
