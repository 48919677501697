import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../../../../utils/configAxios";

const NotificationForm = ({ alertCustom }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    to: "",
    from: "",
    message: "",
    type: "text",
    color: "#ffffff",
    status: true,
    anexo: "",
    titulo: "",
    data: new Date().toISOString().slice(0, 10), // Data padrão no formato YYYY-MM-DD
    action: "",
  });

  const [errors, setErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value === "banner") {
      setFormData({ ...formData, ["para"]: "todos" });
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleColorChange = (e) => {
    setFormData({ ...formData, color: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.to) newErrors.to = "Campo obrigatório";
    if (!formData.from) newErrors.from = "Campo obrigatório";
    if (!formData.message) newErrors.message = "Campo obrigatório";
    if (!formData.titulo) newErrors.titulo = "Campo obrigatório";
    if (!formData.data) newErrors.data = "Campo obrigatório";
    if (formData.action && !/^https?:\/\//.test(formData.action)) {
      newErrors.action = "URL inválida (deve começar com http:// ou https://)";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const response = await axios.post(`/v1/config/cadastrar-mensagem`, {
        ...formData,
        status: true,
      });
      alertCustom("Notificação cadastrada com sucesso");
      console.log("Notificação cadastrada com sucesso:", response.data);
      handleClose();
    } catch (error) {
      console.error("Erro ao cadastrar a notificação:", error);
    }
  };

  return (
    <div>
      <Button
        disableElevation
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        Nova Notificação
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Cadastro de Notificação
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Para"
                name="to"
                disabled={formData.type === "banner"}
                value={formData.type === "banner" ? "Todos" : formData.to}
                onChange={handleInputChange}
                variant="outlined"
                error={!!errors.to}
                helperText={errors.to}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="De"
                name="from"
                value={formData.from}
                onChange={handleInputChange}
                variant="outlined"
                error={!!errors.from}
                helperText={errors.from}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Título"
                name="titulo"
                value={formData.titulo}
                onChange={handleInputChange}
                variant="outlined"
                error={!!errors.titulo}
                helperText={errors.titulo}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mensagem"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                variant="outlined"
                multiline
                rows={4}
                error={!!errors.message}
                helperText={errors.message}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="type-label">Tipo</InputLabel>
                <Select
                  labelId="type-label"
                  name="type"
                  label="Tipo"
                  value={formData.type}
                  onChange={handleInputChange}
                  variant="outlined"
                >
                  <MenuItem value="text">Mensagem</MenuItem>
                  <MenuItem value="banner">Alerta Para Organizadores</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="subtitle1">Cor da Notificação:</Typography>
              <Button
                disableElevation
                variant="contained"
                onClick={() => document.getElementById("color-input").click()}
                sx={{
                  backgroundColor: formData.color,
                  border: "1px solid #000",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: formData.color,
                  },
                }}
              >
                Selecione a Cor
              </Button>
              <input
                type="color"
                id="color-input"
                value={formData.color}
                onChange={handleColorChange}
                style={{ display: "none" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Anexo (opcional)"
                name="anexo"
                value={formData.anexo}
                onChange={handleInputChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Data"
                name="data"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={formData.data}
                onChange={handleInputChange}
                variant="outlined"
                error={!!errors.data}
                helperText={errors.data}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ação (URL opcional)"
                name="action"
                value={formData.action}
                onChange={handleInputChange}
                variant="outlined"
                error={!!errors.action}
                helperText={errors.action}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
          >
            Cadastrar Notificação
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NotificationForm;
